import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Api from "../../api/Api";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import { toast,ToastContainer } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from "@mui/icons-material";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { Box, TextField, Button, Typography, Autocomplete } from "@mui/material";
import MzErrorText  from '../../components/ui/MzErrorText'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ArrowBack from "@mui/icons-material/ArrowBack";



function Services() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const formRef = useRef();

  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
      display_type: "",
      status:''
    });

    const handleTextChange = (e) => {
      const { name, value } = e.target;
      setInitialValues({
        ...initialValues,
        [name]: value,
      });
    };
  
  const [services, setServices] = useState([]);
  const [i, setI] = useState(false);
  const [pageId, setPageId] = useState("");
  const location = useLocation();
  const [serviceToDelete, setServiceToDelete] = useState(null);

  const pathname = location.pathname.split("/");
  const id = pathname[2];
  const validationSchema = Yup.object({
    display_type: Yup.string(),
  });

  async function getServices() {
    try {
      setPageId(pathname[2]);
      const response = await Api.get(`/pages/${id}`, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      });

      setServices(response.data.services ?? []);
      console.log(response.data.services);
    } catch (error) {
      console.log(error);
    }
  }
  const HandleDelete = async (id) =>{
    try {
      const res = await Api.delete('services/' + id,{
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
      const data = res.data
      if(data.status === 'success'){
        getServices()
        toast.success('Deleted Successfully !', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress:undefined ,
          theme: "dark",
        });
        
    }
    } 
    catch (error) {
      toast.error(error.message)
    }
}

  useEffect(() => {
    if (!i) {
      getServices();
      setI(true);
    }
  }, []);

  const onSubmitSearchBdisplay = async (values,token) => {
    
    const display_type = values.display_type === 'Interieur' ? 'INTERNAL' : values.display_type === 'Exterieur' ? 'EXTERNAL' : null
    const status = values.status === 'Active' ? 'ACCEPTED' : values.status === 'Inactive' ? 'BLOCKED' : null

    Api.get("/pages/" + pageId  , {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      params: { 
        display_type: display_type,
        status: status
       } 
    })
      .then((response) => {
        setServices(response.data.services);
      })
      .catch((error) => {
        toast.error(error.response?.data?.message);
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  return (
    <div>
      <div className="flex items-center justify-start gap-2 mb-10">
        <NavLink
            to={"/admin/pages/"}
            className="bg-[#7B3FFF] p-1 text-white rounded-full "
          >
            <ArrowBack/>
          </NavLink>
          <h1 className="font-bold text-xl">
          Services
        </h1>
        </div>
      
      <div className="flex w-full justify-between items-start md:items-center lg:items-center mb-8">
      <Box className="filter" display="flex" flexDirection="row">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchBdisplay(initialValues, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex">
                <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

                {/*Search fields */}

                <Box display="flex" maxWidth="1200px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                  
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="display_type"
                      component={Autocomplete}
                      options={['Interieur','Exterieur']}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          display_type: value,
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Display Type" sx={{width:200}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Display Type" component={MzErrorText} />
                  </Box>

                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="status"
                      component={Autocomplete}
                      options={['Active','Inactive']}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setInitialValues((prevValues) => ({
                          ...prevValues,
                          status: value,
                        }));
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Status" sx={{width:200}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Status" component={MzErrorText} />
                  </Box>

                  <Box marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Filtrer
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            justifyItems: "center",
            alignItems:'center',
            gap: 3,
          }}
          className="mb-4"
        >
          <NavLink
            to={"/admin/page/" + pageId + "/service"}
            className="bg-[#7B3FFF] p-2 text-white rounded-lg "
          >
            Ajouter un Service
          </NavLink>
        </div>
      </div>
      <div className="grid gap-3 grid-cols-1 order-last md:grid-cols-2 lg:grid-cols-3">
        {services?.length === 0 ? (
          <div>No Service found</div>
        ) : (
          services?.map((service) => {
            return (
              <div
                key={service.id}
                style={{
                  color: "#353535",
                  textDecoration: "none",
                  padding: 12,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
                className=" drop-shadow-lg shadow-gray-400 shadow-lg rounded-xl"
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={service.url_image}
                      alt="service_picture"
                      style={{
                        backgroundSize: "cover",
                        width: 100,
                        height: 100,
                        borderRadius: 99,
                      }}
                    />
                  </div>
                  <div className="page-inner-section relative flex justify-between items-center">
                    <div className="flex flex-col gap-1">
                      <h3 className="font-bold text-lg pt-10">{service.name}</h3>
                      <p className="font-bold text-sm">{service.display_type === 'INTERNAL' ? 'Interieur' : 'Exterieur'}</p>
                    </div>
                    {service.status === 'ACCEPTED' ? <h3 className= "text-white p-1.5 bg-green-600 w-auto h-auto rounded-full text-sm  font-bold">
                      Active
                    </h3>
                    : 
                    <h3 data-tooltip-target="tooltip-inactive" className= "text-white p-1.5 bg-red-600 w-auto h-auto rounded-full text-sm font-bold">
                      Inactive
                    </h3>}
                  </div>                  
                </div>
                <div
                  style={{
                    marginTop: 6,
                    width:'100%',
                    display: "flex",
                    alignItems:'center',
                    justifyContent: "end",
                    gap:'1rem'
                  }}
                >

              <NavLink
                to={"/admin/page/" + pageId + "/service/" + service.id}
                className={'bg-gray-600 text-white p-1 rounded-full'}
              >
               <EditIcon/>
              </NavLink>
                  <button onClick={() => setServiceToDelete(service.id)} className="bg-red-500 text-white p-1 rounded-full font-bold">
                    <Delete/>
                  </button>
                  <ConfirmationModal
                    title="Supprimer une page"
                    message="Êtes-vous sûr de bien vouloir supprimer cette page ?"
                    acceptLabel="Oui"
                    refuseLabel="Non"
                    open={serviceToDelete === service.id}
                    onClose={()=> setServiceToDelete(null)}
                    acceptMethod={()=>HandleDelete(service.id)}
                  />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default Services;
