import React from "react";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Autocomplete, StepButton, CircularProgress, Stack } from "@mui/material";
import { colors } from "../../theme/Colors";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import { Formik, Form, Field, useFormik } from "formik";
import AlertTitle from "@mui/material/AlertTitle";
import AlertError from "../../components/ui/AlertError";
import AlertSuccess from "../../components/ui/AlertSuccess";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, Link, useNavigate } from "react-router-dom";
import FileIcon from "../../imgs/file.svg";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Close } from "@mui/icons-material";
import { chipSelector } from "../../utils/chipSelector";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";

import DeliveredClose from "../../components/ui/DeliveredClose";
import DeliveredOpen from "../../components/ui/DeliveredOpen";
import DelieryClose from "../../components/ui/DelieryClose";
import DeliveryOpen from "../../components/ui/DeliveryOpen";
import InProdClose from "../../components/ui/InProdClose";
import InProdOpen from "../../components/ui/InProdOpen";

function FileUploader() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  //loading state
  const [loadingState, setLoadingState] = useState(false)
  //navigate to create enterprise
  const location = useLocation();
  const id = location?.pathname?.split("/")[3];
  const idEntreprise = user?.user?.enterprise?.id;
  const [data, setData] = useState();
  const [change, setChange] = useState(false);
  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml", "image/webp", "application/pdf"];
  const navigate = useNavigate();
  //validation schema first form
  const validationSchemaFirstForm = Yup.object({
    number_of_cards: Yup.number().typeError("ce champs doit etre un nombre").required("ce champ est obligatoire"),
  });
  //deis singer
  const [devisSigned, setDevisSigned] = useState("");
  //validation schema add bon de commande file
  const validationSchemaBonCommande = Yup.object({
    purchase_order_file: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
    estimate_file_signed: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  //bon de commande name
  const [bonDeCommande, setBonDeCommande] = useState("");

  //formik first form
  const formikFirstForm = useFormik({
    initialValues: {
      number_of_cards: "",
    },
    validationSchema: validationSchemaFirstForm,
    onSubmit: () => {
      HandleCreateOrder(formikFirstForm.values.number_of_cards);
    },
  });

  const status = [
    { id: "COMMANDED", name: "Commandé" },
    { id: "SUSPENDED", name: "Suspendue" },
    { id: "ACTIVE", name: "Active" },
  ];

  const formRef = useRef();

  //formik second form
  const formikUploadBonDeCommande = useFormik({
    initialValues: {
      purchase_order_file: "",
      estimate_file_signed: "",
    },
    validationSchema: validationSchemaBonCommande,
    onSubmit: () => {
      UploadBonDeCommande();
    },
  });

  //config upload fichier bon de commande
  const [progressBon, setProgressBon] = useState();
  const [loadingDocBon, setLoadingDocBon] = useState(false);
  var configsBon = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressBon(percentCompleted);
    },
  };

  //upload bon de commande
  const UploadBonDeCommande = () => {
    setLoadingDocBon(true);
    Api.post(
      Urls.UPLOAD_BON_DE_COMMANDE + data?.id + "/purchase_order_file",
      {
        purchase_order_file: formikUploadBonDeCommande.values.purchase_order_file,
        estimate_file_signed: formikUploadBonDeCommande.values.estimate_file_signed,
      },
      configsBon
    )
      .then((res) => {
        setLoadingDocBon(false);
        toast.success(res.data.message);
        formikUploadBonDeCommande.values.purchase_order_file = "";
        formikUploadBonDeCommande.values.estimate_file_signed = "";
        setChange(!change);
        setBonDeCommande("");
        setDevisSigned("");
      })
      .catch((err) => {
        //console.log(err);
        setLoadingDocBon(false);
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message); ////console.log(err);
        setChange(!change);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const [docToAddName, setDocToAddName] = useState();

  //create order
  const HandleCreateOrder = (number_of_cards) => {
    Api.post(
      Urls.CREATE_ORDER + companySelected + "/orders",
      { number_of_cards: number_of_cards, },
      { headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` }, }
    ).then((res) => {
      setOrderId(res.data.order.id);
      toast.success("commande carte cadeaux creer avec success");
    }).catch((err) => {
      if (err.response.status === 403) toast.error("Une erreur est survenue");
      else toast.error(err.response.data.message);
      if (err.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login", { replace: true });
        navigate(0, { replace: true });
      }
    });
  };

  //modal config
  const [openActive, setOpenActive] = useState(false);
  const handleCloseActive = () => setOpenActive(false);

  //onPageChange

  const [loadingDoc, setLoadingDoc] = useState(false);
  const [progress, setProgress] = useState(null);
  //add docs
  const steps = [
    {
      label: "Etape 1",
      description: `  Demande de devis.`,
    },
    {
      label: "Etape 2",
      description: `Validation de devis.`,
    },
    {
      label: "Etape 3",
      description: `Commande.`,
    },
    {
      label: "Etape 4",
      description: `Création des comptes collaborateurs.`,
    },
    {
      label: "Etape 5",
      description: `Facturation.`,
    },
    {
      label: "Etape 6",
      description: `Paiement.`,
    },
    {
      label: "Etape 7",
      description: `Production.`,
    },
  ];
  const stepsProgression = [
    {
      label: "Etape 1",
      description: `En production`,
      status: "IN_PRODUCTION",
    },
    {
      label: "Etape 2",
      description: `Livraison`,
      status: "DELIVERY",
    },
    {
      label: "Etape 3",
      description: `Livrée`,
      status: "DELIVERED",
    },
  ];
  //state for modal confirmation
  const [confirmBonDeCommande, setConfirmBonDeCommande] = useState(false);
  const [confirmListeCollabs, setConfirmListeCollabds] = useState(false);
  const [confirmPayement, setConfirmPayement] = useState(false);

  const [activeStep, setActiveStep] = React.useState(0);

  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  const stepperStyle = {
    ".MuiSvgIcon-root:not(.Mui-completed)": {
      color: colors.light,
    },
    ".MuiSvgIcon-root.Mui-active": {
      color: colors.main_purple,
      marginY: "-3px",
    },
  };
  const stepperStyleProgression = {
    ".css-z7uhs0-MuiStepConnector-line": {
      margin: "12px 8px",
    },
  };


  //add fichier excel
  //check if email is unique
  Yup.addMethod(Yup.array, "unique", function (message, mapper = (a) => a) {
    return this.test("unique", message, function (list) {
      return list?.length === new Set(list?.map(mapper)).size;
    });
  });

  //File config
  const SUPPORTED_FORMATS_EXCEL = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/xlsx", "application/xls"];

  //validation schema for submit
  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS_EXCEL.includes(value.type)),
    collaborators: Yup.array().required(),
  });

  //validation schema for user
  const schema = Yup.object().shape({
    NOM: Yup.string()
      .required("Nom manquant")
      .matches(/^[a-zA-ZàâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑù',ÉÈÀÙ/& .-]*$/, "Le nom doit contenir des caractères alphabétiques\n"),
    Prénom: Yup.string()
      .required("Prénom manquant")
      .matches(/^[a-zA-ZàâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ',ÉÈÀÙ/& .-]*$/, "Le prenom doit contenir des caractères alphabétiques\n"),
    Département: Yup.string()
      .required("Département manquant")
      .matches(/^[a-zA-Z0-9àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ',ÉÈÀÙ/& .-]*$/, "Le département doit contenir des caractères alphabétiques\n"),
    Fonction: Yup.string()
      .required("Fonction manquante")
      .matches(/^[a-zA-Z0-9àâäæáãåāèéêëęėēîïīįíìôōøõóòöœùûüūúÿçćčńñÀÂÄÆÁÃÅĀÈÉÊËĘĖĒÎÏĪĮÍÌÔŌØÕÓÒÖŒÙÛÜŪÚŸÇĆČŃÑ'ÉÈÀÙ/& .-]*$/, "La fonction doit contenir des caractères alphabétiques\n"),
    Email: Yup.string().email("Email invalide").required("Email manquant"),
  });

  const schemaListEmails = Yup.object().shape({
    listEmails: Yup.array().of(Yup.string()).unique("Emails dupliqués"),
  });
  const formRefPayement = useRef();
  const [listEmails, setListEmails] = useState([]);

  const [json, setJson] = useState();
  const [fileName, setFileName] = useState();
  const [excellErrors, setExcellErrors] = useState([]);
  const [validFile, setValidFile] = useState(null);

  const handleChange = async (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const pageExcell = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(pageExcell);

    setExcellErrors([]);
    setListEmails([]);

    jsonData.map((j) => {
      //add field expiration date
      j["expiration_date"] = formRef.current.values.expiration_date;

      //gather emails
      listEmails.push(j.Email);

      //is json valide ??
      schema.isValid(j).then((valid) => valid);
      schema.isValid(j).then((valid) => (valid ? setValidFile(true) : setValidFile(false)));

      //create errors excel
      schema.validate(j).catch((error) => {
        setExcellErrors((oldarray) => [...oldarray, `• Ligne ${j.__rowNum__ + 1} : ${error.message}`]);
        // //console.log(`error at line ${j.__rowNum__ + 1} : ${error.message}`);
      });
    });

    // //console.log(listEmails);

    //is list emails valide ??
    schemaListEmails.isValid({ listEmails }).then((valid) => valid);
    schemaListEmails.isValid({ listEmails }).then((valid) => (valid ? setValidFile(true) : setValidFile(false)));

    //are emails unique
    schemaListEmails.validate({ listEmails }).catch((error) => {
      setExcellErrors((oldarray) => [...oldarray, `•  ${error.message}`]);
      // //console.log(`${error.message}`);
    });

    setJson(jsonData);

    // //console.log("Excell data in JSON : ", jsonData);
  };

  const [realCollaborators, setRealCollaborators] = useState([]);
  const [progressExcel, setProgressExcel] = useState(0);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [fileUploaded, setFileUploaded ] = useState("");
  var configsExcel = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressExcel(percentCompleted);
    },
  };
  const onSubmitAddCollabs = (values) => {
    setRealCollaborators([]);

    // //console.log("values from onsubmit", values);

    json.map((collab) => {
      //add field expiration date
      realCollaborators.push({
        first_name: (collab.Prénom.charAt(0).toUpperCase() + collab.Prénom.substring(1).toLowerCase()).trim(),
        last_name: collab.NOM.toUpperCase().trim(),
        email: collab.Email.trim(),
        department: collab.Département.trim(),
        function: collab.Fonction.trim(),
        phone_number: collab.Téléphone,
      });
    });
    setLoadingExcel(true);
    // //console.log("data to send gherbi", realCollaborators);
    Api.post(
      Urls.UPLOAD_LIST_COLLABORATORS_ORDER + `${orderId}/orderedCollaborators`,
      {
        collaborators: realCollaborators,
        file: values.file,
      },
      configsExcel
    )
      .then((response) => {
        // //console.log("RESPONSE GENERATE COLLABS", response);
        formRef.current.values.file = "";
        formRef.current.values.expiration_date = "";
        formRef.current.values.collaborators = [];
        setDocToAddName("");
        if (response.status === 201) {
          // //console.log(response);
          setChange(!change);

          setFileUploaded(response.data.message)
          toast.success(response.data.message);
        }
        setExcellErrors([]);
        setLoadingExcel(false);
        setValidFile(null);
      })
      .catch((error) => {
        // //console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        setChange(!change);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };

  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  //doc name estimate
  const [docEstimate, setDocEstimate] = useState("");
  //document name facture
  const [facture, setFacture] = useState("");
  //validationSchemaStep3

  const validationSchemaStep3 = Yup.object({
    payment_date: Yup.date().required("ce champ est obligatoire"),
    payment_mode: Yup.string().required("ce champ est obligatoire"),
    amount: Yup.number().typeError("entrer une valeur valide").required("ce champ est obligatoire"),
    payment_file: Yup.mixed()
      .required("Veuillez télécharger un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE)
      .test("fileFormat", "Format du fichier invalide", (value) => value && SUPPORTED_FORMATS.includes(value.type)),
  });

  const paymentMode = {
    ELECTRONIC_BANK_TRANSFERS: "VIREMENTS BANCAIRES",
  };

  //make payment
  const [loadingPayement, setLoadingPayement] = useState(false);
  const [progressPayement, setprogressPayement] = useState(0);
  var configsPayement = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setprogressPayement(percentCompleted);
    },
  };
  const makePayment = (values) => {
    setLoadingPayement(true);
    ////console.log(values);
    Api.post(
      Urls.CREATE_PAYMENT + data?.invoice?.id + "/payments",
      {
        payment_date: values.payment_date,
        payment_mode: values.payment_mode,
        amount: values.amount,
        payment_file: values.payment_file,
      },
      configsPayement
    )
      .then((res) => {
        setChange(!change);
        ////console.log(res);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        ////console.log("Form ref", formRefPayement.current.initialValues);
        setFacture("");
        toast.success("Paiement effectuer");
        setLoadingPayement(false);
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        setChange(!change);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        setFacture("");
        setLoadingPayement(false);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  //update payement
  const UpdatePayment = (values) => {
    //console.log(values);
    setLoadingPayement(true);
    ////console.log(values);
    Api.post(
      "/payments/" + data?.invoice?.payment?.id,
      {
        payment_date: values.payment_date,
        payment_mode: values.payment_mode,
        amount: values.amount,
        payment_file: values.payment_file,
      },
      configsPayement
    )
      .then((res) => {
        setChange(!change);
        ////console.log(res);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        ////console.log("Form ref", formRefPayement.current.initialValues);
        setFacture("");
        toast.success("Paiement effectuer");
        setLoadingPayement(false);
      })
      .catch((err) => {
        if (err.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(err.response.data.message);
        setChange(!change);
        formRefPayement.current.values = formRefPayement.current.initialValues;
        setFacture("");
        setLoadingPayement(false);
        if (err.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  //hover
  const [hover, setHover] = useState(false);

  const [companies, setCompanies] = useState([])
  const [companySelected, setCompanySelected] = useState("")
  const [orderId, setOrderId] = useState("")
  //on page load
  useEffect(() => {
    // setIsLoading(true);
    Api.get(Urls.GET_ENTREPRISE_TABLE, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setCompanies(response.data.enterprises);
        // setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);

  const acceptCollabs = () => {
    //console.log("ACCEPT COLLABS ...");
    //console.log("update estimate accesstoken", access_token);
    setLoadingState(true)
    Api.patch(
      Urls.UPDATE_ORDER_STATUS + `/${orderId}/accept-gift`,
      {
        status: "LIST_COLLABORATORS_ACCEPTED",
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        setLoadingState(false)
        //console.log("RESPONSE ACCEPT COLLABS", response);
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Liste des collaborateur acceptée");
        }
      })
      .catch((error) => {
        setLoadingState(false)
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  return (
    <>
      <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>Selectionner L'enterprise.</Typography>
      {/* <Box marginRight="1rem" sx={{ display: "flex", flexDirection: "column", marginBottom: "1.3rem", }} >
          <Field
            sx={{ maxWidth: "350px" }}
            name="Entreprise"
            component={Autocomplete}
            value={companySelected}
            options={companies}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(s) => s}
            onChange={(event, value) => {
              setCompanySelected(value);
            }}
            renderInput={(params) => <TextField sx={{ maxWidth: "350px" }} {...params} name="autocomplete" label="Entreprise" />}
            size="small"
          />
        </Box> */}
      <select value={companySelected}
        onChange={e => setCompanySelected(e.target.value)} >
        <option value="" disabled>selectionner enterprise</option>
        {companies.map((company, index) => (
          <option value={company.id} key={index}>{company.commercial_name}</option>
        ))}
      </select>
      <div style={{ marginTop: '20px' }}></div>
      <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>Nombre de cadeaux.</Typography>
      <Box>
        {/* NOMBRE DE CARTES */}
        <Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography mb>Nombre d’abonnements</Typography>

            <Box display="flex" flexDirection="row" sx={{ alignItems: "center" }} gap="0.5rem">
              <TextField value={formikFirstForm.values.number_of_cards} name="number_of_cards"
                onChange={formikFirstForm.handleChange} label="Nombre de carte" size="small"></TextField>
              <Button onClick={formikFirstForm.handleSubmit} variant="primary" disabled={loadingState || !formikFirstForm.values.number_of_cards || !companySelected }>
                <Typography>Enregistrer</Typography>
              </Button>
            </Box>

          </Box>
        </Box>
      </Box>

      <div style={{ marginTop: '20px' }}></div>

      <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>{steps[3].description}</Typography>
      <Box>
        {/* NOMBRE DE CARTES */}
        <Box sx={{ marginBottom: "1rem" }}>
          <Typography mb>Liste des collaborateurs</Typography>
          {loadingExcel && (
            <Stack margin="0rem 1rem" gap="0.5rem" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
              <Typography color={colors.main_purple}>{progressExcel ? progressExcel + "%" : null}</Typography>
              <CircularProgress />
            </Stack>
          )}
          <Box flexWrap="wrap" display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Typography variant="small">Importation automatique</Typography>
            <Button variant="primary">
              <Link style={{ textDecoration: "none", color: "white" }} to="/EMAILS.xlsx" target="_blank" download>
                Télécharger fichier exemplaire
              </Link>{" "}
            </Button>
          </Box>
        </Box>
        {/* form add doc */}
        <Formik
          innerRef={formRef}
          initialValues={{
            expiration_date: new Date(year + 1, month, day).toISOString().slice(0, 10),
            file: "",
            collaborators: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            setConfirmListeCollabds(true);
          }}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              {/* doc title */}
              {loadingDoc && (
                <Stack margin="1rem 0rem" gap="0.5rem" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
                  <Typography color={colors.main_purple}>{progress ? progress + "%" : null}</Typography>
                  <CircularProgress />
                </Stack>
              )}
              {/* doc file */}

              <Box display="flex" flexDirection="column" sx={{ marginBottom: "1rem" }}>
                <Button variant="upload" sx={{ height: "250px", display: "flex", flexDirection: "column" }} component="label">
                  <img src={FileIcon} width="50px" alt="" style={{ marginBottom: "0.5rem" }} />
                  <input
                    style={{ opacity: "0%", width: "100%", height: "100%", position: "absolute" }}
                    id="file"
                    name="file"
                    type="file"
                    // accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(event) => {
                      const doc = event.target.files[0];
                      handleChange(event);

                      setFieldValue("file", event.target.files[0]);
                      setDocToAddName(doc.name);
                    }}
                  />
                  <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>Déposer votre fichier</Typography>
                </Button>
              </Box>
              <MzErrorText>{errors.file}</MzErrorText>

              {docToAddName && (
                <Box>
                  <Typography sx={{ margin: "1rem 0rem", color: `${colors.main_purple}`, fontWeight: "500", textDecoration: "underline" }} fontSize="0.875rem">
                    {docToAddName}
                  </Typography>
                </Box>
              )}
              {excellErrors.length !== 0 && (
                <AlertError severity="error">
                  <AlertTitle>Erreur dans votre fichier excell</AlertTitle>
                  {excellErrors.map((e) => (
                    <Typography key={e}>{e}</Typography>
                  ))}
                </AlertError>
              )}
              {validFile === true && excellErrors.length === 0 ? <AlertSuccess severity="error">Votre fichier excel est prêt à être envoyé</AlertSuccess> : ""}

              {/* confirm add doc */}
              <Box display="flex" flexDirection="row" gap="0.5rem">
                <Button disabled={values.file && !errors.file && validFile && excellErrors?.length === 0 ? false : true} variant="primary" type="submit">
                  Envoyer
                </Button>
                <ConfirmationModal
                  title="Confirmer la liste des collaborateurs"
                  message="Êtes-vous sûr de bien vouloir confirmer la liste des collaborateurs ?"
                  acceptLabel="oui"
                  refuseLabel="non"
                  open={confirmListeCollabs}
                  onClose={() => setConfirmListeCollabds(false)}
                  acceptMethod={() => onSubmitAddCollabs(values, access_token)}
                  acceptMethodParams="SUSPENDED"
                />
              </Box>
            </Form>
          )}
        </Formik>
        {/* end form add DOC */}
        {/* DEVIS */}
        {data?.url_ordered_collaborators_file && (
          <Box>
            <Typography
              onClick={() => {
                window.open(data?.url_ordered_collaborators_file, "_blank");
              }}
              sx={{ cursor: "pointer", margin: "1rem 0rem", color: `${colors.main_purple}`, fontWeight: "500", textDecoration: "underline" }}
              fontSize="0.875rem"
            >
              Liste_collaborateurs
            </Typography>
          </Box>
        )}
      </Box>

      <div style={{ marginTop: '20px' }}></div>
      <Typography sx={{ marginBottom: "1.5rem", fontSize: "0.8rem", fontWeight: "600", color: `${colors.main_purple}` }}>validation</Typography>
      {/* LIS DES COLLABS */}
      <Box>
        {loadingState ?
          <Box>
            <Typography>En traitement...</Typography>
          </Box> :
            <Button variant="primary" onClick={acceptCollabs} disabled={!orderId || !fileUploaded }>
              Accepter
            </Button>
          }
      </Box>
      <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </>
  );
}

export default FileUploader;
