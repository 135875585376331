import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Avatar, Modal } from "@mui/material";
import { colors } from "../../theme/Colors";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MzChip from "../../components/ui/MzChip";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import InfiniteScroll from "react-infinite-scroll-component";
import { ModalStyle } from "../../theme/ModalStyles";

function TicketDetails() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  //console.log("USER ID", user?.user?.id);

  const navigate = useNavigate();

  //location settings (get the id from onClick table)
  const { id } = useParams();
  //console.log("TICKET ID IS", id);

  //API data state
  const [data, setData] = useState();
  const [messages, setMessages] = useState([]);
  const [messagesData, setMessagesData] = useState();

  //trigger reload ticket infos details
  const [didOpen, setDidOpen] = useState(false);
  const [didClose, setDidClose] = useState(false);

  //validation schema message
  const validationSchema = Yup.object({
    message: Yup.string().required("Ce champ est obligatoire").max(500, "Le message doit pas dépasser 500 caractères"),
  });

  //on page load (load ticket details)
  useEffect(() => {
    //console.log("ID is", id);
    Api.get(Urls.GET_TICKET_DETAILS + `/${id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.ticket);
          //console.log("GET TICKET DETAILS : ", response?.data);
          //console.log("ENtreprise name : ", response?.data?.enterprise);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        //console.log(error);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [didOpen, didClose]);

  //on page load (load ticket messages)
  useEffect(() => {
    //console.log("ID is", id);
    Api.get(Urls.GET_TICKET_MESSAGES + `/${id}/messages?perPage=10`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setMessages(response?.data?.messages);
          setMessagesData(response?.data);
          //console.log("GET TICKET MESSAGES : ", response?.data?.messages);
          //console.log("MESSAGES WITH LINKS ::::", response?.data);
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);

  //close tiquet (update ticket)
  const updateTicketStatus = (status) => {
    Api.patch(
      Urls.UPDATE_TICKET_STATUS + `/${id}/status`,
      {
        status: status,
      },
      {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        ////console.log("RESPONSE UPDATE TICKET STATUS", response);

        if (response.status === 200) {
          ////console.log(response);
          if (status === "OPEN") {
            toast.success("Le status du ticket est maintenant ouvert");
            setDidOpen(!didOpen);
          }
          if (status === "CLOSED") {
            toast.success("Le status du ticket est maintenant fermé");
            setDidClose(!didClose);
          }
        }
      })
      .catch((error) => {
        ////console.log("RESPONSE", error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //submit new message
  const onSubmitNewMessage = (values) => {
    //console.log("values", values);
    //console.log("access_token", access_token);
    Api.post(
      Urls.CREATE_MESSAGE,
      {
        ticket_id: id,
        message: values.message,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE CREATE MESSAGE", response.data);

        if (response.status === 201) {
          //console.log("response create message", response?.data);
          setMessages(messages.concat(response?.data));
          toast.success("Message envoyé avec succès");
          clearMessageRef.current.values.message = "";
          executeScroll();
        }
      })
      .catch((error) => {
        //console.log("RESPONSE", error?.response?.data?.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //stepper config
  const activeStep = 0;

  const [isAdding, setIsAdding] = useState(false);

  const fetchMoreData = () => {
    if (messagesData.links.next) {
      //console.log("fetch more DATA :::::::", messagesData);

      Api.get(messagesData.links.next, {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setMessagesData(response?.data);
            setMessages(messages.concat(response?.data?.messages));
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
          if (error?.response?.status === 403) {
            toast.error("Une erreur est survenue");
          } else {
            toast.error(error?.response?.data?.message);
          }
        });
    }
  };

  const clearMessageRef = useRef();
  const scrollRef = useRef();
  const executeScroll = () => scrollRef.current.scrollIntoView();
  //delete a ticket
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("");
  //delete tickets
  const deletetick = () => {
    Api.delete(Urls.DELETE_TICKETS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        ticket_ids: [id],
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("ticket supprimé");
        setOnDeletNext(false);
        setTimeout(() => {
          navigate("/admin/service-apres-vente");
        }, 500);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  return (
    <Box>
      {/* Header Section */}
      <Box>
        <Typography sx={{ marginBottom: "1.5rem", fontWeight: "500" }}>Détail du ticket</Typography>
        <Box margin="1rem 0rem">
          <Button onClick={() => setOnDelete(true)} variant="third">
            Supprimer le ticket
          </Button>
        </Box>
        {/* FORMULAIRE  */}
        <Box>
          {/* ticket infos section */}
          <Box>
            <Box sx={{ gap: "0.5rem", marginBottom: "1rem" }} display="flex" flexDirection="row">
              <Typography>Entreprise : </Typography>
              <Typography fontWeight="600" color={colors.main_purple}>{` ${data?.enterprise?.commercial_name}`}</Typography>
            </Box>
            <Avatar
              sx={{
                position: "relative",
                borderRadius: "20px",
                width: "6rem",
                height: "6rem",
                border: "1px solid #C9C5D2",
                marginBottom: "1rem",
              }}
              variant="rounded"
            >
              {<img src={data?.enterprise?.url_logo} alt="The current file" style={{ width: "6rem" }} />}
            </Avatar>
            <Box sx={{ gap: "0.5rem", marginBottom: "1rem" }} display="flex" flexDirection="row">
              <Typography>Numéro du ticket : </Typography>
              <Typography fontWeight="600" color={colors.main_purple}>{` #${data?.id}`}</Typography>
            </Box>
            <Box sx={{ gap: "0.5rem", marginBottom: "1rem" }} display="flex" flexDirection="row">
              <Typography>Motif : </Typography>
              <Typography fontWeight="600" color={colors.main_purple}>{` ${data?.motif}`}</Typography>
            </Box>
            <Box sx={{ gap: "0.5rem", marginBottom: "1rem" }} display="flex" flexDirection="row">
              <Typography>Etat : </Typography>
              <MzChip name={`${data?.status}`}></MzChip>
            </Box>
            {data?.status === "OPEN" && (
              <Box sx={{ gap: "0.5rem", marginBottom: "1rem" }} display="flex" flexDirection="row">
                <Button onClick={() => updateTicketStatus("CLOSED")} variant="secondary">
                  Fermer le ticket
                </Button>
              </Box>
            )}
            {data?.status === "CLOSED" && (
              <Box sx={{ gap: "0.5rem", marginBottom: "1rem" }} display="flex" flexDirection="row">
                <Button onClick={() => updateTicketStatus("OPEN")} variant="primary">
                  Ouvrir le ticket
                </Button>
              </Box>
            )}

            <ConfirmationModal title="Supprimer un ticket" message="Êtes-vous sûr de bien vouloir supprimer ce ticket(s) ?" acceptLabel="oui" refuseLabel="non" open={ondelite} onClose={() => setOnDelete(false)} acceptMethod={() => setOnDeletNext(true)} />
            <Modal open={ondeliteNext} onClose={() => setOnDeletNext(false)}>
              <Box sx={ModalStyle}>
                <Typography fontSize="1.5rem" fontWeight="600" mb>
                  Supprimer un ticket
                </Typography>
                <Typography mb="1rem">Entrez votre mot de passe</Typography>
                <Box marginBottom="1rem">
                  <TextField type="password" value={motdepasse} onChange={(e) => setMotDePasse(e.target.value)} name="motdepasse" placeholder="Mot de passe" />
                </Box>
                <Box display="flex" flexDirection="row" gap="0.5rem">
                  <Button
                    variant="primary"
                    onClick={() => {
                      deletetick();
                    }}
                  >
                    Supprimer
                  </Button>
                  <Button onClick={() => setOnDeletNext(false)} variant="third">
                    Annuler
                  </Button>
                </Box>
              </Box>
            </Modal>
            <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
          </Box>
          {/* end ticket infos section */}
        </Box>
      </Box>
      {/* TICKETS MESSAGE*/}
      <Box sx={{ maxWidth: "650px", marginBottom: "1rem" }}>
        <InfiniteScroll dataLength={messages?.length} next={fetchMoreData} hasMore={messagesData?.links.next ? true : false}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            sx={{
              ".MuiSvgIcon-root:not(.Mui-completed)": {
                color: "white",
              },
              ".MuiSvgIcon-root.Mui-active": {
                color: colors.main_purple,
                marginY: "-3px",
              },
            }}
          >
            {messages.map((m) => (
              <Step active={true} key={m.id}>
                {m.sender_id === user?.user?.id ? <StepLabel>Moi</StepLabel> : <StepLabel>{data?.enterprise?.commercial_name}</StepLabel>}
                <StepContent sx={{ maxWidth: 650 }}>
                  {m.sender_id !== user?.user?.id ? (
                    <Box padding="1rem" borderRadius="1.5rem" bgcolor={colors.main_purple_hover_light_hover}>
                      <Typography sx={{ wordBreak: "break-word" }} fontSize="0.8rem" color="black">
                        {m.message}
                      </Typography>
                    </Box>
                  ) : (
                    <Box padding="1rem" borderRadius="1.5rem" bgcolor={colors.main_purple}>
                      <Typography sx={{ wordBreak: "break-word" }} fontSize="0.8rem" color="white">
                        {m.message}
                      </Typography>
                    </Box>
                  )}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </InfiniteScroll>
      </Box>
      {/* END TICKETS MESSAGE*/}
      {/* Input message  */}
      <Box sx={{ marginLeft: "2rem" }}>
        <Box>
          {data?.status === "OPEN" && (
            <Button
              ref={scrollRef}
              sx={{ marginBottom: "1rem" }}
              onClick={() => {
                setIsAdding(true);
                executeScroll();
              }}
              variant="primary"
            >
              Répondre
            </Button>
          )}
        </Box>
        {isAdding & (data?.status === "OPEN") ? (
          <Box sx={{ maxWidth: 650 - 32 }}>
            <Formik innerRef={clearMessageRef} initialValues={{ message: "" }} validationSchema={validationSchema} onSubmit={onSubmitNewMessage}>
              {({ values }) => (
                <Form>
                  <Box sx={{ marginBottom: "1rem" }}>
                    <Field name="message" multiline fullWidth columns={5} rows={4} as={TextField} />
                    <ErrorMessage name="message" component={MzErrorText} />
                  </Box>
                  <Box gap="1rem" display="flex" flexDirection="row-reverse">
                    <Box>
                      <Button onClick={() => setIsAdding(false)} variant="third">
                        Annuler
                      </Button>
                    </Box>
                    <Box>
                      <Button variant="primary" type="submit">
                        Envoyer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

export default TicketDetails;
