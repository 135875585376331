import axios from "axios";
import { toast } from "react-toastify";
import { useAuthContext } from "../hooks/useAuthContext";

const createAPI = () => {

  const API = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL_1_3}`,
  });

  API.interceptors.request.use(config => {
    config.headers['ngrok-skip-browser-warning'] = true;
    config.headers['access-control-allow-origin'] = '*';
    return config;
  });

  API.interceptors.response.use(response => {
    return response;
  }, error => {
    if (error.response && error.response.status === 429) {
      toast.error(`Error ${error.response.status}: Too Many Requests`);
    }
    return Promise.reject(error);
  });
  
return API;

}
const API = createAPI();

export default API;
