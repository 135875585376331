import React from "react";
import { InputLabel, styled } from "@mui/material";
import { colors } from "../../theme/Colors";

const ErrorText = styled(InputLabel)({
  marginTop: "0.3rem",
  color: `${colors.mj_error}`,
  fontSize: "0.875rem",
});

function MzErrorText({ children }) {
  return <ErrorText>{children}</ErrorText>;
}

export default MzErrorText;
