import React from "react";
import { useState, useEffect, useRef } from "react";
import { Box, TextField, Button, Typography, Autocomplete, Modal } from "@mui/material";
import { frFR } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { columns } from "../../data/ticketsData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import ConfirmationModal from "../../components/ui/ConfirmationModal";

import { useNavigate } from "react-router-dom";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { ToastContainer, toast } from "react-toastify";
import { ModalStyle } from "../../theme/ModalStyles";

function Tickets() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  //ondelete state
  const [del, setDel] = useState(false);
  const formRef = useRef();

  const navigate = useNavigate();

  //data grid pages configuration
  // const [selectedRows, setSelectedRows] = React.useState([]);
  // const [openParams, setOpenParams] = React.useState(false);
  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  //data grid pages configuration
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  //validation schema
  const validationSchema = Yup.object({
    id: Yup.string(),
    motif: Yup.string(),
    enterpriseCommercialName: Yup.string(),
    status: Yup.string(),
  });

  //page state

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if ((typeof tempObj[propName] === "object" && Object.keys(tempObj[propName]).length === 0) || tempObj[propName] === null || tempObj[propName] === "") {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }

  //on page load
  useEffect(() => {
    setIsLoading(true);
    Api.get(Urls.GET_TICKETS_TABLE + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { perPage: perPage },
    })
      .then((response) => {
        // console.log("RESPONSE TABLE", response.data.enterprises);
        setPage(0);
        setIsLoading(false);
        setRowsAPI(response.data.tickets);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);

  //onPageChange
  useEffect(() => {
    setIsLoading(true);
    // console.log("on page changed fired for page", page + 1);
    Api.get(Urls.GET_TICKETS_TABLE + `?perPage=${perPage}&page=${page + 1}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: nonEmptyObject(formRef.current.values),
    })
      .then((response) => {
        setRowsAPI(response.data.tickets);
        setTotal(response.data.meta.total);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error.response.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [page, perPage, del]);

  //on search
  const onSubmitSearchUsers = (values) => {
    let realValues = nonEmptyObject(values);
    // console.log("VALUES", values);
    // console.log("REAL VALUES", realValues);
    // console.log("at from submit", access_token);
    if (realValues.status === "Ouvert") {
      realValues.status = "OPEN";
    }
    if (realValues.status === "Fermé") {
      realValues.status = "CLOSED";
    }
    setPage(0);
    setIsLoading(true);
    Api.get(Urls.GET_TICKETS_TABLE + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: realValues,
    })
      .then((response) => {
        // console.log("response", response);
        setIsLoading(false);
        setRowsAPI(response.data.tickets);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log(error);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  //initial values
  const initialValues = {
    id: "",
    motif: "",
    enterpriseCommercialName: "",
    status: "",
  };

  //delete a ticket
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("");
  //delete tickets
  const deletetick = () => {
    Api.delete(Urls.DELETE_TICKETS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      data: {
        ticket_ids: selectedIDs,
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("ticket supprimé");
        setOnDeletNext(false);
        setDel(!del);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  return (
    <Box>
      {/* Header Section */}

      {/* Filter  Section */}
      {/* Form Section */}
      <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />

      <Box className="filter" display="flex" flexDirection="row">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography noWrap mr marginTop="0.45rem" mb>
                    Filtres
                  </Typography>
                </Box>

                {/*Search fields  */}

                <Box display="flex" maxWidth="900px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field name="id" type="input" as={TextField} label="Id" id="id" size="small" />
                    <ErrorMessage name="id" as={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field label="Motif" name="motif" as={TextField} type="input" size="small" />
                    <ErrorMessage name="motif" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field label="Nom commercial" name="enterpriseCommercialName" as={TextField} type="input" size="small" />
                    <ErrorMessage name="enterpriseCommercialName" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="status"
                      component={Autocomplete}
                      options={["Ouvert", "Fermé"]}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        setFieldValue("status", value ? value : "");
                      }}
                      renderInput={(params) => <TextField sx={{ maxWidth: "600px" }} {...params} name="autocomplete" label="Statut" />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="status" component={MzErrorText} />
                  </Box>
                  <Box marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Chercher
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre>
              <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
            </Form>
          )}
        </Formik>
      </Box>
      <Box>
        <Button onClick={() => setOnDelete(true)} disabled={selectedIDs.length === 0 ? true : false} sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} variant="third">
          Supprimer un/des ticket(s)
        </Button>
      </Box>
      <ConfirmationModal title="Supprimer un/des ticket(s)" message="Êtes-vous sûr de bien vouloir supprimer ce/ces ticket(s) ?" acceptLabel="oui" refuseLabel="non" open={ondelite} onClose={() => setOnDelete(false)} acceptMethod={() => setOnDeletNext(true)} />
      <Modal open={ondeliteNext} onClose={() => setOnDeletNext(false)}>
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb>
            Supprimer un/des ticket(s)
          </Typography>
          <Typography mb="1rem">Entrez votre mot de passe</Typography>
          <Box marginBottom="1rem">
            <TextField type="password" value={motdepasse} onChange={(e) => setMotDePasse(e.target.value)} name="motdepasse" placeholder="Mot de passe" />
          </Box>
          <Box display="flex" flexDirection="row" gap="0.5rem">
            <Button
              variant="primary"
              onClick={() => {
                deletetick();
              }}
            >
              Supprimer
            </Button>
            <Button onClick={() => setOnDeletNext(false)} variant="third">
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* End form section */}

      {/* End header Section */}

      {/* Data grid section */}
      {/* Data grid container (styles imported from DataGridHeaderContainer) */}
      {/* <pre>page number : {JSON.stringify(page, null, 2)}</pre>
      <pre>per page : {JSON.stringify(perPage, null, 2)}</pre> */}

      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          // sx={{ maxWidth: "1180px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          //allow for multiple rows selection when checkboxSelection is on
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rowsAPI.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
            //setSelectedRows(selectedRows);
            // console.log(selectedIDs);
            // console.log(selectedRows);
          }}
          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rowsAPI}
          //allow selection
          checkboxSelection
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // console.log(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          //onpage change
          onPageChange={(newPage) => {
            // console.log(newPage);
            setPage(newPage);
          }}
          pagination
          page={page}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
        {/* <pre>{JSON.stringify(selectedRows, null, 2)}</pre> */}
      </Box>
    </Box>
  );
}

export default Tickets;
