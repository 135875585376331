import { useLocation, Link, useNavigate } from "react-router-dom";
import React from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import {
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
  StepButton,
  CircularProgress,
  Stack,
  Modal,
} from "@mui/material";
import { ModalStyle } from "../../theme/ModalStyles";
import Grid from "@mui/material/Grid";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { useState, useEffect, useRef } from "react";
import AlertTitle from "@mui/material/AlertTitle";
import AlertError from "../../components/ui/AlertError";
import AlertSuccess from "../../components/ui/AlertSuccess";
import { colors } from "../../theme/Colors";
import MainTitle from "../../components/ui/MainTitle";

import IconPlus from "../../imgs/plus.svg";

function Features() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const [useEffectCount, setUseEffectCount] = useState(0);

  const [plansFromFeatures, setPlansFromFeatures] = useState([]);
  const [features, setFeatures] = useState([]);

  const [openFeatureModal, setOpenFeatureModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // -------------------------------------------------------------------------
  const [formData, setFormData] = useState({
    feature: "",
    plan_id:"",
    description: "",
    note: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleCreateFeature = (e) => {
    e.preventDefault();
    setLoading(true);
    Api.post(
      Urls.GET_FEATURES,
      {
        feature: formData.feature,
        plan_id:formData.plan_id,
        description: formData.description,
        note: formData.note,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        getFeatures();
        setOpenFeatureModal(false);
        setLoading(false);
        setFormData({
          feature: "",
          plan_id:"",
          description: "",
          note: "",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  const handleUpdateFeature = (e) => {
    e.preventDefault();
    setLoading(true);
    Api.put(
      `${Urls.GET_FEATURES}/${formData.id}`,
      {
        feature: formData.feature,
        plan_id:formData.plan_id,
        description: formData.description,
        note: formData.note,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        getFeatures();
        setOpenFeatureModal(false);
        setLoading(false);
        setFormData({
          name: "",
          description: "",
          note: "",
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  function deleteFeature(plan) {
    setLoading(true);
    Api.delete(`${Urls.GET_PLANS_WITH_FEATURES}/${plan.id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        getFeatures();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }
  // -------------------------------------------------------------------------
  
  const getFeatures = ()=>{
    Api.get(Urls.GET_FEATURES, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setFeatures(response.data.data);
        setUseEffectCount(1)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getPlans = () => {
    Api.get(Urls.GET_PLANS_WITH_FEATURES, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setUseEffectCount(1);
        setPlansFromFeatures(response.data.data);        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
      getFeatures()
      getPlans()
  },[]);
  return (
    <Box>
      <div className="flex justify-between ">
        <MainTitle title="BDigitall Features " icon="person" />
        <div className="flex items-start">
          <button className="flex items-center gap-1 px-2 py-1 rounded-md bg-[#7B3FFF] justify-center text-white hover:opacity-70"
          onClick={() => { setOpenFeatureModal(true); }} >
            <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" > {" "} <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />{" "} </svg>
            ajouter une feature
          </button>
        </div>
      </div>

      <div className="">
        {features.map((feature) => (
          <div key={feature.id} className="bg-[#2D1C52] rounded-md text-white py-3 px-4 
          flex items-center justify-between mb-3">
            <p>{feature.feature}</p>
            <div className="flex justify-end gap-2">
                <button onClick={() => {
                  //  setFormData(plan); setOpenFeatureModal(true); 
                   }} className="icon-button" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" > {" "} <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />{" "} </svg>
                </button>
                <button onClick={() => { 
                  // deletePlan(plan); 
                  }} className="icon-button" >
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" > {" "} <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />{" "} </svg>
                </button>
              </div>
          </div>
        ))}
      </div>

      {/* modals */}
      <Modal
        open={openFeatureModal}
        onClose={() => {
          setOpenFeatureModal(false);
        }}
      >
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb>
            {formData.id ? "Metre a jour ce plan" : "Ajouter un nouveaux plan"}
          </Typography>
          {/* <Typography mb="1.5rem">description</Typography> */}
          <Box>
            <form
              onSubmit={formData.id ? handleUpdateFeature : handleCreateFeature}
              className=" space-y-3"
            >
              <div className="form-row">
                <label htmlFor="name">Feature:</label>
                <input
                  type="text"
                  id="name"
                  name="feature"
                  value={formData.feature}
                  onChange={handleChange}
                  className="default-input"
                />
              </div>
              <div className="form-row">
                <label htmlFor="name">Nom:</label>
                <select className="default-input" 
                  value={formData.plan_id}
                  name='plan_id'
                  defaultValue=""
                  onChange={(e)=>handleChange(e)}>
                  <option value="" disabled>select plan</option>
                  {
                    plansFromFeatures.map((plan)=>(
                      <option value={plan.id} key={plan.id}>{plan.name}</option>
                    ))
                  }
                </select>
                  
              </div>
              <div className="form-row">
                <label htmlFor="description">Deescription:</label>
                <textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="default-input"
                />
              </div>
              <div className="form-row">
                <label htmlFor="note">Note:</label>
                <textarea
                  id="note"
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                  className="default-input"
                />
              </div>
              <div className="flex justify-end ">
                <Button disabled={loading} variant="primary" type="submit">
                  valider
                </Button>
              </div>
            </form>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Features;
