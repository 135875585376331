import React, { useEffect, useRef, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Box, Typography, Button, TextField } from '@mui/material';
import * as Yup from 'yup';
import API from '../../api/Api_1_3';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';  
import { ToastContainer, toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import MzErrorText from './MzErrorText';
import Gauge from './Gauge.jsx'
import { colors } from '../../theme/Colors.js';


const BarChartComponent = ({ values }) => {
  const [data, setFilled] = useState({});
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  const formRef = useRef();
  
  const initialValuesCartes = {
    start_date: values.start_date,
    end_date: values.end_date,
  };

  // Validation schema
  const validationSchema = Yup.object({
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
    min_percentage: Yup.number().min(0).max(100).nullable(),
    max_percentage: Yup.number().min(0).max(100).nullable(),
  });

  const getProfileFilled = (values) => {
    const params = {
      start_date: values.start_date || null,
      end_date: values.end_date || null,
    };
    if (values.min_percentage !== '' || values.min_percentage !== null) params.min_percentage = values.min_percentage;
    if (values.max_percentage !== '' || values.max_percentage !== null ) params.max_percentage = values.max_percentage;

    API.get('/report/bcard/profiles-filling-per-user', {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        authorization: `Bearer ${access_token}`,
      },
      params,
    })
      .then((response) => {
        setFilled(response.data.data);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleApiError = (error) => {
    if (error.response.status === 403) toast.error('Une erreur est survenue');
    else toast.error(error.response.data.message);
    if (error.response.status === 401) {
      localStorage.removeItem('user');
      navigate('/login', { replace: true });
      navigate(0, { replace: true });
    }
  };

  const onSubmitSearchUsers = (values) =>{
    if(values.start_date > values.end_date){
      toast.error('Start date must be less than end date');
    }
    else {
     getProfileFilled(values)
    }
  };

  useEffect(() => {
    getProfileFilled(initialValuesCartes);
  }, []);

  const profilesArray = data?.profiles
    ? Object.entries(data.profiles).map(([name, percentage]) => ({
        name: name.split('-')[1],
        percentage,
      }))
    : [];

  return (
    <Box p={3} mt={4}>
      {/* Percentage */}
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
        <Typography variant="h6" mb={4}>
          Pourcentage du Profils remplis:
        </Typography>
        <Typography variant="h6" mb={4} fontWeight={600} color={colors.bd_Purple}>{data.filling_percentage_avg}%</Typography>
      </Box>

      <Box className="filter" display="flex" mb={2} flexDirection="row" justifyContent={{
              xs:'start',
              sm:'start',
              md:'center',
              lg:'center',
              xl:'center',
            }}>
        <Formik
          innerRef={formRef}
          initialValues={initialValuesCartes}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column" mt={4}>
                <Box display="flex" maxWidth="1400" sx={{ flexDirection: { md: "column", xs: "column", sm: "column", lg: "row" } }}>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="min_percentage" type="number" as={TextField} variant="outlined" label="Minimum Percentage" size="small" />
                    <ErrorMessage name="min_percentage" component={MzErrorText} />
                  </Box>
                  <Box flex={1} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="max_percentage" type="number" as={TextField} variant="outlined" label="Maximum Percentage" size="small" />
                    <ErrorMessage name="max_percentage" component={MzErrorText} />
                  </Box>
                  <Box display={'flex'} flex={0.5} marginRight="1rem" gap={2} mb>
                    <Button type="submit" variant="primary">
                      Filtrer
                    </Button>
                    <Button onClick={getProfileFilled} variant="primary">
                      Reset
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Box sx={{ height: 300, overflowY: 'auto' }}>
        <ResponsiveContainer width="100%" height={profilesArray.length * 60}>
          <BarChart
            data={profilesArray}
            layout="vertical"
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 60,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" interval={0} tick={{ textAnchor: 'end', fontSize: '12px' }} />
            <Tooltip />
            <Bar dataKey="percentage" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default BarChartComponent;
