import React from 'react';
import { RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts';

const Speedometer = ({ percentage }) => {
  const data = [
    {
      name: 'Percentage',
      value: percentage,
      fill: '#8884d8',
    },
  ];

  return (
    <RadialBarChart
      width={100}
      height={100}
      cx={50}
      cy={50}
      innerRadius={40}
      outerRadius={40}
      barSize={10}
      data={data}
      startAngle={180}
      endAngle={0}
    >
      <PolarAngleAxis
        type="number"
        domain={[0, 100]}
        angleAxisId={0}
        tick={false}
      />
      <RadialBar
        minAngle={15}
        background
        clockWise
        dataKey="value"
      />
      <text
        x={50}
        y={40}
        textAnchor="middle"
        dominantBaseline="middle"
        className="progress-label"
        style={{ fontSize: '15px', fontWeight: 'bold' }}
      >
        {`${percentage}%`}
      </text>
    </RadialBarChart>
  );
};

export default Speedometer;
