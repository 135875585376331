import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress } from '@mui/material';
import api from '../../api/Api_1_3';
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const CreateEmptyProfiles = () => {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const [profilesNumber, setProfilesNumber] = useState(0);
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    const handleCreateProfiles = async () => {
        setLoading(true); // Set loading to true
        try {
            const res = await api.post('/admin/individuals/store-empty-profiles', {
                profiles_number: profilesNumber
            }, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if(res.status === 200){
                toast.success(res.data.message);
                setTimeout(()=> { 
                    navigate('/admin/gestion-des-customers')
                }, 1000)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 4, gap: 2 }}>
        <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <Typography variant="h5" gutterBottom>Create Empty Profiles</Typography>
            <TextField
                label="Number of Profiles (max: 200)"
                type="number"
                required
                value={profilesNumber}
                onInput={(e) => {
                    if (e.target.value > 200) {
                        e.target.value = 200; // Set value to 200 if it exceeds
                    }
                }}
                onChange={(e) => setProfilesNumber(e.target.value)}
                sx={{ mb: 2, width:'220px' }}
                inputProps={{ min: 1 }} 
            />
            <Box display={'flex'} alignItems={'center'} gap={2}>
                {
                 loading 
                ? 
                <CircularProgress size={24} /> 
                : 
                <Button variant="primary" onClick={handleCreateProfiles} disabled={loading}> {/* Disable button when loading */}
                    Créer les Profiles
                </Button>
                }
                <Button
                    variant="secondary"
                    onClick={() => navigate('/admin/gestion-des-customers')} // Navigate to the desired route
                >
                    Annuler
                </Button>
            </Box>
        </Box>
    );
};

export default CreateEmptyProfiles;