import { Box } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { chipSelector } from "../utils/chipSelector";
import gift from '../imgs/gift_user.svg'
import React from "react";
import { colors } from "../theme/Colors";
import { OpenInNew, SchoolOutlined } from "@mui/icons-material";


function capitalizeName(name) {
  // Split the name into an array of words
  if(name){
    var words = name?.toLowerCase()?.trim().split(/\s+/);

  // Capitalize the first letter of each word
  var capitalizedWords = words.map(function (word) {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  });

  // Join the words back together into a string
  var capitalizedName = capitalizedWords.join(" ");

  // Return the capitalized name
  return capitalizedName;
  }
}

export const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 40,
    headerClassName: "grid-header",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/gestion-des-cartes/${params.row.id}`}>
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
              {params?.row.id}
            </Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "last_name",
    headerName: "Nom",
    description: "This column has a value getter and is not sortable.",
    width: 190,
    align: "left",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/gestion-des-cartes/${params.row.id}`}>
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center", justifyContent:'space-between' }} onClick={() => {}}>
              {params?.row?.last_name?.toUpperCase()}
              {params.row?.is_gift === true ? <img src={gift} alt="gift_icon" width={20} height={20}/> : null}
              {params.row?.is_trained === true ? <SchoolOutlined/> : null}
            </Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "first_name",
    headerName: "Prénom",
    width: 130,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/gestion-des-cartes/${params.row.id}`}>
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
              {capitalizeName(params?.row?.first_name)}
            </Box>
          </Link>
        </Box>
      );
    },
  },

  {
    field: "collaborated_enterprise_commercial_name",
    headerName: "Entreprise",
    description: "Nom de commercial de l'entreprise.",
    width: 150,
    align: "left",
  },
  {
    field: "email",
    headerName: "Email",
    description: "Email du collaborateur.",
    width: 200,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
            {'######'}
            </Box>
        </Box>
      );
    },
  },

  {
    field: "phone_number",
    headerName: "Téléphone",
    description: "Numéro de téléphone du collaborateur.",
    width: 130,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
              {'######'}
            </Box>
        </Box>
      );
    },
  },

  {
    field: "department",
    headerName: "Département",
    description: "Département du collaborateur.",
    width: 130,
    align: "left",
  },

  {
    field: "function",
    headerName: "Fonction",
    description: "Fonction du collaborateur.",
    width: 130,
    align: "left",
  },

  {
    field: "expiration_date",
    headerName: "Date d'expiration",
    description: "Date d'expiration de la carte.",
    width: 150,
    align: "left",
    renderCell: (params) => {
      return params.row.expiration_date ? params.row?.expiration_date?.split("-")[2] + "/" + params.row?.expiration_date?.split("-")[1] + "/" + params.row?.expiration_date?.split("-")[0] : null;
    },
  },
  {
    field: "status_card",
    headerName: "Status carte",
    description: "Statut de la carte collaborateur.",
    width: 150,
    align: "left",
    renderCell: (params) => {
      return chipSelector(params.row.status_card);
    },
  },
  {
    field: "url_profile",
    headerName: "Aperçu du profile",
    description: "Aperçu du profile.",
    width: 150,
    align: "center",
    renderCell: (params) => {
      return <NavLink target="_blank" to={params.row.url_profile}>
              <Box>
                <OpenInNew/>
              </Box>
            </NavLink>;
    },
  },
];
export const rows = [
  {
    id: 1,
    collaborated_enterprise_commercial_name: "TELECOMMUNICATION",
    first_name: "DJEZZY",
    last_name: "DJEZZY",
    phone_number: "65412319848961532",
    department: "123123564941532123",
    function: "Algiers",
    expiration_date: "Algérie",
    action: ["consult"],
    email: "e@bdigitall.com",
    status_card: "active",
  },
];
