import React from "react";
import { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { colors } from "../../theme/Colors";
import IconBdigitall from "../../imgs/login_logo.svg";
import * as Yup from "yup";
import MzLabel from "../../components/ui/MzLabel";
import MzErrorText from "../../components/ui/MzErrorText";
import AlertError from "../../components/ui/AlertError";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import bg from "../../imgs/BG_LOGIN.png";
import { Urls } from "../../api/Urls";
import API from "../../api/Api_1_3";

function ResetPassword() {

  const navigate = useNavigate();
  const { dispatch } = useAuthContext();

  //initialize error, isLoading, and login function
  const [isLoading, setIsLoading] = useState(false)
  const [errorLogin, setErrorLogin] = useState('')

  const onSubmitResetPassword = async (values) =>{
    if(values.password !== values.password_confirmation){
      setErrorLogin('Password Does not match')
      return;
    }

    setIsLoading(true);
    setErrorLogin(null);
    //remove const response = await
    const response = await API.post(
      '/user/reset-password-first-login',
      { password: values.password, password_confirmation: values.password_confirmation },
      {
        headers: { "Content-type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                  },
      }
    )
      .then((response) => {
        // console.log(response);

        if (response.status === 200) {
          // save the user to local storage
          localStorage.setItem("user", JSON.stringify(response.data));

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });

          // update loading state
          setTimeout(() => {
            setIsLoading(false);
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorLogin(error.response.data.message);
        // console.log(error.response.data.message);
        // console.log(error);
      });
  }

  //initial values form state
  const initialValues = {
    password: "",
    password_confirmation: "",
  };

  //validators form state
  const validationSchema = Yup.object({
    password: Yup.string().required("Ce champ est obligatoire"),
    password_confirmation: Yup.string().required("Ce champ est obligatoire"),
  });

  return (
    <Box sx={{ bgcolor: colors.main, backgroundSize: "cover", width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box sx={{ padding: "2.5rem", width: "450px", maxHeight: "560px", borderRadius: "24px", margin: "1rem" }} backgroundColor="white">
        <Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "4rem" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <img src={IconBdigitall} height={100} alt="bdigitall logo" style={{ marginRight: "1rem" }} />
            </Box>
          </Box>
        </Box>

        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitResetPassword}>
          {({ values }) => (
            <Form>
              <Box className="field" sx={{ marginBottom: "1rem" }}>
                <MzLabel htmlFor="email" name="email">
                  Mot de Passe
                </MzLabel>
                <Field
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "16px 10px",
                      },
                    },
                  }}
                  autoComplete="off"
                  name="password"
                  type="password"
                  placeholder="Mot de Passe"
                  id="password"
                  as={TextField}
                  fullWidth
                />
                <ErrorMessage name="password" component={MzErrorText} />
              </Box>

              <Box className="field" sx={{ marginBottom: "2rem" }}>
                <MzLabel htmlFor="password" name="password">
                  Confirmation de Mot de passe 
                </MzLabel>
                <Field
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& .MuiInputBase-input": {
                        padding: "16px 10px",
                      },
                    },
                  }}
                  autoComplete="off"
                  name="password_confirmation"
                  type="password"
                  placeholder="Mot de passe"
                  id="password_confirmation"
                  as={TextField}
                  fullWidth
                />
                <ErrorMessage name="password_confirmation" component={MzErrorText} />
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button sx={{ height: "3.5rem" }} disabled={isLoading} className="submit" type="submit" variant="primary" fullWidth>
                  Reinitialisez mot de passe
                </Button>
              </Box>
              {errorLogin && <AlertError>{errorLogin}</AlertError>}
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}

export default ResetPassword;
