import React from "react";
import PauseIcon from "@mui/icons-material/Pause";
import { IconButton } from "@mui/material";
import { colors } from "../../theme/Colors";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useNavigate } from "react-router-dom";

function ButtonDataGrid({ action, ...props }) {
  //navigate
  const navigate = useNavigate();

  switch (action) {
    //suspend button
    case "suspend":
      return (
        <IconButton
          {...props}
          onClick={props.onClick}
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: `${colors.bd_DarkPurple}`,
            "&:hover": { backgroundColor: `${colors.bd_DarkPurple_hover}` },
          }}>
          <PauseIcon htmlColor="white" />
        </IconButton>
      );

    //accept button
    case "accept":
      return (
        <IconButton
          {...props}
          onClick={props.onClick}
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: `${colors.bd_Purple}`,
            "&:hover": { backgroundColor: `${colors.bd_Purple_hover}` },
          }}>
          <CheckRoundedIcon htmlColor="white" />
        </IconButton>
      );

    //refuse button
    case "refuse":
      return (
        <IconButton
          onClick={props.onClick}
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: `${colors.bd_DarkPurple}`,
            "&:hover": { backgroundColor: `${colors.bd_DarkPurple_hover}` },
          }}>
          <ClearRoundedIcon htmlColor="white" />
        </IconButton>
      );

    //refuse button
    case "consult":
      return (
        <IconButton
          onClick={props.onClick}
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: `${colors.main_purple}`,
            "&:hover": { backgroundColor: `${colors.main_purple}` },
          }}>
          <ArrowForwardRoundedIcon htmlColor="white" />
        </IconButton>
      );
  }
}

export default ButtonDataGrid;
