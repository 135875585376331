import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react';
import { Form, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Urls } from '../../api/Urls';
import { ToastContainer, toast } from "react-toastify";
import API from '../../api/Api';
import { ErrorMessage, Field, Formik } from 'formik';
import { Box, TextField, Autocomplete, FormControl, Button, colors } from '@mui/material';
import MzErrorText from "../../components/ui/MzErrorText";
import { Delete, Edit, Group } from '@mui/icons-material';

const PagesGroups = () => {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const navigate = useNavigate();
    const [companies,setCompanies] = useState([])
    const [group,setGroup] = useState([])

    const getEnterprises = ()=>{
            //get companies
            API.get(Urls.GET_ENTREPRISE_TABLE, {
              headers: {
                "Content-type": "application/json",
                accept: "application/json",
                authorization: `Bearer ${access_token}`,
              },
            })
              .then((response) => {
                setCompanies(response.data.enterprises);
                
                
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  localStorage.removeItem("user");
                  navigate("/login", { replace: true });
                  navigate(0, { replace: true });
                }
                // console.log(error);
                if (error?.response?.status === 403) {
                  toast.error("Une erreur est survenue");
                } else {
                  toast.error(error?.response?.data?.message);
                }
              });
    }

    const getGroup = (value)=>{
        API.get('/bdisplay-groups', {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
            params:{ enterprise_id: value.id }
          })
            .then((response) => {
              setGroup(response.data.data);
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
              // console.log(error);
              if (error?.response?.status === 403) {
                toast.error("Une erreur est survenue");
              } else {
                toast.error(error?.response?.data?.message);
              }
            });
    }

    const deleteGroup = async (id)=>{
       await API.delete('/bdisplay-groups/' + id, {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
          })
            .then((response) => {
              if(response.status === 200){
                toast('operation success')
              }
              else{
                toast('operation success')
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
              // console.log(error);
              if (error?.response?.status === 403) {
                toast.error("Une erreur est survenue");
              } else {
                toast.error(error?.response?.data?.message);
              }
            });
    }

      //on page load
      useEffect(() => {
        getEnterprises()
      }, []);
    return ( 
        <main className='flex flex-col gap-4 w-full'>
            <Box maxWidth={'2xl'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box display={'flex'} flexDirection={'column'} alignItems={'start'} justifyContent={'center'} gap={3}>
                <Typography variant="h6">Choisissez l'entreprise</Typography>
                <Formik>
                <FormControl>
                <Box flex={1} alignItems="center" marginRight="1rem" mb={4}>
                    <Field
                      name="Import Method"
                      component={Autocomplete}
                      options={companies?.map(company => company.commercial_name)}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => s}
                      onChange={(event, value) => {
                        if (value !== null) {
                            getGroup(companies.find(company => company.commercial_name === value));
                        }                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Enterprise" sx={{width:250}} />}
                      size="small"
                    />{" "}
                    <ErrorMessage name="Import Method" component={MzErrorText} />
                </Box>
                </FormControl>
                </Formik>
                </Box>
                <Button variant='primary' onClick={()=> navigate('/admin/pages/groups/add')}>Nouveau Group</Button>
            </Box>
            
            <div className='grid grid-cols-3 gap-3 gap-y-8 grid-flow-row'>
            {group.length === 0 ? <h3>No group to show</h3> :
              group.map((grp,index) =>(
                <span key={index} className=' flex items-center justify-between px-6 py-3 rounded-lg shadow-xl black' style={{'backgroundColor':colors.blueGrey}}>
                
                <h4 className='font-medium flex gap-2 items-center'>
                    {grp.identification}
                    <Group/> 
                    {grp.name}
                </h4>
                <div className='flex items-center gap-2'>
                    <button onClick={()=> navigate('/admin/pages/groups/'+ grp.id)} className="bg-purple-800 p-2 hover:bg-opacity-80 hover:transition-all hover:ease-out hover:duration-200 text-white rounded-lg">
                          <Edit/>
                    </button>
                    <button onClick={()=>deleteGroup(grp.id)} className="bg-purple-700 p-2 hover:bg-opacity-80 hover:transition-all hover:ease-out hover:duration-200 text-white rounded-lg">
                          <Delete/>
                    </button>                
                </div>
                </span>
            ))}
            </div>
            <ToastContainer
                style={{ top: "5rem", width: "300px" }}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"/>
        </main>
     );
}
 
export default PagesGroups;