import React from "react";
import { useState, useRef, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Autocomplete,
  Avatar,
  Modal,
  CircularProgress,
  styled,
  useTheme,
  useMediaQuery,
  Stack,
} from "@mui/material";
import { colors } from "../../theme/Colors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Urls } from "../../api/Urls";
import Api from "../../api/Api";
import Cropper from "react-easy-crop";
import getCroppedImg from "../../utils/cropImage";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import MainTitle from "../../components/ui/MainTitle";
import { ToastContainer, toast } from "react-toastify";
import defaultLogo from "../../imgs/logo.png";
import { Link, useNavigate } from "react-router-dom";
import AlertError from '../../components/ui/AlertError'
import API from "../../api/Api_1_3";
import MzLabel from "../../components/ui/MzLabel";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Excel from '../../imgs/excel.svg'
import MyIconButton from "../../components/ui/MyIconButton";
import IconLink from "../../components/ui/IconLink";
import ConfirmationModal from '../../components/ui/ConfirmationModal'
import { ModalStyle } from "../../theme/ModalStyles";
import FileIcon from "../../imgs/file.svg";
import axios from "axios";


function Entreprises() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const [plans,setPlans] = useState([])
  const [errors,setErrors] = useState([])
  const [inLinks, setInLinks] = useState(true);
  const [inDocuments, setInDocuments] = useState(false);
  const [allowOtherField, setAllowOtherField] = useState();
  const [currentVariantLink, setCurrentVariantLink] = useState("primary");
  const [currentVariantDocument, setCurrentVariantDocument] = useState("secondary");
  const [links, setLinks] = useState([]);
  const [selectedLinkToDelete, setSelectedLinkToDelete] = useState();
  const [selectedDocToDelete, setSelectedDocToDelete] = useState();
  const [openAddLink, setOpenAddLink] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteDoc, setOpenDeleteDoc] = useState(false);
  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [possibleLinksList, setPossibleLinksList] = useState();
  const [didUpdate, setDidUpdate] = useState(false);
  const [didUpdateLink, setDidUpdateLink] = useState(false);
  const [didDeleteLink, setDidDeleteLink] = useState(false);
  const [didAddLink, setDidAddLink] = useState(false);
  const [didDeleteDoc, setDidDeleteDoc] = useState(false);
  const [data, setData] = useState();
  const [docs, setDocs] = useState([]);
  const [docToAddName, setDocToAddName] = useState('');

  const addDoc = (values) => {
      setDocs([{
        title: values.brochure.name,
        brochure: values.brochure,
      }])
      handleCloseAddDoc()
  };

  const handleButtonVariantChangeLink = () => {
    if ((currentVariantDocument === "secondary" && currentVariantLink === "primary") || (currentVariantDocument === "primary" && currentVariantLink === "secondary")) {
      setCurrentVariantLink("primary");
      setCurrentVariantDocument("secondary");
    }
  };
  const handleButtonVariantChangeDocument = () => {
    if ((currentVariantLink === "secondary" && currentVariantDocument === "primary") || (currentVariantLink === "primary" && currentVariantDocument === "secondary")) {
      setCurrentVariantDocument("primary");
      setCurrentVariantLink("secondary");
    }
  };

  //initial values link
  const initialValuesAddLink = {
    link_id: "",
    value: "",
    title: "",
  };

  //initial values doc
  const initialValuesAddDoc = {
    brochure: "",
  };

  
  //validation schema link fields
  const validationSchemaAddLink = Yup.object({
    link_id: Yup.string().required("Ce champ est obligatoire"),
    value: Yup.string().required("Ce champ est obligatoire").url(),
    title: Yup.string().required("Ce champ est obligatoire"),
  });

  
  //validation schema add document fields
  const validationSchemaAddDoc = Yup.object({
    brochure: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

    
  //add link modal
    const handleOpenAddLink = () => {
      if (data?.id === null || undefined) {
        toast.error("Aucune ligne séléctionnée");
        return;
      }
      setOpenAddLink(true);
    };
    
    const handleCloseAddLink = () => setOpenAddLink(false);
  
    //delete link modal
    const handleOpenDelete = () => {
      setOpenDelete(true);
    };
    const handleCloseDelete = () => setOpenDelete(false);
  
    //delete document modal
    const handleOpenDeleteDoc = () => {
      setOpenDeleteDoc(true);
    };
    const handleCloseDeleteDoc = () => setOpenDeleteDoc(false);
  
    //update link modal
    const handleOpenUpdate = () => {
      setOpenUpdate(true);
    };
    const handleCloseUpdate = () => setOpenUpdate(false);
  
    //add doc modal
    const handleOpenAddDoc = () => {
      setOpenAddDoc(true);
    };
    const handleCloseAddDoc = () => setOpenAddDoc(false);

    const getLogoUrl = (title) => {
      const link = possibleLinksList?.find((link) => link.title === title);
      return link ? link.url_logo : `${process.env.REACT_APP_BASE_URL}/data/links/6/link.svg`;
    };
  

  const deleteDoc = (id_doc) => {
    Api.delete(
      Urls.DELETE_USER_DOCUMENT + `/${id_doc}`,

      {
        headers: {
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => {

        if (response.status === 200) {
          setDidDeleteDoc(!didDeleteDoc);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  //initial values
  const initialValues = {
    social_reason: "",
    commercial_name: "",
    commercial_register: "",
    nif:"",
    country_name:"",
    city_name:"",
    postal_code:"",
    status:'CONFIRMED',
    address:"",
    activation_date:"",
    expiration_date:"",
    logo:null,
    type: "CLIENT",
    plan_id: "",
    collaborators_file:"",
    cover_picture:"",
    default_background_color: colors.main_purple,
    default_text_color: "#FFFFFF",
    website: "",
    admin:{
      first_name:"",
      last_name:"",
      email:"",
      phone_number:"",
      sex:"",
      department:"",
      function:""
    }
  };

  const XLSX_SUPPORTED_FORMATS = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // XLSX
    "application/vnd.ms-excel", // XLS
  ];

  //validation schema
  const validationSchema = Yup.object({
    social_reason: Yup.string().required("Ce champ est obligatoire"),
    commercial_name: Yup.string().required("Ce champ est obligatoire"),
    plan_id: Yup.string().required("Ce champ est obligatoire"),
    status: Yup.string().required("Ce champ est obligatoire"),
    type: Yup.string().required("Ce champ est obligatoire"),
    commercial_register: Yup.string(),
    nif: Yup.string(),
    country_name: Yup.string().required("Ce champ est obligatoire"),
    city_name: Yup.string(),
    postal_code: Yup.string().required("Ce champ est obligatoire"),
    address: Yup.string(),
    email: Yup.string().required("Ce champ est obligatoire").email('Email invalide'),
    phone_number:Yup.number().required("Ce champ est obligatoire"),
    fix:Yup.number(),
    website: Yup.string(),
    activation_date: Yup.string("Ce champ est obligatoire"),
    expiration_date: Yup.string().when("collaborators_file", {
      is: (value) => value !== undefined && value !== null && value !== "",
      then: Yup.string().required("Ce champ est obligatoire"),
    }),
    logo: Yup.mixed(),
    default_background_color: Yup.string(),
    default_text_color: Yup.string(),
    cover_picture: Yup.mixed(),
    collaborators_file: Yup.mixed()
    .test(
      "fileFormat",
      "Format invalide",
      function (value) {
        if (this.parent.collaborators_file) {
          return XLSX_SUPPORTED_FORMATS.includes(value?.type);
        }
        return true;
      }
    ),
    admin: Yup.object().shape({
      first_name: Yup.string().required("Ce champ est obligatoire"),
      last_name: Yup.string().required("Ce champ est obligatoire"),
      email: Yup.string().required("Ce champ est obligatoire").email('Email invalide'),
      phone_number: Yup.number().required("Ce champ est obligatoire"),
      sex: Yup.string(),
      department: Yup.string(),
      function: Yup.string(),
    }),
    
  });
  

  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setProgress(percentCompleted);
    },
  };

  const [onUploadImage, setOnUploadIage] = useState(false);

  //submit entreprise
  const onSubmitCreateEntreprise = async (values) => {
    setErrors([]) 
    setOnUploadIage(true);

    if(values.admin.phone_number === '' || values.admin.phone_number === null){
      delete values.admin.phone_number
    }

    if(values.admin.department === '' || values.admin.department === null){
      delete values.admin.department
    }

    if(values.admin.function === '' || values.admin.function === null){
      delete values.admin.function
    }

    if(values.admin.sex === '' || values.admin.sex === null){
      delete values.admin.sex
    }


    const formData = new FormData();
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (value !== null && value !== "") {
        if (key === "admin") {
          Object.keys(value).forEach((adminKey) => {
            formData.append(`admin[${adminKey}]`, value[adminKey]);
          });
        } else {
          formData.append(key, value);
        }
      }
    });

    if(docs[0]?.brochure && values?.brochure !== null  ){
      formData.append('brochure',docs[0].brochure)
    }
    if(links.length !== 0){
      // Append each link object to the formData object
      links.forEach((link, index) => {
        formData.append(`links[${index}][link_id]`, link.link_id);
        formData.append(`links[${index}][title]`, link.title);
        formData.append(`links[${index}][value]`, link.value);
      });
    }

    await API.post(
      '/admin/enterprises/store-manual',
      formData,
      configs
    )
      .then((response) => {

        if (response.status === 201) {
          toast.success("Entreprisé créé avec succès");
          setTimeout(() => {
            navigate("/admin/gestion-des-entreprises");
          }, 2000);
        }
        setOnUploadIage(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error.response.data.message)
          setErrors(!error.response.data.message ? error.response.data.errors : [])
        }
        setOnUploadIage(false);
      });
  };

  const getPlans = async () => {
    const res = await Api.get('/admin/plans',{
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
    const data = await res.data.data
    const values = data.map((item) => ({ id: item.id, name: item.name }))
    setPlans(values);
  }

  const deleteLink = (id_link) => {
    setLinks(links.filter((l)=> l.link_id !== id_link))
 };

  //logo state
  const [image, setImage] = useState(defaultLogo);
  const [selectedFileName, setSelectedFileName] = useState("");
  //crop image config
  const formikRef = useRef();
  const [format, setFormat] = useState("");
  const [onImage, setOnImage] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const minDate = new Date();
  const FILE_SIZE = 5000 * 1024;
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };
  const onCrop = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, format);
    setImage(URL.createObjectURL(croppedImage));
    var file = new File(
      [croppedImage],
      `${Math.floor(Math.random() * 10000000000000000)}.${
        format.split("/")[1]
      }`,
      { lastModified: 1534584790000, type: [`${format}`] }
    );
    if (file && croppedImage) {
      setOnImage(false);
      formikRef.current.values.logo = file;
    }
  };
  
  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };

  const addLink = (values) => {
    setLinks([
      ...links,
      {
        link_id: values.link_id,
        title: values.title,
        value: values.value,
      }
    ]);

    setDidAddLink(!didAddLink);
    handleCloseAddLink();
  };

  const updateLink = (values) => {
    const updatedLinks = links.map((l) => {
      if (l.link_id === values.link_id) {
        return { ...l, title: values.title, value: values.value };
      }
      return l;
    });
    setLinks(updatedLinks);
    handleCloseUpdate()
  };

  useEffect(()=>{
    getPlans()
    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => {
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  },[])

  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    const response = await axios.get('https://restcountries.com/v3.1/all');
    return response.data.map(country => ({
      code: country.cca2,
      name: country.name.common,
      geonameId: country.geonameId || null,
    }));
  };

  const formats = [
    {
      id:0,
      name: 'SARL',
    },
    {
      id:1,
      name: 'EURL',
    },
    {
      id:2,
      name: 'SPA',
    },
    {
      id:3,
      name: 'SNC',
    },
    {
      id:4,
      name: 'DC',
    },
    {
      id:5,
      name: 'SCS',
    },
    {
      id:6,
      name: 'Groupement',
    },
  ]

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountries();
        setCountries(countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
      getCountries();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFileName(file.name);
      formikRef.current.setFieldValue('collaborators_file', file);
    }
  };

  const handleRemoveFile = (event) => {
    setSelectedFileName('');
      formikRef.current.setFieldValue('collaborators_file', null);
  };

  function isObjEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

// Cover Section
const [coverImage, setCoverImage] = useState();
const [onCoverImage, setOnCoverImage] = useState(false);
const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
const [coverZoom, setCoverZoom] = useState(1);
const [coverCroppedAreaPixels, setCoverCroppedAreaPixels] = useState();
const [coverFormat, setCoverFormat] = useState("");
const [coverProgress, setCoverProgress] = useState(0);
const [onUploadCoverImage, setOnUploadCoverImage] = useState(false);
const [change, setChange] = useState(false);
const coverImageProfile = useRef();
const onCoverCropComplete = (croppedArea, croppedAreaPixels) => {
  setCoverCroppedAreaPixels(croppedAreaPixels);
};
const onCoverCrop = async () => {
  const croppedImage = await getCroppedImg(coverImage, coverCroppedAreaPixels, coverFormat);
  setCoverImage(URL.createObjectURL(croppedImage));
  var file = new File([croppedImage], `${Math.floor(Math.random() * 10000000000000000)}.${coverFormat.split("/")[1]}`, { lastModified: 1534584790000, type: [`${coverFormat}`] });
  if (file && croppedImage) {
    setOnCoverImage(false);
    formikRef.current.values.cover_picture = file;
    file = null;
  }
};
const theme = useTheme();
const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
const getAvatarStyle = () => {
  let width;
  if (isXsScreen || isSmScreen) {
    width = '100%';
  } else if (isMdScreen) {
    width = '45.3125rem'; // 725px
  } else if (isLgScreen) {
    width = '45.3125rem'; // 725px
  }
  return {
    width
  };
};
const flexDirection = isSmScreen ? 'column' : isMdScreen ? 'column' : 'row';
// End: Cover Section
  return (
    <Box>
      {/* Header Section */}
      <Box>
        <Typography variant="h5" sx={{ marginBottom: "1.5rem", fontWeight: "500" }}>
          Création d'une entreprise
        </Typography>
        <ToastContainer
          style={{ top: "5rem" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        {/* FORMULAIRE  */}

        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitCreateEntreprise(values, access_token);
          }}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <Box
                display="flex"
                sx={{
                  flexDirection: { lg: "column", xs: "column" },
                  gap: "2rem",
                }}
              >
                {/* Cover Picture Section */}
                <Box display="flex" flexDirection="column">
                      <MzLabel>Coverture de l'entreprise </MzLabel>
                        <Avatar
                          sx={[getAvatarStyle(),{
                            position: "relative",
                            borderRadius: "20px",
                            height: "14rem", // 224px
                            border: "1px solid #C9C5D2",
                            marginBottom: "1rem",
                          }]}
                          variant="rounded"
                        >
                          {coverImage ? (
                              <img alt="cover-pic" ref={coverImageProfile} src={coverImage} style={{ width: "100%", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                            ) : data?.url_cover_picture ? (
                              <img alt="cover-pic" ref={coverImageProfile} src={data?.url_cover_picture} style={{ width: "100%", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                            ) : (
                              <img alt="cover-pic" src={'https://placehold.co/725x224'} style={{ width: "45.3125rem", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                          )}                          
                          <IconButton
                            sx={{
                              zIndex: "33",
                              position: "absolute",
                              right: "0.5rem",
                              bottom: "0.5rem",
                              bgcolor: `${colors.main_purple}`,
                              "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                            }}
                            component="label"
                          >
                            <input
                              hidden
                              id="cover_picture"
                              name="cover_picture"
                              type="file"
                              onChange={(event) => {
                                setFieldValue("cover_picture", event.target.files[0] ? event.target.files[0] : null);
                                if (event.target.files[0]) {
                                  setOnCoverImage(true);
                                  setCoverImage(URL.createObjectURL(event.target.files[0]));
                                  setCoverFormat(event.target.files[0].type);
                                }
                              }}
                            />
                            <AddRoundedIcon htmlColor="white" />
                          </IconButton>
                        </Avatar>
                        <Modal
                          sx={{ backgroundColor: "#3f3f3f" }}
                          open={onCoverImage}
                          onClose={() => {
                            setCoverImage(null);
                            formikRef.current.values.cover_picture = "";
                            setOnCoverImage(false);
                          }}
                        >
                          <Box height="100%" width="100%" margin="0 auto">
                            <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <Box height="100%" width="100%" display="flex" flexDirection="column">
                                <Cropper style={styleCropper} image={coverImage} crop={coverCrop} zoom={coverZoom} aspect={725 / 224} onCropChange={setCoverCrop} onCropComplete={onCoverCropComplete} onZoomChange={setCoverZoom} />
                              </Box>
                            </Box>
                            <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                              <MzErrorText>{errors.cover_picture ? errors.cover_picture : null}</MzErrorText>
                              <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                                <Button disabled={errors?.cover_picture ? true : false} onClick={onCoverCrop} variant="primary">
                                  Confirmer
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    setCoverImage(null);
                                    formikRef.current.values.cover_picture = "";
                                    setOnCoverImage(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                              </Box>
                              <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                                <Typography color="white">Niveau de zoom : </Typography>
                                <input type="range" min={1} max={3} step={0.1} value={coverZoom} onInput={(e) => setCoverZoom(e.target.value)} />
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                        <Box>
                          {errors.cover_picture ? <MzErrorText>{errors.cover_picture}</MzErrorText> : null}
                        </Box>

                        <Box display="flex" flexDirection="column" alignItems="end" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                          <Box>
                            {onUploadCoverImage && (
                              <Box display="flex" alignItems="center" gap="1rem">
                                <CircularProgress size={30} />
                                <Typography width="20px" color={colors.main_purple}>
                                  {coverProgress ? coverProgress + "%" : null}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                </Box>
              <Box 
                display="flex"
                sx={{
                  flexDirection: { lg: "row", xs: "column" },
                  gap: "2rem",
                }}>

                {/* Admin infos */}
                <Box>
                  <Box>
                    <MainTitle
                      title="Informations de l'admin"
                      icon="person"
                    />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    marginBottom="1rem"
                    width={'full'}
                  >
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="admin.first_name"
                        as={TextField}
                        variant="outlined"
                        label="Prénom*"
                        size="small"
                      />
                      <ErrorMessage
                        name="admin.first_name"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="admin.last_name"
                        as={TextField}
                        variant="outlined"
                        label="Nom*"
                        size="small"
                      />
                      <ErrorMessage
                        name="admin.last_name"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="admin.email"
                        as={TextField}
                        variant="outlined"
                        label="Email*"
                        size="small"
                      />
                      <ErrorMessage
                        name="admin.email"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="admin.phone_number"
                        as={TextField}
                        variant="outlined"
                        label="Numéro de téléphone*"
                        size="small"
                      />
                      <ErrorMessage
                        name="admin.phone_number"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="admin.department"
                        as={TextField}
                        variant="outlined"
                        label="Département"
                        size="small"
                      />
                      <ErrorMessage
                        name="admin.department"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="admin.function"
                        as={TextField}
                        variant="outlined"
                        label="Fonction"
                        size="small"
                      />
                      <ErrorMessage
                        name="admin.function"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        sx={{ maxWidth: '100%' }}
                        name="admin.sex"
                        component={Autocomplete}
                        value={values.admin.sex}
                        options={["MALE", "FEMALE"]}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        getOptionLabel={(s) => s}
                        onChange={(event, value) => {
                          setFieldValue("admin.sex", value ? value : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="autocomplete"
                            label="Sexe"
                          />
                        )}
                        size="small"
                      />
                      <ErrorMessage
                        name="admin.sex"
                        component={MzErrorText}
                      />
                    </Box>

                    {/*  color sliders */}
                  <Box display="flex" flexDirection="column" gap="1rem" mb={2}>
                    <Box display="flex" flexDirection={'column'} alignItems="start" gap="1rem">
                    <Typography> Couleur du Background:</Typography>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                      <Field
                        name="default_background_color"
                        type="color"
                        as={TextField}
                        variant="outlined"
                        size="small"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <Typography>
                        <Field name="default_background_color" />
                      </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection={'column'} alignItems="start" gap="1rem">
                    <Typography>Couleur du Text:</Typography>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Field
                          name="default_text_color"
                          type="color"
                          as={TextField}
                          variant="outlined"
                          size="small"
                          style={{ width: "50px", height: "50px" }}
                        />
                        <Typography>
                          <Field name="default_text_color" />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>


                    <Box sx={{ maxWidth: { xs: "350px" }, width: { md: "350px" } }}>
          <Box sx={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
            <Button
              onClick={() => {
                setInLinks(true);
                setInDocuments(false);
                handleButtonVariantChangeLink();
              }}
              variant={currentVariantLink}
            >
              Liens
            </Button>
            <Button
              onClick={() => {
                setInDocuments(true);
                setInLinks(false);
                handleButtonVariantChangeDocument();
              }}
              variant={currentVariantDocument}
            >
              Brochure
                      </Button>
                    </Box>

          {inLinks && (
            <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
              {links?.map((l) => (
                <Box key={l.id} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <IconLink title={l.title} icon={getLogoUrl(l.title)} value={l.value} />
                  <Box>
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="edit"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        if (l.slug === "other") {
                          setAllowOtherField(true);
                        } else {
                          setAllowOtherField(false);
                        }
                        handleOpenUpdate();
                      }}
                    />
                    <MyIconButton
                      sx={{ padding: "1rem" }}
                      name="delete"
                      onClick={() => {
                        setSelectedLinkToDelete(l);
                        handleOpenDelete();
                      }}
                    />
                  </Box>
                </Box>
              ))}

              <ConfirmationModal title="Supprimer un lien" message="Êtes-vous sûr de bien vouloir supprimer ce lien ?" acceptLabel="oui" refuseLabel="non" open={openDelete} onClose={handleCloseDelete} acceptMethod={deleteLink} acceptMethodParams={selectedLinkToDelete?.link_id} />

              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={handleOpenAddLink}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>

              {/* Modal add link */}
              <Modal open={openAddLink} onClose={handleCloseAddLink}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter un lien
                  </Typography>
                  {/* form add link */}
                  <Formik initialValues={initialValuesAddLink} validationSchema={validationSchemaAddLink} onSubmit={addLink}>
                    {({ values, setFieldValue }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.slug === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = {};
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");
                              setFieldValue("url_logo", value ? value.url_logo : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value.title);
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddLink} variant="third">
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form add link */}
                </Box>
              </Modal>

              {/* modal update link */}
              <Modal open={openUpdate} onClose={handleCloseUpdate}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Mettre à jour un lien
                  </Typography>

                  {/* form update link */}
                  <Formik initialValues={{ link_id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value ?? "", title: selectedLinkToDelete?.title ?? "" }} validationSchema={validationSchemaAddLink}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field
                            fullWidth
                            name="link_id"
                            component={Autocomplete}
                            defaultValue={{ id: selectedLinkToDelete?.link_id ?? "", value: selectedLinkToDelete?.value, title: selectedLinkToDelete?.title }}
                            options={possibleLinksList}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            getOptionLabel={(pl) => (pl.title === "other" ? "Autre" : pl.title)}
                            onChange={(event, value) => {
                              if (value === null) {
                                value = { link_id: "" };
                                setFieldValue("title", "");
                                setFieldValue("link_id", "");
                              }

                              setFieldValue("link_id", value ? value.id : "");
                              setFieldValue("title", value ? value.title : "");

                              if (value.slug === "other") {
                                setAllowOtherField(true);
                                setFieldValue("title", value ? "Site web" : "");
                              } else {
                                setAllowOtherField(false);
                              }
                            }}
                            renderInput={(params) => <TextField {...params} name="autocomplete" label="Lien vers" />}
                            size="small"
                          />
                          <ErrorMessage name="link_id" component={MzErrorText} />
                        </Box>

                        {allowOtherField ? (
                          <Box className="field" sx={{ marginBottom: "1rem" }}>
                            <Field name="title" type="input" label="Titre" id="title" as={TextField} fullWidth size="small" />
                            <ErrorMessage name="title" component={MzErrorText} />
                          </Box>
                        ) : (
                          ""
                        )}

                        <Box className="field" sx={{ marginBottom: "1rem" }}>
                          <Field name="value" type="input" label="Url" id="value" as={TextField} fullWidth size="small" />
                          <ErrorMessage name="value" component={MzErrorText} />
                        </Box>

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button
                            variant="primary"
                            onClick={() => {
                              if (isObjEmpty(errors)) {
                                updateLink(values);
                              }
                            }}
                          >
                            Mettre à jour
                          </Button>
                          <Button onClick={handleCloseUpdate} variant="third">
                            Annuler
                          </Button>
                        </Box>
                        {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                        <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                      </Form>
                    )}
                  </Formik>
                  {/* end form update link */}
                </Box>
              </Modal>
              {/* end modal update link  */}
            </Box>
          )}



          {inDocuments && (
            <Box width="100%" sx={{ backgroundColor: "#F9F6FF", borderRadius: "2rem", padding: "0.5rem" }}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                {(docs.length > 0 && docs[0]?.title) && <IconLink title={docs[0]?.title} value={docToAddName} />}
                </Box>
              <IconButton
                sx={{
                  margin: "0.5rem 1rem",
                  bgcolor: `${colors.main_purple}`,
                  "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                }}
                onClick={() => {
                  handleOpenAddDoc();
                }}
              >
                <AddRoundedIcon htmlColor="white" />
              </IconButton>
              {/* Modal add DOC */}
              <Modal open={openAddDoc} onClose={handleCloseAddDoc}>
                <Box sx={ModalStyle}>
                  <Typography fontSize="1.5rem" fontWeight="600" mb="2rem">
                    Ajouter une Brochure
                  </Typography>
                  {/* form add doc */}
                  <Formik initialValues={initialValuesAddDoc} validationSchema={validationSchemaAddDoc} onSubmit={addDoc}>
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        {progress !== 0 && (
                          <Stack margin="1rem 0rem" gap="0.5rem" direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
                            <Typography color={colors.main_purple}>{progress ? progress + "%" : null}</Typography>
                            <CircularProgress sx={{ color: `${colors.main_purple}` }} />
                          </Stack>
                        )}

                        {/* doc file */}
                        <Box display="flex" flexDirection="column" sx={{ marginBottom: "1rem" }}>
                          <Button variant="upload" sx={{ overflow: "hidden", height: "200px", display: "flex", flexDirection: "column" }} component="label">
                            <img src={FileIcon} width="50px" alt="" style={{ marginBottom: "0.5rem" }} />
                            <input
                              style={{ opacity: "0%", width: "100%", height: "100%", position: "absolute" }}
                              id="document"
                              name="document"
                              type="file"
                              onChange={(event) => {
                                const doc = event.target.files[0];
                                setFieldValue("brochure", event.target.files[0]);
                                setDocToAddName(doc.name);
                              }}
                            />
                            <Typography sx={{ fontSize: "1rem", fontWeight: "400" }}>Déposer votre fichier</Typography>
                          </Button>
                          <MzErrorText>{errors.brochure}</MzErrorText>
                        </Box>
                        {docToAddName && (
                          <Box>
                            <Typography sx={{ margin: "1rem 0rem", color: `${colors.main_purple}`, fontWeight: "500", textDecoration: "underline" }} fontSize="0.875rem">
                              {docToAddName}
                            </Typography>
                          </Box>
                        )}

                        {/* confirm add doc */}
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary" type="submit">
                            Ajouter
                          </Button>
                          <Button onClick={handleCloseAddDoc} variant="third">
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                  {/* end form add DOC */}
                </Box>
              </Modal>
              <ConfirmationModal title="Supprimer un document" message="Êtes-vous sûr de bien vouloir supprimer ce document ?" acceptLabel="oui" refuseLabel="non" open={openDeleteDoc} onClose={handleCloseDeleteDoc} acceptMethod={deleteDoc} acceptMethodParams={selectedDocToDelete?.id} />
            </Box>
          )}
                    </Box>

                  </Box>
                </Box>
                
                <Box display="flex" flexDirection="column"  >
                    <MzLabel>Logo de l'entreprise</MzLabel>
                    <Avatar
                      sx={{
                        position: "relative",
                        borderRadius: "20px",
                        width: "12rem",
                        height: "12rem",
                        border: "1px solid #C9C5D2",
                        marginBottom: "1rem",
                      }}
                      variant="rounded"
                    >
                      {image ? (
                        <img
                          src={image}
                          alt="The current file"
                          style={{ width: "13rem" }}
                        />
                      ) : (
                        <img
                          src={values.logo}
                          alt="The current file"
                          style={{ width: "13rem" }}
                        />
                      )}
                      <IconButton
                        sx={{
                          zIndex: "33",
                          position: "absolute",
                          right: "0.5rem",
                          bottom: "0.5rem",
                          bgcolor: `${colors.main_purple}`,
                          "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                        }}
                        component="label"
                      >
                        <input
                          hidden
                          id="logo"
                          name="logo"
                          type="file"
                          onChange={(event) => {
                            setFieldValue(
                              "logo",
                              event.target.files[0]
                                ? event.target.files[0]
                                : null
                            );
                            if (event.target.files[0]) {
                              setOnImage(true);
                              setImage(
                                URL.createObjectURL(event.target.files[0])
                              );
                              setFormat(event.target.files[0].type);
                            }
                          }}
                        />
                        <AddRoundedIcon htmlColor="white" />
                      </IconButton>
                    </Avatar>
                    <Modal
                      sx={{ backgroundColor: "#3f3f3f" }}
                      open={onImage}
                      onClose={() => {
                        setImage(null);
                        formikRef.current.values.logo = "";
                        setOnImage(false);
                      }}
                    >
                      <Box height="100%" width="100%" margin="0 auto">
                        <Box
                          sx={{
                            height: "80%",
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                          >
                            <Cropper
                              style={styleCropper}
                              image={image}
                              crop={crop}
                              zoom={zoom}
                              aspect={1 / 1}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />
                          </Box>
                        </Box>
                        <Box
                          position="absolute"
                          bottom="5%"
                          left="0"
                          right="0"
                          gap="1rem"
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <MzErrorText>
                            {errors.logo ? errors.logo : null}
                          </MzErrorText>
                          <Box
                            width="fit-content"
                            display="flex"
                            flexDirection="row"
                            gap="1rem"
                            alignItems="center"
                          >
                            <Button
                              disabled={errors?.logo ? true : false}
                              onClick={onCrop}
                              variant="primary"
                            >
                              Confirmer
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => {
                                setImage(null);
                                formikRef.current.values.logo = "";
                                setOnImage(false);
                              }}
                            >
                              Annuler
                            </Button>
                          </Box>
                          <Box
                            display="flex"
                            gap="0.5rem"
                            sx={{
                              flexDirection: {
                                xs: "column",
                                md: "row",
                                lg: "row",
                                sm: "row",
                              },
                              alignItems: "center",
                            }}
                          >
                            <Typography color="white">
                              Niveau de zoom :{" "}
                            </Typography>
                            <input
                              type="range"
                              min={1}
                              max={3}
                              step={0.1}
                              value={zoom}
                              onInput={(e) => setZoom(e.target.value)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Modal>
                    <ErrorMessage name="logo" component={MzErrorText} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap="0.5rem"
                      sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                    >
                      <Box>
                        {onUploadImage && (
                          <Box display="flex" alignItems="center" gap="1rem">
                            <CircularProgress size={30} />
                            <Typography width="20px" color={colors.main_purple}>
                              {progress ? progress + "%" : null}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                </Box>

                {/* SECTION ENTREPRISE INFOS */}
                <Box>
                  <Box>
                    <MainTitle
                      title="Informations de l'entreprise"
                      icon="entreprise_purple"
                    />
                  </Box>

                  <Box
                    display="grid"
                    gridTemplateColumns="repeat(2, 1fr)"
                    gridTemplateRows="repeat(7, auto)"
                    marginBottom="1rem"
                    width={'full'}
                  >
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                    <Autocomplete
                      options={formats}
                      getOptionLabel={(option) => option.name || ''}
                      value={formats.find(c => c.name === values.social_reason) || null}
                      onChange={(event, newValue) => {
                        setFieldValue('social_reason', newValue ? newValue.name : '');
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Raison Social*"
                          variant="outlined"
                          size="small"
                        />
                      )}
                    />
                      <ErrorMessage
                        name="social_reason"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="commercial_name"
                        as={TextField}
                        variant="outlined"
                        label="Nom commercial*"
                        size="small"
                      />
                      <ErrorMessage
                        name="commercial_name"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        sx={{ maxWidth: '100%' }}
                        name="type"
                        component={Autocomplete}
                        value={values.type}
                        options={["GIFT", "CLIENT"]}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        getOptionLabel={(s) => s}
                        onChange={(event, value) => {
                          setFieldValue("type", value ? value : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="autocomplete"
                            label="Type Entreprise*"
                          />
                        )}
                        size="small"
                      />
                      <ErrorMessage
                        name="company_status"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      sx={{ maxWidth: '100%' }}
                      name="plan_id"
                      component={Autocomplete}
                      value={plans.find((plan) => plan.id === values.plan_id) || null}
                      options={plans}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        setFieldValue("plan_id", value ? value.id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="autocomplete"
                          label="Plan*"
                        />
                      )}
                      size="small"
                    />

                    <ErrorMessage name="plans" component={MzErrorText} />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="commercial_register"
                        as={TextField}
                        type="number"
                        variant="outlined"
                        label="Registre Commercial"
                        size="small"
                      />
                      <ErrorMessage
                        name="commercial_register"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="nif"
                        as={TextField}
                        type="number"
                        variant="outlined"
                        label="NIF"
                        size="small"
                      />
                      <ErrorMessage
                        name="nif"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Autocomplete
                          options={countries}
                          getOptionLabel={(option) => option.name || ''}
                          value={countries.find(c => c.name === values.country_name) || null}
                          onChange={(event, newValue) => {
                            setFieldValue('country_name', newValue ? newValue.name : '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Pays*"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      <ErrorMessage
                        name="country_name"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="city_name"
                        as={TextField}
                        variant="outlined"
                        label="Ville"
                        size="small"
                      />
                      <ErrorMessage
                        name="city_name"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="postal_code"
                        as={TextField}
                        type="number"
                        variant="outlined"
                        label="Code Postal"
                        size="small"
                      />
                      <ErrorMessage
                        name="postal_code"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="address"
                        as={TextField}
                        variant="outlined"
                        label="Address"
                        size="small"
                      />
                      <ErrorMessage
                        name="address"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="email"
                        as={TextField}
                        type="email"
                        variant="outlined"
                        label="Email De l'entreprise*"
                        size="small"
                      />
                      <ErrorMessage
                        name="email"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="phone_number"
                        as={TextField}
                        variant="outlined"
                        label="Téléphone De l'entreprise*"
                        size="small"
                      />
                      <ErrorMessage
                        name="phone_number"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="fix"
                        as={TextField}
                        variant="outlined"
                        label="Téléphone Fix De l'entreprise"
                        size="small"
                      />
                      <ErrorMessage
                        name="fix"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="website"
                        as={TextField}
                        variant="outlined"
                        label="Site Web"
                        size="small"
                      />
                      <ErrorMessage
                        name="website"
                        component={MzErrorText}
                      />
                    </Box>
                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                        gridColumn:'span 2 / span 2'
                      }}
                    >
                          <Box display={'flex'} width={'100%'} justifyContent={'space-between'} alignItems={'center'} gap={3} mb={1}>
                            <label htmlFor="collaborators_file font-medium text-lg">Fichier Collaborateurs</label>
                            <Link style={{ textDecoration: "none", display:'flex',alignItems:'center', gap:'.5rem' }} to="/BDigitAll Template Fichier Collaborateur.xlsx" target="_blank" download>
                              <img src={Excel} alt="excel_logo" width={20} height={10}/>
                              <p className="hover:underline hidden md:block lg:block text-sm font-medium hover:underline-offset-2">
                                Télécharger l'exemplaire
                              </p>
                            </Link>
                          </Box>
                        <Button
                          component="label"
                          role={undefined}
                          sx={{width:'100%'}}
                          variant="upload"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setFieldValue('collaborators_file', file);
                          setSelectedFileName(file.name);
                        }
                      }}
                    >
                     {selectedFileName ? selectedFileName : 'Upload'}
                      <VisuallyHiddenInput type="file" />
                    </Button>
                    {selectedFileName && (
                      <Button
                        sx={{ marginTop: '1rem' }}
                        variant="primary"
                        onClick={handleRemoveFile}
                      >
                        Remove File
                      </Button>
                    )}
                      <ErrorMessage name="collaborators_file" component={MzErrorText} />
                    </Box>

                    {values.collaborators_file ? 
                      <Box marginRight="1rem" marginBottom="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent:'center',
                        gridColumn:'span 2 / span 2'
                      }}
                    >
                        <Field name="activation_date" sx={{'width':'100vw'}} min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} label="Date d'activation" />
                        <ErrorMessage name="activation_date" component={MzErrorText} />
                      </Box> : null
                    }
                    {values.collaborators_file ? 
                      <Box marginRight="1rem" marginBottom="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent:'center',
                        gridColumn:'span 2 / span 2'
                      }}
                    >
                        <Field name="expiration_date" sx={{'width':'100vw'}} min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} label="Date d'expiration*" />
                        <ErrorMessage name="expiration_date" component={MzErrorText} />
                      </Box> : null
                    }
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} gap={'.5rem'}>
                    <Button type="submit" variant="primary">Envoyer</Button>
                    <Button onClick={()=> navigate('/admin/gestion-des-entreprises')} variant="secondary">Annuler</Button>
                  </Box>

                </Box>
              </Box>
              </Box>
            </Form>
          )}
          
        </Formik>
        {errors.length !== 0 && <AlertError>
          <ul className="list-disc">
            {errors.map((error,index)=>(
              <ul>
                <li key={index} className="flex items-center gap-2">
                  <p><strong>Row:</strong> {error.row}</p>
                  <p>{error.msg}</p>
                </li>
              </ul>
            ))}
          </ul>
        </AlertError>}
      </Box>
    </Box>
  );
}

export default Entreprises;
