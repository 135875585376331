import ButtonDataGrid from "../components/ui/ButtonDataGrid";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { chipSelector } from "../utils/chipSelector";
import React from "react";
import { colors } from "../theme/Colors";
import { borderRadius } from "@mui/system";

export const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 60,
    headerClassName: "grid-header",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/gestion-des-commandes/${params.row.id}`}>
            <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{params.row.id}</Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "enterprise_commercial_name",
    headerName: "Entreprise",
    description: "Nom commercial de l'entreprise.",
    width: 250,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/gestion-des-commandes/${params.row.id}`}>
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{params.row.enterprise_commercial_name}</Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "created_at",
    headerName: "Date de commande",
    description: "Date de commande.",
    width: 250,
    align: "left",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/gestion-des-commandes/${params.row.id}`}>
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>
              {params.row.created_at ? params.row?.created_at?.split("T")[0].split("-")[2] + "/" + params.row?.created_at?.split("T")[0].split("-")[1] + "/" + params.row?.created_at?.split("T")[0].split("-")[0] : null}
            </Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "number_of_cards",
    headerName: "Nombre d’abonnements",
    description: "Statut du ticket.",
    width: 200,
    align: "center",
  },
  {
    field: "status",
    headerName: "Statut",
    width: 300,
    align: "left",
    renderCell: (params) => {
      return (
        <Box height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "black", height: "100%", width: "100%", display: "flex" }} to={`/admin/gestion-des-commandes/${params.row.id}`}>
            <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{chipSelector(params.row.status)}</Box>
          </Link>
        </Box>
      );
    },
  },
];
