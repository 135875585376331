import React from "react";
import { Alert } from "@mui/material";
import { colors } from "../../theme/Colors";

function AlertError({ children }) {
  return (
    <Alert
      sx={{
        margin: "1rem 0rem",
        border: `1px solid  ${colors.mjGreenOutline}`,
        borderRadius: "12px",
      }}
      severity="success">
      {children}
    </Alert>
  );
}

export default AlertError;
