import { Box } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import React from "react";
import { colors } from "../theme/Colors";
import { Category, QrCode } from "@mui/icons-material";
import TimelineIcon from '@mui/icons-material/Timeline';


function capitalizeName(name) {
  // Split the name into an array of words
  if(name){
    var words = name?.toLowerCase()?.trim().split(/\s+/);

  // Capitalize the first letter of each word
  var capitalizedWords = words.map(function (word) {
    return word?.charAt(0)?.toUpperCase() + word?.slice(1);
  });

  // Join the words back together into a string
  var capitalizedName = capitalizedWords.join(" ");

  // Return the capitalized name
  return capitalizedName;
  }
}

export const PagesColumns = [
  {
    field: "title",
    headerName: "Titre",
    description: "This column has a value getter and is not sortable.",
    width: 190,
    align: "left",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center", justifyContent:'space-between' }} >
              {params?.row?.title}
            </Box>
        </Box>
      );
    },
  },
  {
    field: "commercial_name",
    headerName: "Nom Commercial",
    width: 150,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
              {capitalizeName(params?.row?.enterprise?.commercial_name)}
            </Box>
        </Box>
      );
    },
  },

  {
    field: "display_type",
    headerName: "Type de Display",
    description: "",
    width: 150,
    align: "left",
  },
  {
    field: "url_qrcode",
    headerName: "Qr Code",
    description: "",
    width: 200,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`${params.row.url_qrcode}`}>
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
              <QrCode/>
            </Box>
          </Link>
        </Box>
      );
    },
  },

  {
    field: "statistics",
    headerName: "Statistiques",
    description: "",
    width: 130,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
            <NavLink
              to={"/admin/page/" + params.row.id+"/statistics"}
              className={`primary-button primary-link-pages underline text-purple-800 font-bold underline-offset-4`}
            >
              <TimelineIcon/>
                </NavLink>
            </Box>
        </Box>
      );
    },
  },

  {
    field: "services",
    headerName: "Services",
    description: "",
    width: 130,
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" } }} height="100%" width="100%">
            <Box sx={{ color: "inherit", width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }} onClick={() => {}}>
            <NavLink
              to={"/admin/" + params.row.id+"/services"}
              className={`primary-button primary-link-pages underline text-purple-800 font-bold underline-offset-4`}
            >
              <Category/>
            </NavLink>
            </Box>
        </Box>
      );
    },
  },,
];
