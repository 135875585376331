import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyAhZN82AXPrB3ZlJ4Kv9mRcbSOekOnJQVA",
    authDomain: "bdigitall.firebaseapp.com",
    projectId: "bdigitall",
    storageBucket: "bdigitall.appspot.com",
    messagingSenderId: "774713158337",
    appId: "1:774713158337:web:c4cf9334e4387c2c754240",
    measurementId: "G-SFKS8ZPBP8",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { app , messaging } ;