import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const LineChart = ({ data }) => {
  const svgRef = useRef();

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear existing content

    const width = 900;
    const height = 400;
    const margin = { top: 20, right: 30, bottom: 50, left: 50 };

    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const months = [];
    const values = [];
    const years = [];

    Object.entries(data).forEach(([year, monthsData]) => {
      Object.entries(monthsData).forEach(([month, value]) => {
        months.push(`${month}`);
        values.push(value);
        years.push(year);
      });
    });

    const xScale = d3.scalePoint()
      .domain(months)
      .range([0, chartWidth])
      .padding(0.5);

    const yScale = d3.scaleLinear()
      .domain([0, d3.max(values)])
      .range([chartHeight, 0]);

    const line = d3.line()
      .x((d, i) => xScale(months[i]))
      .y(d => yScale(d));

    g.append('path')
      .datum(values)
      .attr('fill', 'none')
      .attr('stroke', 'steelblue')
      .attr('stroke-width', 1.5)
      .attr('d', line);

    g.selectAll('.dot')
      .data(values)
      .enter().append('circle')
      .attr('cx', (d, i) => xScale(months[i]))
      .attr('cy', d => yScale(d))
      .attr('r', 3)
      .attr('fill', 'steelblue');

    g.append('g')
      .attr('transform', `translate(0,${chartHeight})`)
      .call(d3.axisBottom(xScale));

    g.append('g')
      .call(d3.axisLeft(yScale));

    // Add X-axis label
    svg.append('text')
      .attr('x', width / 1.1)
      .attr('y', height - margin.bottom / 10)
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .text('months')

    // Add Y-axis label
    svg.append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 5)
      .attr('y', margin.left / 6)
      .attr('text-anchor', 'middle')
      .attr('font-size', '12px')
      .text('percentage');

    // Add year labels below x-axis and separation lines
    const yearsG = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top + chartHeight + 30})`);

    const uniqueYears = [...new Set(years)];
    let lastIndex = 0;

    uniqueYears.forEach((year, index) => {
      const yearMonths = months.filter((_, i) => years[i] === year);
      const xStart = xScale(yearMonths[0]);
      const xEnd = xScale(yearMonths[yearMonths.length - 1]);
      const xMiddle = (xStart + xEnd) / 2;

      yearsG.append('text')
        .attr('x', xMiddle)
        .attr('y', 15)
        .attr('text-anchor', 'middle')
        .text(year);

      if (index > 0) {
        // Draw separation line
        const xLine = xScale(yearMonths[0]) - (xScale.step() / 2);
        yearsG.append('line')
          .attr('x1', xLine)
          .attr('x2', xLine)
          .attr('y1', -20)
          .attr('y2', -5)
          .attr('stroke', 'black')
          .attr('stroke-width', 1);
      }

      lastIndex += yearMonths.length;
    });

    yearsG.append('line')
      .attr('x1', 0)
      .attr('x2', chartWidth)
      .attr('y1', -5)
      .attr('y2', -5)
      .attr('stroke', 'black');
  }, [data]);

  return (
    <svg ref={svgRef} width="900" height="400"></svg>
  );
};

export default LineChart;
