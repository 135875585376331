import { colors } from "../../theme/Colors";

export const DataGridHeaderStyle = {
  marginTop: "1rem",
  height: "70vh",
  "& .MuiDataGrid-columnHeaders": {
    // backgroundColor: `${colors.bd_DarkPurple}`,
    backgroundColor: "black",
    color: "white",
  },
  "& .MuiDataGrid-columnHeaderCheckbox": {
    // backgroundColor: `${colors.bd_DarkPurple}`,
    backgroundColor: "black",
  },
  "& .MuiSvgIcon-fontSizeSmall": {
    color: "white",
  },
};
