import React, { useEffect, useRef, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import { Select, MenuItem, Button, Box, TextField, FormControl, Checkbox, Menu, IconButton, Modal, Autocomplete, Typography, TextareaAutosize, FormControlLabel, Grid, CircularProgress, Tooltip } from "@mui/material";
import { Add, AirplayOutlined, CalendarMonth, Call, Check, Close, CoPresent, Comment, ContactsOutlined, Download, Edit, Email, LinkedIn, List, MoreHoriz, Upload } from "@mui/icons-material";
import API from "../../api/Api_1_3";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ModalStyle } from "../../theme/ModalStyles";
import MzErrorText from "../../components/ui/MzErrorText";
import { colors } from "../../theme/Colors";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { Navigate, useNavigate } from "react-router-dom";
import { StripedDataGrid } from '../../components/ui/StripedGrid'; // Import StripedDataGrid
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BadgeIcon from '@mui/icons-material/Badge';

function BCRM() {
  const navigate = useNavigate()
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedContactType, setSelectedContactType] = useState("");
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [contactId, setContactId] = useState("");
  const [selectedType, setSelectedType] = useState('')
  const [companyID,setCompanyID] = useState('')
  const pageNumbers = [10, 25, 50, 100];
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [loading, setLoading] = useState(false); // Add loading state



  const handleOpenReject = (id) => {
    setContactId(contactId);
    handleCloseMenu();
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
    setContactId('');
  };

  const exportContacts = async () =>{
    try {
      const response = await API.get('/export-contacts', {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob', // Ensure Axios treats the response as a blob
      });
  
      const blob = response.data; // Use response.data to access the blob
  
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `Contacts.xlsx`);
  
      document.body.appendChild(link);
  
      setTimeout(() => {
        link.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 100); // Delay the click to ensure the link is appended
    } catch (error) {
      toast.error('Failed to export contacts');
      console.error('Error fetching the file:', error);
    }
  }

  const handleCheckboxChange = (event, contactId) => {
    if (event.target.checked) {
      setSelectedContacts([...selectedContacts, contactId]);
    } else {
      setSelectedContacts(selectedContacts.filter(id => id !== contactId));
    }
  };

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: '',
    phone: '',
    company_name: '',
    contact_origin: '',
    collector_name: '',
    bdisplay_name: '',
    service_name: '',
    email: '',
    function: ''
  });

  const validationSchema = Yup.object({
    first_name: Yup.string(),
    last_name: Yup.string(),
    phone: Yup.string(),
    company: Yup.string(),
    contact_origin: Yup.string(),
    collector_name: Yup.string(),
    bdisplay_name: Yup.string(),
    service_name: Yup.string(),
    email: Yup.string(),
    function: Yup.string()
  });

  const validationSchemaUser = Yup.object({
    type: Yup.string(),
    send_email: Yup.boolean(),
  });

  const onSubmitSearchBdisplay = async (values, token) => {
    setLoading(true); // Set loading to true
    try {
      const response = await Api.get("/get-contacts", {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${token}`,
        },
        params: {
          contact_group: 'GROUP_COMMERCIAL',
          first_name: values.first_name !== '' ? values.first_name : null,
          last_name: values.last_name !== '' ? values.last_name : null,
          phone: values.phone !== '' ? values.phone : null,
          company_name: values.company_name !== '' ? values.company_name : null,
          contact_origin: values.contact_origin !== '' ? values.contact_origin : null,
          collector_name: values.collector_name !== '' ? values.collector_name : null,
          bdisplay_name: values.bdisplay_name !== '' ? values.bdisplay_name : null,
          service_name: values.service_name !== '' ? values.service_name : null,
          email: values.email !== '' ? values.email : null,
          function: values.function !== '' ? values.function : null
        }
      });
      setContacts(response.data?.contacts);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const handleAddComment = async (values) => {
    const { type, send_email } = values;
    let payload = {
      source: "contacts",
      contact_id: contactId.toString(),
      type: type.toUpperCase(),
    };
    if(values.type === 'Gift'){
      payload.send_email = send_email
    }

    try {
      await API.post(
        "/contacts/convert-to-user",
        payload,
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res)=>{
        toast.success('Operation success');
        setTimeout(()=>{
        navigate('/admin/gestion-des-entreprises/' + res.data?.enterprise?.id)
      },500)
      setSelectedType("")
      })
    } catch (error) {
      console.error("Error adding contact:", error.response.data.message);
      toast.error(error.response.data.message);
    }
    handleCloseReject();
  };

  const [vcfUrlFile, setVcfUrlFile] = useState(null);

  const handleShowMenu = (event, url_vcf_file) => {
    setAnchorEl(event.currentTarget);
    setVcfUrlFile(url_vcf_file);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setVcfUrlFile(null);
  };

  const handleNavLinkClick = () => {
    if (vcfUrlFile) {
      window.open(vcfUrlFile, "_blank");
    }
    handleCloseMenu();
  };

  const showMenu = Boolean(anchorEl);

  const getContacts = () => {
    Api.get(`/get-contacts`, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params:{
        page: currentPage, // Use currentPage for pagination
        perPage: perPage,
        contact_group: 'GROUP_COMMERCIAL'
      }
    })
    .then((response) => {
      setContacts(response.data.contacts ?? []);
      setTotalPages(response.data.meta.total); // Ensure this is set correctly
    })
    .catch((error) => {
      console.log('Error fetching contacts:', error);
    });
  };
  
  useEffect(() => {
    getContacts(); // Call getContacts without parameters
  }, [currentPage, perPage]); // Depend on currentPage and perPage

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page); // Update currentPage to trigger data fetch
    }
  };

  const [possibleActionsList,setList] = useState([
    {
      id: 0,
      type: 'Client',
    },
    {
      id: 1,
      type: 'Gift',
    },
  ])

  const [editedComments, setEditedComments] = useState({});

  const handleEditComment = (id, newComment) => {
    setEditedComments({ ...editedComments, [id]: newComment });
  };

  const handleEditCommentOnSubmit = async (id, newComment) => {
    const trimmed = newComment.trim()
    handleShowEdit(null)
    try {
      await API.patch(
        "/contacts/comments/" + id,
        {
          comment: trimmed,
        },
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts();
    } catch (error) {
      console.error("Error editing comment:", error);
      toast.error("Failed to edit comment");
    }
  };

  const [openComments,setOpenComments] = useState(false)
  const handleClose = () => setOpenComments(false)
  const handleOpen = () => setOpenComments(true)
  
  const [selectedContactId, setSelectedContactId] = useState(null);

  const handleOpenComments = () => {
    setSelectedContactId(contactId);
  };

  const getIconByType = (type, color) => {
    switch (type) {
      case 'Call':
        return <Call sx={{color: color}} />;
      case 'Email':
        return <Email sx={{color: color}} />;
      case 'LinkedIn':
        return <LinkedIn sx={{color: color}} />;
      case 'RDV Présentation':
        return <CalendarMonth sx={{color: color}}/>;
      case 'Démo':
        return <CoPresent sx={{color: color}} />;
      default:
        return <DesignServicesIcon sx={{color: color}} />;
    }
  };

  const [editCommentId, setEditCommentId] = useState(null);

  const handleShowEdit = (commentId) =>{
    setEditCommentId(commentId);
  }
  
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [editCommentId]);

  const columns = [ // Define columns based on the current table
    {
      field: 'fullName',
      headerName: 'Nom Complet',
      width: 200,
      renderCell: (params) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
              {params.row.hasOwnProperty('bdisplay') ? <AirplayOutlined sx={{ color: colors.bd_Purple }} /> : <BadgeIcon sx={{ color: colors.bd_Purple }} />} {/* Source icon */}
              <Tooltip title={params.row.hasOwnProperty('bdisplay') ? 'Source: Bdisplay' : 'Source: Bcard'} arrow> {/* Tooltip for source */}
                  <span style={{ marginLeft: 10 }}>
                      {`${params.row.first_name || ''} ${params.row.last_name || ''}`}
                  </span>
              </Tooltip>
          </div>
      )
    },
    { field: 'phone', headerName: 'Mobile', width: 150, valueGetter: (params) => params.row.phone || '---' },

    { field: 'company', headerName: 'Entreprise', width: 200, valueGetter: (params) => params.row.company_name || '---' },

    { field: 'owner', headerName: 'Owner', width: 200, valueGetter: (params) => (
      (params.row.hasOwnProperty('bdisplay') && params.row.bdisplay.title) || 
      (params.row.hasOwnProperty('user') && params.row.user.first_name) ? 
      (params.row.bdisplay?.title || (params.row.user.first_name + ' ' + params.row.user.last_name || '---')) : '---'
    )
     },

    { field: 'actions', headerName: 'Action', width: 150, renderCell: (params) => (
        <div>
          <IconButton onClick={(event) => {
            handleShowMenu(event, params.row.url_vcf_file);
            setContactId(params.row.id);
            setCompanyID(params.row.company_id);
          }}>
            <MoreHoriz />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={showMenu}
            onClose={handleCloseMenu}
            PaperProps={{ elevation: 2 }}
          >
            <MenuItem onClick={() => handleNavLinkClick(params.row.url_vcf_file)} sx={{display:'flex', gap:1}}>
              <ContactsOutlined sx={{width:25}}/>
              Ajouter Contact
            </MenuItem>
            <MenuItem onClick={() => handleOpenReject(params.row.id)} sx={{display:'flex', gap:1}}>
              <Add/>
              Créer un compte
            </MenuItem>
            <MenuItem onClick={() => {
              handleOpenComments(params.row.id);
              handleOpen();
            }} sx={{display:'flex', gap:1}}>
              <List/>
              List des Commentaires
            </MenuItem>
          </Menu>
        </div>
      )
    }
  ];

  return (
    <div>
      <ToastContainer
        style={{ top: "5rem", width: "300px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex items-start justify-between mb-4">
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <h1 className="text-2xl font-bold">Liste Des Contacts B-CRM</h1>
          <Box className="filter" display="flex" flexDirection="row">
          <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmitSearchBdisplay(initialValues, access_token);
              }}
            >
              {({ values, setFieldValue }) => (
                <FormControl>
                  <Box display="flex">
                    {/*Search fields */}
                    <Box display="flex" maxWidth="1200px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                      <Box display="flex" flexDirection="column" marginBottom="1rem">
                          <Grid container spacing={2}>
                            {/* Existing Filter: Full Name */}
                            <Grid item xs={6} sm={2}>
                            <TextField
                                placeholder="Nom Du Contact"
                                name="last_name"
                                value={initialValues.last_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    last_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Phone */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Téléphone"
                                name="phone"
                                value={initialValues.phone} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    phone: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Company name */}
                            <Grid item xs={6} sm={2}>
                            <TextField
                                placeholder="Entreprise"
                                name="company_name"
                                value={initialValues.company_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    company_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Contact Origin */}
                            <Grid item xs={6} sm={2}>
                              <FormControl fullWidth>
                                <Select
                                  name="contact_origin"
                                  value={initialValues.contact_origin} // Set value from state
                                  onChange={(event) => {
                                    setInitialValues((prevValues) => ({
                                      ...prevValues,
                                      contact_origin: event.target.value,
                                    }));
                                  }}
                                  size="small"
                                >
                                  <MenuItem value="" disabled>
                                    Selectionner l'origin
                                  </MenuItem>
                                  <MenuItem value="BDISPLAY">BDISPLAY</MenuItem>
                                  <MenuItem value="BCARD">BCARD</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* Existing Filter: Collector Name */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Collector Name"
                                name="collector_name"
                                value={initialValues.collector_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    collector_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Bdisplay Name */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Bdisplay Name"
                                name="bdisplay_name"
                                value={initialValues.bdisplay_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    bdisplay_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Service Name */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Service Name"
                                name="service_name"
                                value={initialValues.service_name} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    service_name: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Email */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Email"
                                name="email"
                                value={initialValues.email} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    email: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            {/* Existing Filter: Function */}
                            <Grid item xs={6} sm={2}>
                              <TextField
                                placeholder="Function"
                                name="function"
                                value={initialValues.function} // Set value from state
                                onChange={(event) => {
                                  setInitialValues((prevValues) => ({
                                    ...prevValues,
                                    function: event.target.value,
                                  }));
                                }}
                                sx={{ '::placeholder': { 'fontWeight': 600 } }}
                              />
                            </Grid>

                            <Grid item xl={3} lg={3} sx={6} sm={2}>
                                <Box display={'flex'} gap={1}>
                                  {loading ? (
                                    <CircularProgress size={24} /> // Show loader instead of button
                                  ) : (
                                    <Button variant="primary" onClick={() => onSubmitSearchBdisplay(initialValues, access_token)}>Filtrer</Button>
                                  )}
                                  <Button variant="secondary" onClick={() => {
                                    setInitialValues({ first_name: "", last_name: "", phone: "", company_name: "", contact_origin: "", collector_name: "", bdisplay_name: "", service_name: "", email: "", function: "" });
                                    onSubmitSearchBdisplay(values, access_token);
                                  }}>Réinitialiser</Button>
                                </Box>
                            </Grid>

                          </Grid>
                      </Box>
                    </Box>
                  </Box>
                </FormControl>
              )}
          </Formik>
          </Box>
        </Box>
      </div>
      <Box style={DataGridHeaderStyle}>
        <StripedDataGrid
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          rows={contacts}
          columns={columns}
          rowCount={totalPages}
          rowsPerPageOptions={pageNumbers}
          pageSize={perPage}
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // console.log(newPageSize);
          }}
          autoHeight
          getRowId={(row) => row.id}
          onPageChange={(newPage) => handlePageChange(newPage + 1)}
          paginationMode="server"
          //total number of rows per search
        />
      </Box>
      {openReject && (
        <Formik
          initialValues={{ type: 'Client', send_email: false }}
          onSubmit={(values) => handleAddComment(values)}
          validationSchema={validationSchemaUser}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Modal
              open={openReject}
              onClose={handleCloseReject}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <h2 className="text-2xl mb-4">Créer un compte</h2>
                <Form>
                  <Box className="field" sx={{ marginBottom: "1rem" }}>
                    <Field
                      name="type"
                      component={Autocomplete}
                      options={possibleActionsList}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) => option.type}
                      onChange={(event, value) => {
                        setFieldValue("type", value.type);
                        if(value){
                          setSelectedType(value.type)
                          console.log(value);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Type" />}
                      size="small"
                      error={touched.type}
                    />
                    <ErrorMessage name="type" component={MzErrorText} />
                  </Box>
                  {
                    selectedType === 'Gift' ?
                    <div className="pb-2">
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            name="send_email"
                            color="primary"
                            checked={values.send_email} // if using Formik, ensure `values` is being passed correctly
                          />
                        }
                        label="Envoyer email"
                      />
                      {touched.send_email && errors.send_email && (
                        <ErrorMessage name="send_email" component={MzErrorText} />
                      )}
                    </div> : null
                  }
                  <div className="flex justify-end">
                    <Button variant="secondary" onClick={handleCloseReject} sx={{ mr: 2 }}>Annuler</Button>
                    <Button type="submit" variant="primary">Ajouter</Button>
                  </div>
                </Form>
              </Box>
            </Modal>
          )}
        </Formik>
      )}

      {
        openComments && (
          <Modal
          open={openComments}
          onClose={handleClose}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openComments}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="h6" color="initial">Commentaires</Typography>
                  <Box onClick={handleClose}>
                    <Close/>
                  </Box>
                </Box>
              </MenuItem>
              {contacts.length !== 0 ? contacts.map((contact, index) => (
              <div key={index}>
                {selectedContactId === contact.id && contact.comments && contact.comments.map((comment, commentIndex) => (
                  <MenuItem key={commentIndex} sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', alignItems: 'center', borderBottom: '.5px solid #232323', gap: '.5rem' }}>
                    <Box>
                      {getIconByType(comment.comment_type,colors.bd_Purple)}
                    </Box>
                    <Box width={500} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                      {editCommentId === comment.id ? 
                      <TextareaAutosize
                      rowsMin={3}
                      maxRows={5}
                      value={editedComments[comment.id] || comment.comment}
                      onChange={(e) => handleEditComment(comment.id, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault(); 
                          handleEditCommentOnSubmit(comment.id, editedComments[comment.id]);
                        }
                      }}
                      autoFocus
                      ref={textAreaRef}
                      style={{ width: '100%', fontSize: '1rem', padding:3, fontWeight: 450, backgroundColor:'transparent' }}
                    />
                      : <Typography variant="body1">{editedComments[comment.id] || comment.comment}</Typography>
                    }
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                      <Typography variant="body1" color="initial" sx={{ whiteSpace: 'break-spaces', fontSize: '.9rem', opacity: 0.7 }}>{comment.user} - {comment.is_updated ? comment.updated_at : comment.created_at} {comment.is_updated && '(Modifié)'}</Typography>
                      <Box display={'flex'} gap={1}>
                        {
                        editCommentId === comment.id &&
                        <>
                          <Check sx={{color: colors.mjGreenText}} onClick={() => handleEditCommentOnSubmit(comment.id, editedComments[comment.id])}/>
                          <Close sx={{color: colors.mj_red}} onClick={()=> handleShowEdit(null)}/>
                        </>
                        }
                      </Box>
                    </Box>
                    </Box>
                    <Box>
                      <Button variant={ editCommentId === comment.id ? "disabled" : "secondary"} onClick={() => handleShowEdit(comment.id)}><Edit/></Button>
                    </Box>
                  </MenuItem>
          ))}
              </div>
              )) : <div>Aucun contact ajoutée.</div>}


            </Menu>

          </Modal>
        )
      }
    </div>
  );
}

export default BCRM;