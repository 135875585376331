import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
  StepButton,
  Modal,
} from "@mui/material";
import { colors } from "../../theme/Colors";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Stepper from "@mui/material/Stepper";
import ReportGmailerrorredOutlinedIcon from "@mui/icons-material/ReportGmailerrorredOutlined";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { Gradient } from "react-gradient";
import * as yup from "yup";
import { chipSelector } from "../../utils/chipSelector";
import { ModalStyle } from "../../theme/ModalStyles";
import { color } from "@mui/system";

function CommandeDetails() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const { id } = useParams();

  const navigate = useNavigate();

  const gradients = [["#7B3FFF", "#9E38CF"]];

  const steps = [
    {
      label: "Etape 1",
      description: `  Demande de devis.`,
    },
    {
      label: "Etape 2",
      description: `Validation de devis.`,
    },
    {
      label: "Etape 3",
      description: `Commande.`,
    },
    {
      label: "Etape 4",
      description: `Création des comptes collaborateurs.`,
    },
    {
      label: "Etape 5",
      description: `Facturation.`,
    },
    {
      label: "Etape 6",
      description: `Paiement.`,
    },
    {
      label: "Etape 7",
      description: `Production.`,
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);

  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const stepperStyle = {
    ".MuiSvgIcon-root:not(.Mui-completed)": {
      color: colors.main_purple,
    },
    ".MuiSvgIcon-root.Mui-active": {
      color: colors.main_purple,
      marginY: "-3px",
    },
  };
  const stepperStyleProgression = {
    ".css-z7uhs0-MuiStepConnector-line": {
      margin: "12px 8px",
    },
  };

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if (
        (typeof tempObj[propName] === "object" &&
          Object.keys(tempObj[propName]).length === 0) ||
        tempObj[propName] === null ||
        tempObj[propName] === ""
      ) {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

  const factureFormRef = useRef();

  //File config
  const FILE_SIZE = 5000 * 1024;
  const SUPPORTED_FORMATS_PROFILE_PIC = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
    "application/pdf",
  ];

  //upload progress
  const [progress, setProgress] = useState();

  const [order, setOrder] = useState();
  const [isCreatingEstimate, setIsCreatingEstimate] = useState(true);
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(true);
  const [devisToAddName, setDevisToAddName] = useState();
  const [invoiceToAddName, setInvoiceToAddName] = useState();

  // modal configs
  const [openPurchase, setOpenPurchase] = useState();
  const [openListCollabs, setOpenListCollabs] = useState();
  const [openAcceptPayment, setOpenAcceptPayment] = useState();
  const [openChangeOrderStatus, setOpenChangeOrderStatus] = useState();
  const [openCreateInvoice, setOpenCreateInvoice] = useState();
  const [openUpdateInvoice, setOpenUpdateInvoice] = useState();
  const [openCreateEstimate, setOpenCreateEstimate] = useState();
  const [openUpdateEstimate, setOpenUpdateEstimate] = useState();
  const [openRefuse, setOpenRefuse] = useState(false);
  const [openRefusepaiment, setOpenRefusePaiement] = useState(false);

  //possible order states
  const [possibleOrderStates, setPossibleOrderStates] = useState([
    { id: 0, title: "En production", value: "IN_PRODUCTION" },
    { id: 1, title: "En cours de livraison", value: "DELIVERY" },
    { id: 2, title: "Livrée", value: "DELIVERED" },
  ]);

  //trigger reload
  const [triggerReload, setTriggerReload] = useState(false);

  //translate
  const paymentMode = {
    ELECTRONIC_BANK_TRANSFERS: "VIREMENTS BANCAIRES",
  };

  const handleCloseListCollabs = () => {
    setOpenListCollabs(false);
  };

  const handleClosePurchase = () => {
    setOpenPurchase(false);
  };

  const handleCloseAcceptPayment = () => {
    setOpenAcceptPayment(false);
  };

  const handleCloseChangeStatus = () => {
    setOpenChangeOrderStatus(false);
  };

  const handleCloseCreateInvoice = () => {
    setOpenCreateInvoice(false);
  };

  const handleCloseUpdateInvoice = () => {
    setOpenUpdateInvoice(false);
  };

  const handleCloseCreateEstimate = () => {
    setOpenCreateEstimate(false);
  };

  const handleCloseUpdateEstimate = () => {
    setOpenUpdateEstimate(false);
  };

  //get commande by id
  useEffect(() => {
    //console.log("ID is", id);
    Api.get(Urls.GET_COMMANDE + `/${id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          setOrder(response?.data?.order);
          if (isEmpty(response?.data?.order?.estimate) === true) {
            setIsCreatingEstimate(true);
          } else {
            setIsCreatingEstimate(false);
          }
          if (isEmpty(response?.data?.order?.invoice) === true) {
            setIsCreatingInvoice(true);
          } else {
            setIsCreatingInvoice(false);
          }

          //console.log("GET ORDER DETAILS : ", response?.data);
          setActiveStep(
            response?.data?.order?.status === "INIT"
              ? 0
              : response?.data?.order?.status === "ESTIMATE_UPLOADED"
              ? 1
              : response?.data?.order?.status === "PURCHASE_ORDER_UPLOADED"
              ? 2
              : response?.data?.order?.status === "PURCHASE_ORDER_CONFIRMED" ||
                response?.data?.order?.status === "PURCHASE_ORDER_REFUSED"
              ? 2
              : response?.data?.order?.status === "LIST_COLLABORATORS_UPLOADED"
              ? 3
              : response?.data?.order?.status === "LIST_COLLABORATORS_ACCEPTED"
              ? 4
              : response?.data?.order?.status === "INVOICE_UPLOADED"
              ? 4
              : response?.data?.order?.status === "PAYMENT_MADE"
              ? 5
              : response?.data?.order?.status === "PAYMENT_ACCEPTED"
              ? 6
              : response?.data?.order?.status === "PAYMENT_REFUSED"
              ? 5
              : 6
          );
          if (response?.data?.order?.status === "IN_PRODUCTION") {
            setPossibleOrderStates([
              { id: 1, title: "En cours de livraison", value: "DELIVERY" },
              { id: 2, title: "Livrée", value: "DELIVERED" },
            ]);
          }
          if (response?.data?.order?.status === "DELIVERY") {
            setPossibleOrderStates([
              { id: 2, title: "Livrée", value: "DELIVERED" },
            ]);
          }
          if (response?.data?.order?.status === "DELIVERED") {
            setPossibleOrderStates([]);
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [triggerReload]);

  const createEstimate = (values) => {
    //console.log("creating estimate ...");
    //console.log("create estimate accesstoken", access_token);
    //console.log("create estimate values", values);
    Api.post(
      Urls.CREATE_ESTIMATE + `/${id}/estimates`,
      {
        unit_price: values.unit_price,
        amount_excluding_tax: values.amount_excluding_tax,
        amount_including_tax: values.amount_including_tax,
        estimate_file: values.estimate_file,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE CREATE ESTIMATE", response);
        if (response.status === 201) {
          //console.log(response);
          toast.success("Devis envoyé avec succès karim");
          setProgress();
          setTriggerReload(!triggerReload);
          setDevisToAddName();
          // estimateFormRef.current.values.estimate_file = null;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setProgress();
      });
  };

  const updateEstimate = (values) => {
    //console.log("updating estimate ...");
    //console.log("update estimate accesstoken", access_token);
    //console.log("update estimate values", values);

    // let realValues = nonEmptyObject(values);

    Api.post(
      Urls.UPDATE_ESTIMATES + `/${order?.estimate?.id}`,
      {
        unit_price: values.unit_price,
        amount_excluding_tax: values.amount_excluding_tax,
        amount_including_tax: values.amount_including_tax,
        estimate_file:
          typeof values?.estimate_file === "string" ||
          values?.estimate_file instanceof String
            ? null
            : values?.estimate_file,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE UPDATE ESTIMATE", response);
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Devis mis à jours avec succès");
          setProgress();
          setTriggerReload(!triggerReload);
          setDevisToAddName();
          // estimateFormRef.current.values.estimate_file = null;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setProgress();
      });
  };

  const acceptCollabs = () => {
    //console.log("ACCEPT COLLABS ...");
    //console.log("update estimate accesstoken", access_token);
    Api.patch(
      Urls.UPDATE_ORDER_STATUS + `/${id}/status`,
      {
        status: "LIST_COLLABORATORS_ACCEPTED",
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE ACCEPT COLLABS", response);
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Liste des collaborateur acceptée");
          setTriggerReload(!triggerReload);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const acceptPayment = () => {
    //console.log("ACCEPT COLLABS ...");
    //console.log("update estimate accesstoken", access_token);
    Api.patch(
      Urls.UPDATE_ORDER_STATUS + `/${id}/status`,
      {
        status: "PAYMENT_ACCEPTED",
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE ACCEPT COLLABS", response);
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Paiement accepté");
          setTriggerReload(!triggerReload);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //refuser le paiement
  const RefusePayment = (values) => {
    //console.log("update estimate accesstoken", access_token);
    Api.patch(
      Urls.UPDATE_ORDER_STATUS + `/${id}/status`,
      {
        status: "PAYMENT_REFUSED",
        reason_for_refusal: values.motif,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE ACCEPT COLLABS", response);
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Paiement refusé");
          setTriggerReload(!triggerReload);
          setOpenRefusePaiement(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  const confirmPurchase = () => {
    //console.log("confirmer Bon de commande ...");
    //console.log("update estimate accesstoken", access_token);
    Api.patch(
      Urls.UPDATE_ORDER_STATUS + `/${id}/status`,
      {
        status: "PURCHASE_ORDER_CONFIRMED",
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Bon de commande confirmé");
          setDevisToAddName(null);
          setTriggerReload(!triggerReload);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //refuser bon de commande
  const RefusePurchase = (values) => {
    //console.log("Refuser Bon de commande ...");
    //console.log("update estimate accesstoken", access_token);
    Api.patch(
      Urls.UPDATE_ORDER_STATUS + `/${id}/status`,
      {
        status: "PURCHASE_ORDER_REFUSED",
        reason_for_refusal: values.motif,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Bon de commande refusé");
          setDevisToAddName(null);
          setTriggerReload(!triggerReload);
          setOpenRefuse(false);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  const createInvoice = (values) => {
    //console.log("Creating invoice...");

    Api.post(
      Urls.CREATE_INVOICE + `/${id}/invoices`,
      {
        invoice_file: values.invoice_file,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE CREATE INVOICE", response);
        if (response.status === 201) {
          //console.log(response);
          toast.success("Facture envoyé avec succès");
          setProgress();
          setTriggerReload(!triggerReload);
          setInvoiceToAddName();
          factureFormRef.current.values.invoice_file = null;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setProgress();
      });
  };
  const updateInvoice = (values) => {
    //console.log("updating invoice...");

    Api.post(
      Urls.UPDATE_INVOICE + `/${order?.invoice?.id}`,
      {
        invoice_file: values.invoice_file,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE CREATE INVOICE", response);
        if (response.status === 200) {
          //console.log(response);
          toast.success("Facture mis à jours avec succès");
          setProgress();
          setTriggerReload(!triggerReload);
          setInvoiceToAddName();
          factureFormRef.current.values.invoice_file = null;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setProgress();
      });
  };

  const changeOrderStatus = (value) => {
    //console.log("VALUE", value.status);
    Api.patch(
      Urls.UPDATE_ORDER_STATUS + `/${id}/status`,
      {
        status: value.status,
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("RESPONSE ACCEPT COLLABS", response);
        if (response.status === 200) {
          //console.log(response);
          // //console.log("real values :", realValues);
          toast.success("Etat de la commande mis à jours");
          setTriggerReload(!triggerReload);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // //console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //delete une commande
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("0zFgtYmLv9");
  //delete commandes
  const deletecommandes = () => {
    Api.delete(Urls.DELETE_COMMANDES, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
      data: {
        order_ids: [id],
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("commande supprimée");
        setOnDeletNext(false);
        setTimeout(() => {
          navigate("/admin/gestion-des-commandes");
        }, 500);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  //hover
  const [hover, setHover] = useState(false);
  const renderSwitch = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <Typography
              sx={{
                marginBottom: "1.5rem",
                fontSize: "0.8rem",
                fontWeight: "600",
                color: `${colors.main_purple}`,
              }}
            >
              {step.description}
            </Typography>
            <Box>
              {/* NOMBRE DE CARTES */}
              <Box sx={{ marginBottom: "1rem" }}>
                <Typography mb>Nombre d’abonnements</Typography>
                <Box display="flex" flexDirection="row" gap="0.5rem">
                  <Typography variant="small">
                    Nombre d’abonnements commandées
                  </Typography>
                  <Typography variant="small_purple" fontWeight="600">
                    {order?.number_of_cards}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        );
      case 1:
        return (
          <>
            {/* DEVIS */}
            <Box sx={{ marginBottom: "1rem" }}>
              <Box display="flex" gap="0.5rem" position="relative">
                <Typography mb>Devis</Typography>
                <Box
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <ReportGmailerrorredOutlinedIcon />
                </Box>
                {hover && (
                  <Box
                    sx={{
                      background: `${colors.paperLightGray}`,
                      border: `2px solid ${colors.main_purple}`,
                      borderRadius: "15px",
                      padding: "1rem",
                      maxWidth: "300px",
                      position: "absolute",
                      top: "-85px",
                      left: "125px",
                    }}
                  >
                    <Typography variant="text_gray">
                      Veuillez s'il vous plaît utiliser le point "." à la place
                      de la virgule "," pour saisir un chiffre décimal
                    </Typography>
                  </Box>
                )}
              </Box>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  unit_price: order?.estimate?.unit_price ?? "",
                  amount_excluding_tax:
                    order?.estimate?.amount_excluding_tax ?? "",
                  amount_including_tax:
                    order?.estimate?.amount_including_tax ?? "",
                  estimate_file: order?.estimate?.url_estimate_file ?? "",
                }}
                validationSchema={yup.object({
                  estimate_file: yup
                    .mixed()
                    .required("Veuillez télécharger un fichier")
                    .test(
                      "fileFormat",
                      "Format du fichier invalide",
                      (value) =>
                        value &&
                        SUPPORTED_FORMATS_PROFILE_PIC.includes(value.type)
                    )
                    .test(
                      "fileSize",
                      "Fichier volumineux",
                      (value) => value && value.size <= FILE_SIZE
                    ),
                  unit_price: yup
                    .number()
                    .typeError("Ce champs doit être numérique")
                    .min(0, "Le nombre doit être positive")
                    .required("Ce champs est obligatoire"),
                  amount_including_tax: yup
                    .number()
                    .typeError("Ce champs doit être numérique")
                    .min(0, "Le nombre doit être positive")
                    .required("Ce champs est obligatoire"),
                  amount_excluding_tax: yup
                    .number()
                    .typeError("Ce champs doit être numérique")
                    .min(0, "Le nombre doit être positive")
                    .required("Ce champs est obligatoire"),
                })}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    {/* devis fields */}
                    {order?.status === "INIT" ||
                    order?.status === "ESTIMATE_UPLOADED" ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        gap="0.5rem"
                        maxWidth={350}
                        mb
                      >
                        <Box>
                          <Field
                            name="unit_price"
                            as={TextField}
                            onChange={(e) => {
                              setFieldValue("unit_price", e.target.value);
                              setFieldValue(
                                "amount_excluding_tax",
                                e.target.value * order?.number_of_cards
                              );
                              const montant =
                                e.target.value * order?.number_of_cards;
                              const montant_tax = (montant * 19) / 100;
                              setFieldValue(
                                "amount_including_tax",
                                montant + montant_tax
                              );
                            }}
                            label="Prix U HT"
                            size="small"
                            type="input"
                          />
                          <MzErrorText>{errors.unit_price}</MzErrorText>
                        </Box>
                        <Box>
                          <Field
                            name="amount_excluding_tax"
                            as={TextField}
                            label="Montant HT"
                            size="small"
                            type="input"
                          />
                          <MzErrorText>
                            {errors.amount_excluding_tax}
                          </MzErrorText>
                        </Box>
                        <Box>
                          <Field
                            name="amount_including_tax"
                            as={TextField}
                            label="Montant TTC"
                            size="small"
                            type="input"
                          />
                          <MzErrorText>
                            {errors.amount_including_tax}
                          </MzErrorText>
                        </Box>
                        {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                      </Box>
                    ) : (
                      <Box display="flex" flexDirection="column" gap="0.5rem">
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Typography variant="small">Prix U HT</Typography>
                          <Typography variant="small_purple" fontWeight="600">
                            {order?.estimate?.unit_price}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Typography variant="small">Montant HT</Typography>
                          <Typography variant="small_purple" fontWeight="600">
                            {order?.estimate?.amount_excluding_tax}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Typography variant="small">Montant TTC</Typography>
                          <Typography variant="small_purple" fontWeight="600">
                            {order?.estimate?.amount_including_tax}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="small_grey_underline">
                            <a
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                textDecoration: "none",
                                color: `${colors.gray}`,
                              }}
                              href={order?.estimate?.url_estimate_file ?? ""}
                            >
                              Fichier devis
                            </a>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    {/* upload devis */}
                    {(order?.status === "INIT" ||
                      order?.status === "ESTIMATE_UPLOADED") && (
                      <Box
                        sx={{
                          marginBottom: "1rem",
                          flexDirection: "column",
                          display: "flex",
                          gap: "0.5rem",
                        }}
                      >
                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Button variant="primary">
                            <input
                              style={{
                                cursor: "pointer",
                                opacity: "0%",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                              }}
                              id="estimate_file"
                              name="estimate_file"
                              type="file"
                              onChange={(event) => {
                                const doc = event.target.files[0];
                                setFieldValue(
                                  "estimate_file",
                                  event.target.files[0]
                                );
                                setDevisToAddName(doc.name);
                              }}
                            ></input>
                            Déposer devis
                          </Button>
                          <Button
                            disabled={
                              isEmpty(errors) &&
                              typeof values?.estimate_file !== "string"
                                ? false
                                : true
                            }
                            variant="primary"
                            onClick={() => {
                              isCreatingEstimate
                                ? setOpenCreateEstimate(true)
                                : setOpenUpdateEstimate(true);
                            }}
                          >
                            Confirmer
                          </Button>
                          {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                          {/* <pre>{JSON.stringify(typeof values?.estimate_file, null, 2)}</pre> */}
                          {progress && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "0.5rem",
                              }}
                            >
                              <Box width="2.2rem">
                                <Typography color={colors.main_purple}>
                                  {progress ? progress + "%" : null}
                                </Typography>
                              </Box>
                              <Box>
                                <CircularProgress
                                  size={25}
                                  variant="determinate"
                                  value={progress}
                                  sx={{ color: `${colors.main_purple}` }}
                                />
                              </Box>
                            </Box>
                          )}
                          <Modal
                            open={openRefuse}
                            onClose={() => setOpenRefuse(false)}
                          >
                            <Box sx={ModalStyle}>
                              <Typography marginBottom="1rem">
                                Refuser le bon de commande
                              </Typography>
                              <Box>
                                <Formik
                                  initialValues={{
                                    motif: "",
                                  }}
                                  validationSchema={yup.object({
                                    motif: yup
                                      .string()
                                      .required("Ce champ est obligatoire"),
                                  })}
                                  onSubmit={(values) => {
                                    console.log(values);
                                  }}
                                >
                                  {({ values, setFieldValue, errors }) => (
                                    <Form>
                                      <Field
                                        name="motif"
                                        type="input"
                                        placeholder="Motif de refus :"
                                        id="description"
                                        value={values.motif}
                                        as={TextField}
                                        autoComplete="off"
                                        multiline
                                        fullWidth
                                        columns={3}
                                        rows={4}
                                      />
                                      <Box
                                        marginTop="1rem"
                                        display="flex"
                                        justifyContent="center"
                                        gap="1rem"
                                      >
                                        <Button type="submit" variant="primary">
                                          Refuser
                                        </Button>
                                        <Button
                                          variant="third"
                                          onClick={() => setOpenRefuse(false)}
                                        >
                                          Annuler
                                        </Button>
                                      </Box>
                                    </Form>
                                  )}
                                </Formik>
                              </Box>
                            </Box>
                          </Modal>
                        </Box>
                        <ConfirmationModal
                          title="Créer un devis"
                          message="Êtes-vous sûr de bien vouloir créer le devis"
                          acceptLabel="oui"
                          refuseLabel="non"
                          open={openCreateEstimate}
                          onClose={handleCloseCreateEstimate}
                          acceptMethod={createEstimate}
                          acceptMethodParams={values}
                        />
                        <ConfirmationModal
                          title="Mettre à jour le devis"
                          message="Êtes-vous sûr de bien vouloir mettre à jour le devis"
                          acceptLabel="oui"
                          refuseLabel="non"
                          open={openUpdateEstimate}
                          onClose={handleCloseUpdateEstimate}
                          acceptMethod={updateEstimate}
                          acceptMethodParams={values}
                        />

                        <Box display="flex" flexDirection="row" gap="0.5rem">
                          <Typography variant="small_grey_underline">
                            {devisToAddName
                              ? devisToAddName
                              : values?.estimate_file && (
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      color: `${colors.gray}`,
                                    }}
                                    href={values?.estimate_file ?? ""}
                                  >
                                    Fichier devis
                                  </a>
                                )}
                          </Typography>
                        </Box>

                        {touched.estimate_file && (
                          <MzErrorText>{errors.estimate_file}</MzErrorText>
                        )}
                      </Box>
                    )}
                    {/* <pre>{JSON.stringify(isCreatingEstimate, null, 2)}</pre>
                      <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </Form>
                )}
              </Formik>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography mb>Bon de commande</Typography>
              {order?.url_purchase_order_file && (
                <>
                  <Box display="flex" flexDirection="column">
                    <Typography variant="small_grey_underline">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: `${colors.gray}`,
                        }}
                        href={order?.estimate?.url_estimate_file_signed}
                      >
                        Devis et CGV signés
                      </a>
                    </Typography>
                    <Typography variant="small_grey_underline">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: `${colors.gray}`,
                        }}
                        href={order?.url_purchase_order_file}
                      >
                        Bon de commande
                      </a>
                    </Typography>
                  </Box>
                  {(order?.status === "INIT" ||
                    order?.status === "ESTIMATE_UPLOADED" ||
                    order?.status === "PURCHASE_ORDER_UPLOADED") && (
                    <Box marginTop="1rem" display="flex" gap="1rem">
                      <Button
                        variant="primary"
                        onClick={() => setOpenPurchase(true)}
                      >
                        Accepter bon de commande
                      </Button>
                      <Button
                        variant="third"
                        onClick={() => setOpenRefuse(true)}
                      >
                        Refuser bon de commande
                      </Button>
                    </Box>
                  )}
                  {order?.status === "PURCHASE_ORDER_REFUSED" && (
                    <Box
                      maxWidth="300px"
                      sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
                    >
                      <Typography sx={{ color: "red", fontWeight: "500" }}>
                        Motif de refus :
                      </Typography>
                      <Typography>{order?.reason_for_refusal}</Typography>{" "}
                    </Box>
                  )}
                </>
              )}
            </Box>
            <Modal open={openRefuse} onClose={() => setOpenRefuse(false)}>
              <Box sx={ModalStyle}>
                <Typography marginBottom="1rem">
                  Refuser le bon de commande
                </Typography>
                <Box>
                  <Formik
                    initialValues={{
                      motif: "",
                    }}
                    validationSchema={yup.object({
                      motif: yup.string().required("Ce champ est obligatoire"),
                    })}
                    onSubmit={(values) => {
                      RefusePurchase(values);
                    }}
                  >
                    {({ values, setFieldValue, errors }) => (
                      <Form>
                        <Field
                          name="motif"
                          type="input"
                          placeholder="Motif de refus :"
                          id="description"
                          value={values.motif}
                          as={TextField}
                          autoComplete="off"
                          multiline
                          fullWidth
                          columns={3}
                          rows={4}
                        />
                        <Box
                          marginTop="1rem"
                          display="flex"
                          justifyContent="center"
                          gap="1rem"
                        >
                          <Button type="submit" variant="primary">
                            Refuser
                          </Button>
                          <Button
                            variant="third"
                            onClick={() => setOpenRefuse(false)}
                          >
                            Annuler
                          </Button>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Modal>
            <ConfirmationModal
              title="Confirmer bon de commande"
              message="Êtes-vous sûr de bien vouloir confirmer le bon de commande"
              acceptLabel="oui"
              refuseLabel="non"
              open={openPurchase}
              onClose={handleClosePurchase}
              acceptMethod={confirmPurchase}
            />
          </>
        );
      case 3:
        return (
          <>
            <Typography
              sx={{
                marginBottom: "1.5rem",
                fontSize: "0.8rem",
                fontWeight: "600",
                color: `${colors.main_purple}`,
              }}
            >
              {step.description}
            </Typography>

            {/* LIS DES COLLABS */}
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography mb>Liste des collaborateurs</Typography>
              <Box display="flex" flexDirection="column" gap="0.5rem">
                {order?.url_ordered_collaborators_file && (
                  <>
                    <Typography variant="small_grey_underline" mb>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                          color: `${colors.gray}`,
                        }}
                        href={order?.url_ordered_collaborators_file ?? ""}
                      >
                        Liste des collaborateurs
                      </a>
                    </Typography>
                    {order?.status === "LIST_COLLABORATORS_UPLOADED" && (
                      <Box>
                        <Button
                          variant="primary"
                          onClick={() => setOpenListCollabs(true)}
                        >
                          Accepter
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
            <ConfirmationModal
              title="Liste des collaborateurs"
              message="Êtes-vous sûr de bien vouloir accepter la liste des collaborateur"
              acceptLabel="oui"
              refuseLabel="non"
              open={openListCollabs}
              onClose={handleCloseListCollabs}
              acceptMethod={acceptCollabs}
            />
          </>
        );
      case 4:
        return (
          <>
            <Typography
              sx={{
                marginBottom: "1.5rem",
                fontSize: "0.8rem",
                fontWeight: "600",
                color: `${colors.main_purple}`,
              }}
            >
              {step.description}
            </Typography>

            <Box sx={{ marginBottom: "1rem" }}>
              <Typography mb>Facture</Typography>
              <Formik
                innerRef={factureFormRef}
                enableReinitialize={true}
                initialValues={{
                  invoice_file: order?.invoice?.url_invoice_file ?? "",
                }}
                validationSchema={yup.object({
                  invoice_file: yup
                    .mixed()
                    .required("Veuillez télécharger un fichier")
                    .test(
                      "fileFormat",
                      "Format du fichier invalide",
                      (value) =>
                        value &&
                        SUPPORTED_FORMATS_PROFILE_PIC.includes(value.type)
                    )
                    .test(
                      "fileSize",
                      "Fichier volumineux",
                      (value) => value && value.size <= FILE_SIZE
                    ),
                })}
              >
                {({ values, setFieldValue, errors }) => (
                  <Form>
                    <Box
                      sx={{
                        marginBottom: "1rem",
                        flexDirection: "column",
                        display: "flex",
                        gap: "0.5rem",
                      }}
                    >
                      {order?.status === "LIST_COLLABORATORS_ACCEPTED" ||
                      order?.status === "INVOICE_UPLOADED" ? (
                        <Box display="flex" flexDirection="column">
                          <Box display="flex" flexDirection="row" gap="0.5rem">
                            <Button variant="primary">
                              <input
                                style={{
                                  cursor: "pointer",
                                  opacity: "0%",
                                  width: "100%",
                                  height: "100%",
                                  position: "absolute",
                                }}
                                id="invoice_file"
                                name="invoice_file"
                                type="file"
                                onChange={(event) => {
                                  const doc = event.target.files[0];
                                  setFieldValue(
                                    "invoice_file",
                                    event.target.files[0]
                                  );
                                  setInvoiceToAddName(doc.name);
                                }}
                              ></input>
                              Déposer Facture
                            </Button>
                            <Button
                              disabled={
                                isEmpty(errors) &&
                                typeof values?.invoice_file !== "string"
                                  ? false
                                  : true
                              }
                              variant="primary"
                              onClick={() => {
                                isCreatingInvoice
                                  ? setOpenCreateInvoice(true)
                                  : setOpenUpdateInvoice(true);
                              }}
                            >
                              Confirmer
                            </Button>
                            {/* <pre>{JSON.stringify(typeof values.invoice_file, null, 2)}</pre> */}

                            <ConfirmationModal
                              title="Créer une facture"
                              message="Êtes-vous sûr de bien vouloir créer la facture"
                              acceptLabel="oui"
                              refuseLabel="non"
                              open={openCreateInvoice}
                              onClose={handleCloseCreateInvoice}
                              acceptMethod={createInvoice}
                              acceptMethodParams={values}
                            />
                            <ConfirmationModal
                              title="Mettre à jour une facture"
                              message="Êtes-vous sûr de bien vouloir mettre à jour la facture"
                              acceptLabel="oui"
                              refuseLabel="non"
                              open={openUpdateInvoice}
                              onClose={handleCloseUpdateInvoice}
                              acceptMethod={updateInvoice}
                              acceptMethodParams={values}
                            />
                          </Box>
                          {progress && (
                            <Box
                              margin="1rem 0rem"
                              gap="0.5rem"
                              sx={{ display: "flex", flexDirection: "row" }}
                            >
                              <Typography color={colors.main_purple}>
                                {progress ? progress + "%" : null}
                              </Typography>
                              <CircularProgress
                                size={25}
                                variant="determinate"
                                value={progress}
                                sx={{ color: `${colors.main_purple}` }}
                              />
                            </Box>
                          )}
                        </Box>
                      ) : (
                        ""
                      )}

                      {(order?.status === "LIST_COLLABORATORS_ACCEPTED" ||
                        order?.status === "INVOICE_UPLOADED" ||
                        order?.status === "PAYMENT_MADE" ||
                        order?.status === "PAYMENT_ACCEPTED" ||
                        order?.status === "IN_PRODUCTION" ||
                        order?.status === "DELIVERY" ||
                        order?.status === "DELIVERED") && (
                        <Box display="flex" flexDirection="column" gap="0.5rem">
                          <Box display="flex" flexDirection="row" gap="0.5rem">
                            <Typography variant="small_grey_underline">
                              {invoiceToAddName ? (
                                invoiceToAddName
                              ) : order?.invoice?.url_invoice_file ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: `${colors.gray}`,
                                  }}
                                  href={values?.invoice_file ?? ""}
                                >
                                  Facture
                                </a>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                          <MzErrorText>{errors.invoice_file}</MzErrorText>
                        </Box>
                      )}
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                      <pre>{JSON.stringify(order, null, 2)}</pre> */}
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </>
        );
      case 5:
        return (
          <>
            <Box marginBottom="1rem">
              <Typography mb>Paiement</Typography>
              {(order?.status === "PAYMENT_MADE" ||
                order?.status === "PAYMENT_ACCEPTED" ||
                order?.status === "IN_PRODUCTION" ||
                order?.status === "DELIVERY" ||
                order?.status === "DELIVERED" ||
                order?.status === "PAYMENT_REFUSED") && (
                <Box maxWidth={650}>
                  <Gradient
                    style={{ padding: "1rem", borderRadius: "2rem" }}
                    gradients={gradients} // required
                    property="background"
                    duration={3000}
                    angle="45deg"
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                        padding: "0.5rem",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        <Typography color="white" fontWeight={300}>
                          Date de paiement
                        </Typography>
                        <Typography color="white" sx={{ fontWeight: "500" }}>
                          {order?.invoice?.payment?.payment_date?.split(
                            "-"
                          )[2] +
                            "/" +
                            order?.invoice?.payment?.payment_date?.split(
                              "-"
                            )[1] +
                            "/" +
                            order?.invoice?.payment?.payment_date?.split(
                              "-"
                            )[0]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        <Typography color="white" fontWeight={300}>
                          Mode de paiement
                        </Typography>
                        <Typography color="white" sx={{ fontWeight: "500" }}>
                          {paymentMode[order?.invoice?.payment?.payment_mode]}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        <Typography color="white" fontWeight={300}>
                          Montant
                        </Typography>
                        <Typography color="white" sx={{ fontWeight: "500" }}>
                          {order?.invoice?.payment?.amount}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                        }}
                      >
                        <Typography color="white" fontWeight={300}>
                          Document de paiement
                        </Typography>
                        <Typography
                          color="white"
                          sx={{
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                        >
                          <a
                            target="_blank"
                            href={order?.invoice?.payment?.url_payment_file}
                            style={{ color: "white" }}
                          >
                            Document de paiement
                          </a>
                        </Typography>
                      </Box>
                    </Box>
                  </Gradient>
                  {order?.status === "PAYMENT_REFUSED" && (
                    <Box
                      marginTop="1rem"
                      maxWidth="400px"
                      sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
                    >
                      <Typography sx={{ color: "red", fontWeight: "500" }}>
                        Motif de refus :
                      </Typography>
                      <Typography>{order?.reason_for_refusal}</Typography>
                    </Box>
                  )}
                  {order?.status === "PAYMENT_MADE" && (
                    <Box
                      sx={{
                        marginTop: "1rem",
                        display: "flex",
                        gap: "1rem",
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Button
                        variant="third"
                        onClick={() => setOpenRefusePaiement(true)}
                      >
                        Refuser
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => setOpenAcceptPayment(true)}
                      >
                        Accepter
                      </Button>
                    </Box>
                  )}
                  <Modal
                    open={openRefusepaiment}
                    onClose={() => setOpenRefusePaiement(false)}
                  >
                    <Box sx={ModalStyle}>
                      <Typography marginBottom="1rem">
                        Refuser le paiement
                      </Typography>
                      <Box>
                        <Formik
                          initialValues={{
                            motif: "",
                          }}
                          validationSchema={yup.object({
                            motif: yup
                              .string()
                              .required("Ce champ est obligatoire"),
                          })}
                          onSubmit={(values) => {
                            RefusePayment(values);
                          }}
                        >
                          {({ values, setFieldValue, errors }) => (
                            <Form>
                              <Field
                                name="motif"
                                type="input"
                                placeholder="Motif de refus :"
                                id="description"
                                value={values.motif}
                                as={TextField}
                                autoComplete="off"
                                multiline
                                fullWidth
                                columns={3}
                                rows={4}
                              />
                              <Box
                                marginTop="1rem"
                                display="flex"
                                justifyContent="center"
                                gap="1rem"
                              >
                                <Button type="submit" variant="primary">
                                  Refuser
                                </Button>
                                <Button
                                  variant="third"
                                  onClick={() => setOpenRefusePaiement(false)}
                                >
                                  Annuler
                                </Button>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </Box>
                    </Box>
                  </Modal>
                  <ConfirmationModal
                    title="Accepter le paiement"
                    message="Êtes-vous sûr de bien vouloir accepter le paiement ?"
                    acceptLabel="oui"
                    refuseLabel="non"
                    open={openAcceptPayment}
                    onClose={handleCloseAcceptPayment}
                    acceptMethod={acceptPayment}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 6:
        return (
          <>
            {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
            <Box marginBottom="1rem">
              <Typography marginBottom={2}>État de la commande</Typography>
              {order?.status !== "DELIVERED" ? (
                <Formik initialValues={{ status: order?.status }}>
                  {({ values, setFieldValue }) => (
                    <Form>
                      <Box
                        sx={{
                          marginBottom: "2rem",
                          display: "flex",
                          flexDirection: "row",
                          gap: "0.5rem",
                        }}
                      >
                        <Box
                          className="field"
                          sx={{
                            width: "250px",
                            marginBottom: "1rem",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Field
                            disabled={
                              order?.status !== "PAYMENT_ACCEPTED" &&
                              order?.status !== "IN_PRODUCTION" &&
                              order?.status !== "DELIVERY" &&
                              order?.status !== "DELIVERED"
                            }
                            fullWidth
                            name="status"
                            component={Autocomplete}
                            options={possibleOrderStates}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            getOptionLabel={(s) => s.title}
                            defaultValue={possibleOrderStates.find(
                              (s) => s.value === order?.status
                            )}
                            getOptionDisabled={(option) =>
                              option.id <
                              possibleOrderStates?.find(
                                (s) => s.value === order?.status
                              )?.id
                            }
                            onChange={(event, value) => {
                              setFieldValue("status", value?.value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name="autocomplete"
                                label="Etat de la commande"
                              />
                            )}
                            size="small"
                          />
                        </Box>
                        <Box>
                          <Button
                            onClick={() => setOpenChangeOrderStatus(true)}
                            disabled={
                              order?.status !== "PAYMENT_ACCEPTED" &&
                              order?.status !== "IN_PRODUCTION" &&
                              order?.status !== "DELIVERY" &&
                              order?.status !== "DELIVERED"
                            }
                            variant="primary"
                            type="submit"
                          >
                            Confirmer
                          </Button>
                        </Box>
                      </Box>
                      <ConfirmationModal
                        title="Changer l'état de la commande"
                        message={`Êtes-vous sûr de bien vouloir changer l'état de la commande à "${
                          possibleOrderStates?.find(
                            (order) => order.value === values?.status
                          )?.title
                        }"?`}
                        acceptLabel="oui"
                        refuseLabel="non"
                        open={openChangeOrderStatus}
                        onClose={handleCloseChangeStatus}
                        acceptMethod={changeOrderStatus}
                        acceptMethodParams={values}
                      />
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </Form>
                  )}
                </Formik>
              ) : (
                <Box>
                  <Typography>Livrée</Typography>
                </Box>
              )}
            </Box>
          </>
        );
    }
  };

  return (
    <Box>
      {/* Header Section */}
      <Box>
        <Typography sx={{ marginBottom: "1.5rem", fontWeight: "500" }}>
          Détail de la commande
        </Typography>
        <Box marginBottom="1rem">
          <Button onClick={() => setOnDelete(true)} variant="third">
            Supprimer cette commande
          </Button>
        </Box>
        <ConfirmationModal
          title="Supprimer la commande"
          message="Êtes-vous sûr de bien vouloir supprimer cette commande(s) ?"
          acceptLabel="oui"
          refuseLabel="non"
          open={ondelite}
          onClose={() => setOnDelete(false)}
          acceptMethod={() => setOnDeletNext(true)}
        />
        <Modal open={ondeliteNext} onClose={() => setOnDeletNext(false)}>
          <Box sx={ModalStyle}>
            <Typography fontSize="1.5rem" fontWeight="600" mb>
              Supprimer la commande
            </Typography>
            <Typography mb="1rem">Entrez votre mot de passe</Typography>
            <Box marginBottom="1rem">
              <TextField
                type="password"
                value={motdepasse}
                onChange={(e) => setMotDePasse(e.target.value)}
                name="motdepasse"
                placeholder="Mot de passe"
              />
            </Box>
            <Box display="flex" flexDirection="row" gap="0.5rem">
              <Button
                variant="primary"
                onClick={() => {
                  deletecommandes();
                }}
              >
                Supprimer
              </Button>
              <Button onClick={() => setOnDeletNext(false)} variant="third">
                Annuler
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box display="flex" flexDirection="row" gap="0.5rem">
          <Typography
            sx={{
              fontSize: "0.875rem",
              marginBottom: "1.5rem",
              fontWeight: "500",
            }}
          >
            Numéro de la commande{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.875rem",
              color: `${colors.main_purple}`,
              marginBottom: "1.5rem",
              fontWeight: "500",
            }}
          >
            #{id}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" gap="0.5rem">
          <Typography
            sx={{
              fontSize: "0.875rem",
              marginBottom: "1.5rem",
              fontWeight: "500",
            }}
          >
            Etat de la commande
          </Typography>
          <Box>{chipSelector(order?.status)}</Box>
        </Box>
        {/* FORMULAIRE  */}
      </Box>

      {/* stepper */}
      <Box sx={{ maxWidth: "800px" }}>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical" sx={stepperStyle}>
          {steps.map((step, index) => (
            <Step key={step.label} completed={completed[index]}>
              <StepButton disableRipple color="inherit" onClick={handleStep(index)}>
                <StepLabel sx={{ padding: "-3px" }}>
                  <Box display="flex" flexDirection="column">
                    <Typography>{step.label}</Typography>
                  </Box>
                  <Typography color={colors.main_purple} fontSize="0.7rem" fontWeight="bold">
                    {step.description}
                  </Typography>
                </StepLabel>
              </StepButton>
              <StepContent>{renderSwitch(index)}</StepContent>
            </Step>
          ))}
        </Stepper>
        <>
          {/* <pre>{JSON.stringify(order, null, 2)}</pre> */}
          <Box marginBottom="1rem">
            <Typography marginBottom={2}>État de la commande</Typography>
            {order?.status !== "DELIVERED" ? (
              <Formik initialValues={{ status: order?.status }}>
                {({ values, setFieldValue }) => (
                  <Form>
                    <Box
                      sx={{
                        marginBottom: "2rem",
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                      }}
                    >
                      <Box
                        className="field"
                        sx={{
                          width: "250px",
                          marginBottom: "1rem",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Field
                          disabled={
                            order?.status !== "PAYMENT_ACCEPTED" &&
                            order?.status !== "IN_PRODUCTION" &&
                            order?.status !== "DELIVERY" &&
                            order?.status !== "DELIVERED"
                          }
                          fullWidth
                          name="status"
                          component={Autocomplete}
                          options={possibleOrderStates}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          getOptionLabel={(s) => s.title}
                          defaultValue={possibleOrderStates.find(
                            (s) => s.value === order?.status
                          )}
                          getOptionDisabled={(option) =>
                            option.id <
                            possibleOrderStates?.find(
                              (s) => s.value === order?.status
                            )?.id
                          }
                          onChange={(event, value) => {
                            setFieldValue("status", value?.value);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="autocomplete"
                              label="Etat de la commande"
                            />
                          )}
                          size="small"
                        />
                      </Box>
                      <Box>
                        <Button
                          onClick={() => setOpenChangeOrderStatus(true)}
                          disabled={
                            order?.status !== "PAYMENT_ACCEPTED" &&
                            order?.status !== "IN_PRODUCTION" &&
                            order?.status !== "DELIVERY" &&
                            order?.status !== "DELIVERED"
                          }
                          variant="primary"
                          type="submit"
                        >
                          Confirmer
                        </Button>
                      </Box>
                    </Box>
                    <ConfirmationModal
                      title="Changer l'état de la commande"
                      message={`Êtes-vous sûr de bien vouloir changer l'état de la commande à "${
                        possibleOrderStates?.find(
                          (order) => order.value === values?.status
                        )?.title
                      }"?`}
                      acceptLabel="oui"
                      refuseLabel="non"
                      open={openChangeOrderStatus}
                      onClose={handleCloseChangeStatus}
                      acceptMethod={changeOrderStatus}
                      acceptMethodParams={values}
                    />
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </Form>
                )}
              </Formik>
            ) : (
              <Box>
                <Typography>Livrée</Typography>
              </Box>
            )}
          </Box>
        </>
      </Box>
      <ToastContainer
        style={{ top: "5rem" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Box>
  );
}

export default CommandeDetails;
