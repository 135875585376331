export const Urls = {
  //auth
  LOGIN_URL: "/auth/login",

  RESET_PASSWORD: '/user/reset-password-first-login',

  //get users
  USERS: "/admin/users",

  //get role
  USERS_ROLE :"/users/role",
  //logout
  LOGOUT: "/auth/logout",

  //create entreprise account
  CREATE_CLIENT_ADMIN: "/admin/clientAdmins",

  //get datagrid entreprise list
  GET_ENTREPRISE_TABLE: "/admin/enterprises",

  //get detail entreprise + user infos
  GET_ENTREPRISE_DETAILS: "/enterprises/",

  //update user infos in entreprise details
  UPDATE_USER_INFOS_ENTREPRISE: "/users/",

  //update user infos in entreprise details
  UPDATE_ENTREPRISE_INFOS_ENTREPRISE: "/enterprises/",

  //update entreprise logo in entreprise details
  UPDATE_ENTREPRISE_LOGO: "/enterprises/",

  //update entreprise logo in entreprise details
  UPDATE_PROFILE_PICTURE: "/users",

  //generer les collaborateurs (comptes)
  GENERATE_FUTURE_COLLABS: "/enterprises/",

  //get liste des collaborateurs (tableau)
  GET_COLLABS: "/users",

  //update card status
  UPDATE_CARD_STATUS: "/update-status-card-users",

  //get user infos (detail)
  GET_USER_ACCOUNT_DETAILS: "/users",

  //update user infos (detail)
  UPDATE_USER_INFOS_COLLAB: "/users/",

  //get user links (detail)
  GET_USER_LINKS: "/users",

  //get user documents
  GET_USER_DOCUMENTS: "/users",

  //get possible links list
  GET_POSSIBLE_LINKS: "/links",

  //create user link
  CREATE_USER_LINK: "/users",

  //delete user link
  DELETE_USER_LINK: "/userLinks",

  //update user link
  UPDATE_USER_LINK: "/userLinks",

  //delete user doc
  DELETE_USER_DOCUMENT: "/documents",

  //add user doc
  ADD_USER_DOCUMENT: "/documents",

  //add user doc
  GET_TICKETS_TABLE: "/tickets",

  //get ticket details
  GET_TICKET_DETAILS: "/tickets",

  //get messages for specific ticket
  GET_TICKET_MESSAGES: "/tickets",

  //update  ticket status
  UPDATE_TICKET_STATUS: "/tickets",

  //Create message
  CREATE_MESSAGE: "/messages",

  //Update expiration date
  UPDATE_EXPIRATION_DATE: "/expiration_date_users",

  //get commande list
  GET_COMMANDE_LIST: "/orders",

  //get order
  GET_COMMANDE: "/orders",

  //create estimates
  CREATE_ESTIMATE: "/orders",

  //update estimates
  UPDATE_ESTIMATES: "/estimates",

  //update order status (accept collabs)
  UPDATE_ORDER_STATUS: "/orders",

  //create invoice (upload facture)
  CREATE_INVOICE: "/orders",

  //update unvoice (upload facture)
  UPDATE_INVOICE: "/invoices",

  // delete profil pic
  DELETE_PROFILE_PIC: "/users/",

  //update solde
  UPDATE_SOLDE: "/update-balance-users",

  //delete users
  DELETE_USER: "/users",

  //delete entreprise
  DELETE_ENTREPRISE: "/enterprises",

  //delete tickets
  DELETE_TICKETS: "/tickets",

  //delete commande
  DELETE_COMMANDES: "/orders",

  //password reset
  PASSWORD_RESET: "/auth/reset-password",

  //create order
  CREATE_ORDER: "/enterprises/",

  //create collaborators
  UPLOAD_LIST_COLLABORATORS_ORDER: "/orders/",

  // get plans
  GET_PLANS_WITH_FEATURES: "/admin/plans",

  // get features
  GET_FEATURES: "/admin/features",

  // post new order
  CREATE_NEW_ORDER: "/admin/create-order",

  // get contacts
  GET_CONTACTS: "/get-contacts",
  

  GET_CONTACTS_BDISPLAY:'/bdisplay-contacts'
};
