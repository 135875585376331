import React from "react";
import { useState, useEffect, useRef } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
function AjouterUneCommande() {
  const { user } = useAuthContext();
  const navigate = useNavigate()
  const access_token = user.access_token;
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [devisFile, setDevisFile] = useState(null);
  const [devisSigned, setDevisSigned] = useState(null);
  const [formData, setFormData] = useState({
    company_id: "",
    number_of_cards: 0,
  });
  const [devis, setDevis] = useState(0);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };
  const handleDevisChange = (event) => {
    setDevis(event.target.value);
    console.log(parseInt(event.target.value) + 15000);
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleDevisFileChange = (event) => {
    setDevisFile(event.target.files[0]);
  };
  const handleDevisSignedFileChange = (event) => {
    setDevisSigned(event.target.files[0]);
  };
  const handleClear = () => {
    setSelectedFile(null);
    if (document.getElementById("fileInput")) {
      document.getElementById("fileInput").value = "";
    }
  };
  const handleCreateOrder = (e) => {
    e.preventDefault();
    setLoading(true);
    let object = {
      enterprise_id: formData.company_id,
      number_of_cards: formData.number_of_cards,
      collabs: selectedFile,
      unit_price: devis,
      amount_excluding_tax: devis * formData.number_of_cards,
      amount_including_tax:
        devis * formData.number_of_cards +
        (devis * formData.number_of_cards * 19) / 100,
      estimate_file: devisFile,
    };
    // devisSigned ?? (object["estimate_file_signed"] = devisSigned);
    Api.post(
      Urls.CREATE_NEW_ORDER,
      {
        enterprise_id: formData.company_id,
        number_of_cards: formData.number_of_cards,
        collabs: selectedFile,
        unit_price: devis,
        amount_excluding_tax: devis * formData.number_of_cards,
        amount_including_tax:
          devis * formData.number_of_cards +
          (devis * formData.number_of_cards * 19) / 100,
        estimate_file: devisFile,
        estimate_file_signed: devisSigned,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        setLoading(false);
        setDevis(0);
        setFormData({
          enterprise_id: "",
          number_of_cards: 0,
          collabs: null,
        });
        toast.success("Commande cree avec success");
        setTimeout(() => {
          navigate("/admin/gestion-des-commandes");
        }, 1500);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Une erreur est survenue");
      });
  };

  useEffect(() => {
    // setIsLoading(true);
    Api.get(Urls.GET_ENTREPRISE_TABLE, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setCompanies(response.data.enterprises);
        // setIsLoading(false);
      })
      .catch((error) => {});
  }, []);
  return (
    <>
      <form onSubmit={handleCreateOrder} className=" w-96 space-y-4">
        <div className="form-row">
          <select
            className="default-input"
            value={formData.company_id}
            onChange={handleChange}
            id="company_id"
            name="company_id"
            required
          >
            <option value="" disabled>
              selectionner enterprise
            </option>
            {companies.map((company, index) => (
              <option value={company.id} key={index}>
                {company.commercial_name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-row">
          <label htmlFor="name">Nombre de carte:</label>
          <input
            type="number"
            id="name"
            name="number_of_cards"
            value={formData.number_of_cards}
            onChange={handleChange}
            className="default-input"
            required
          />
        </div>
        <div className="form-row">
          <label htmlFor="devis">Devis prix U HT:</label>
          <input
            id="fileInput"
            type="number"
            min={0}
            onChange={handleDevisChange}
            name="devis"
            className="default-input w-full"
            required
          />
          <div className="flex gap-6 mt-2">
            <p>{devis * formData.number_of_cards} HT</p>
            <p>
              {devis * formData.number_of_cards +
                (devis * formData.number_of_cards * 19) / 100}{" "}
              TTC
            </p>
          </div>
        </div>

        <div className="form-row">
          <label htmlFor="devisFile">les fichier de devis:</label>
          fichier non signer
          <div className="relative">
            <input
              id="fileInputDevis"
              type="file"
              onChange={handleDevisFileChange}
              className="default-input w-full"
              required
            />
          </div>
          fichier signer
          <div className="relative">
            <input
              id="fileInputDevisSigned"
              type="file"
              onChange={handleDevisSignedFileChange}
              className="default-input w-full"
            />
          </div>
        </div>

        <div className="form-row ">
          <label htmlFor="collabs">La list des collaborateurs en csv:</label>
          <div className="relative">
            <input
              id="fileInput"
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="default-input w-full"
              required
            />
            {selectedFile && (
              <button
                type="button"
                onClick={handleClear}
                className="absolute top-0 right-0 -m-2.5 bg-white hover:scale-110 duration-200 transition"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  {" "}
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />{" "}
                </svg>
              </button>
            )}
          </div>
        </div>
        <div className="flex justify-end">
          <button
            disabled={loading}
            type="submit"
            className="bg-[#7b3fff] text-white py-2 px-8 rounded-full
                hover:opacity-80 disabled:cursor-not-allowed disabled:opacity-70"
          >
            valider
          </button>
        </div>
      </form>
      <ToastContainer
        style={{ top: "5rem" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default AjouterUneCommande;
