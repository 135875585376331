import React from "react";
import { Box, Typography } from "@mui/material";
import FB from '../../imgs/facebook.svg'
import IG from '../../imgs/instagram.svg'
import YTB from '../../imgs/youtube.svg'
import X from '../../imgs/xcorp.svg'
import LIN from '../../imgs/linkedin.svg'

function IconLinkRow({ icon, slug, title, value, hide }) {
  const iconPicker = () => {
  
    switch (slug) {
      case 'facebook':
        return FB;
      case 'instagram':
        return IG;
      case 'youtube':
        return YTB;
      case 'linkedin':
        return LIN;
      case 'twitter':
        return X;
      default:
        return IG;
    }
  };
  return (
    <Box sx={{ display: `${hide ? "none" : "flex"}` }} flexDirection="row" gap="0.5rem" alignItems="center" >
      <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} href={value}>
        <Box className="well" display="flex" sx={{ alignItems: "center", justifyContent: "center", border:'.3px solid #E0E0E0', padding: "12px", borderRadius: "50%" }}>
          <img width="24px" height="24px" src={iconPicker(slug)} alt={`${slug} icon`} />
        </Box>
      </a>

      <Box>
        <Typography>
          <a target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "black" }} href={value}>
            {title}
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

export default IconLinkRow;
