import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, Tab, Tabs, TextField, Tooltip, Typography, colors } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { ChartsLegend, ChartsXAxis, ChartsYAxis, PieChart, ResponsiveChartContainer } from "@mui/x-charts";
import { BarChart, BarElement } from '@mui/x-charts/BarChart';
import MultiStatsCard from "./MultiStatsCard";
import TotalStatsCard from "./TotalStatsCard";
import { ToastContainer, toast } from "react-toastify";
import { useAuthContext } from "../../hooks/useAuthContext";
import API from "../../api/Api_1_3";
import Api from '../../api/Api'
import { useNavigate } from "react-router-dom";
import { Urls } from '../../api/Urls'
import LineChart from "../../components/ui/LineChart";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup'
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import MzErrorText from "../../components/ui/MzErrorText";
import BarChartComponent from "../../components/ui/BarChart";
import FilledFields from "../../components/ui/FilledFields";

const Dashboard = () => {
    const { user } = useAuthContext()
    const access_token = user?.access_token;
    const [links,setLinks] = useState([]) 
    const [linksBdisplay, setLinksBdisplay] = useState([])
    const [servicesBdisplay, setServicesBdisplay] = useState([])
    const [statsBdisplay, setStatsBdisplay] = useState([])
    const [stats,setStats] = useState([])
    const [value, setValue] = useState('one');
    const [PPM, setPerMonth] = useState([])
    const [rowsAPI,setRowsAPI] = useState([])
    const [selectedCompany,setSelectedCompany] = useState(0)
    const [Total,setTotal] = useState(null)
    const [statsCommon, setStatsCommon] = useState([])
    const [linksCommon, setLinksCommon] = useState([])
    const [profileFilling,setFilling] = useState([])
    const [profileFilled,setFilled] = useState([])
    const [filterDates, setfilterDates] = useState([]);
    const [contacts,setContacts] = useState([])
    const [moneyCo2,setMoneyCo2] = useState([])
    const navigate = useNavigate();

    const handleChangeCompany = useCallback((event) => {
      setSelectedCompany(event.target.value);
    }, []);

    const handleApiError = (error) => {
      if (error.response.status === 403) {
          toast.error("Une erreur est survenue");
      } else {
          toast.error(error.response.data.message);
      }
      if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
      }
    };

    // BCARDS KPI - Links
    const getStatsMemo = useCallback(() => {
      API.get('/report/bcard/scans-report', {
          headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
          },
      })
      .then((response) => {
          setStats(response.data.data);
      })
      .catch(handleApiError);
  }, [access_token]);

    // BCARDS KPI - Links
    const getStatsLinks = useCallback(() => {
    API.get('/report/bcard/consultations-report', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
        setLinks(response.data.data.consultations);
    })
    .catch(handleApiError);
  }, [access_token])

  // BCARDS KPI - Links
  const getContactsReport = useCallback(() => {
    API.get('/report/bcard/contacts-report', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
        setContacts(response.data.data);
    })
    .catch(handleApiError);
  }, [access_token])

  // BCARDS KPI - Links
  const getMoneyCo2Report = useCallback(() => {
    API.get('/report/bcard/money-carbon-print-report', {
        headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
        },
    })
    .then((response) => {
      setMoneyCo2(response.data.data);
    })
    .catch(handleApiError);
  }, [access_token])


    const getProfileFilling = (values) => {
      API.get('/report/bcard/filled-profiles-report', {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
          params:{
            start_date: values?.start_date ?? null,
            end_date: values?.end_date ?? null,
          }
        })
          .then((response) => {
            setFilling(response.data.data)
          })
          .catch((error) => {
            if (error.response.status === 403) toast.error("Une erreur est survenue");
            else toast.error(error.response.data.message);
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
          });
    }

    const getProfileFilled = (values) => {
      API.get('/report/bcard/filled-profiles-report', {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
          params:{
            start_date: values?.start_date ?? null,
            end_date: values?.end_date ?? null,
          }
        })
          .then((response) => {
            setFilled(response.data.data)
          })
          .catch((error) => {
            if (error.response.status === 403) toast.error("Une erreur est survenue");
            else toast.error(error.response.data.message);
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
          });
    }

    const getCompanies = () =>{
        Api.get(Urls.GET_ENTREPRISE_TABLE , {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        })
          .then((response) => {
            setRowsAPI(response.data.enterprises);
          })
          .catch((error) => {
            if (error.response.status === 403) toast.error("Une erreur est survenue");
            else toast.error(error.response.data.message);
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
          });
    }

    // BDISPLAY KPIS
    const getStatsBdisplay = () => {
        API.get('/report/bdisplay/scans-contacts-report', {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
          })
            .then((response) => {
              setStatsBdisplay(response.data.data)
            })
            .catch((error) => {
              if (error.response.status === 403) toast.error("Une erreur est survenue");
              else toast.error(error.response.data.message);
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
            });
    }

    // BDISPLAY KPIS
    const getStatsBdisplayLinks = () => {
      API.get('/report/bdisplay/links-report', {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        })
          .then((response) => {
            setLinksBdisplay(response.data.data.links_consultation)
          })
          .catch((error) => {
            if (error.response.status === 403) toast.error("Une erreur est survenue");
            else toast.error(error.response.data.message);
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
          });
    } 

    // BDISPLAY KPIS
    const getStatsBdisplayServices = () => {
      API.get('/report/bdisplay/services-report', {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        })
          .then((response) => {
            setServicesBdisplay(response.data.data.services_consultations_downloads)
          })
          .catch((error) => {
            if (error.response.status === 403) toast.error("Une erreur est survenue");
            else toast.error(error.response.data.message);
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
          });
    }

    const getStatsPerCompany = (values) => {
      const params = {
        start_date: values?.start_date ? values?.start_date : null,
        end_date: values?.end_date ? values?.end_date : null,
        company_id: selectedCompany
      }
        if(selectedCompany){
          API.get('/report/bcard/scans-percentage', {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
            params
          })
            .then((response) => {
              setTotal(response.data.data.total_percentage)
              setPerMonth(response.data.data.percenatges_per_month);
            })
            .catch((error) => {
              if (error.response.status === 403) toast.error("Une erreur est survenue");
              else toast.error(error.response.data.message);
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
            });
        }
    };

    // COMKON KPIS
    const getStatsCommon = (values) => {
        API.get('/report/bcard-bdisplay/metrics-summary', {
            headers: {
              "Content-type": "application/json",
              accept: "application/json",
              authorization: `Bearer ${access_token}`,
            },
            params:{
              start_date: values?.start_date ?? null,
              end_date: values?.end_date ?? null,
            }
          })
            .then((response) => {
              setStatsCommon(response.data.data)
              setLinksCommon(response.data.data.consultations)
    
            })
            .catch((error) => {
              if (error.response.status === 403) toast.error("Une erreur est survenue");
              else toast.error(error.response.data.message);
              if (error.response.status === 401) {
                localStorage.removeItem("user");
                navigate("/login", { replace: true });
                navigate(0, { replace: true });
              }
            });
    }
    
    useEffect(()=>{
      if(access_token){
      getStatsMemo()
      getStatsLinks()
      getStatsCommon()
      getCompanies()
      setTimeout(()=>{
        getStatsBdisplay()
        getStatsBdisplayLinks()
        getMoneyCo2Report()
        getStatsPerCompany()
        getStatsBdisplayServices()
      },2000)
      setTimeout(()=>{
        getContactsReport()
        getProfileFilling()
        getProfileFilled()
      },3000)
    }
    },[])

    useEffect(()=>{
        getStatsPerCompany()
        getProfileFilling()
    },[selectedCompany])

    const totalContacts = [
      contacts?.contacts?.perType.CLIENT,
      contacts?.contacts?.perType.LEAD,
      contacts?.contacts?.perType.HOT_LEAD,
      contacts?.contacts?.perType.CALLED_LEAD,
      contacts?.contacts?.perType.TO_BE_INITIATED,
    ].reduce((sum, value) => sum + (value || 0), 0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const initialValuesCartes = {
    start_date: "",
    end_date: "",
    };

    //validation schema
    const validationSchema = Yup.object({
    start_date: Yup.date(),
    end_date: Yup.date(),
    });

    const formRef = useRef();

    const onSubmitSearchUsers = (values) =>{
      if(value.start_date > value.end_date){
        toast.error('Start date must be less than end date');
      }
      else {
       getStatsMemo(values)
       getProfileFilling(values)
       getProfileFilled(values)
      }
    }

    const data = [
      { name: 'First Name', value: profileFilling?.filled_columns_percentages?.first_name, color: colors.blue[600] },
      { name: 'Last Name', value: profileFilling?.filled_columns_percentages?.last_name, color: colors.blue[600] },
      { name: 'Phone Number', value: profileFilling?.filled_columns_percentages?.phone_number, color: colors.blue[600] },
      { name: 'Email', value: profileFilling?.filled_columns_percentages?.email, color: colors.blue[600] },
      { name: 'Department', value: profileFilling?.filled_columns_percentages?.department, color: colors.blue[600] },
      { name: 'Function', value: profileFilling?.filled_columns_percentages?.function, color: colors.purple[900] },
      { name: 'Country', value: profileFilling?.filled_columns_percentages?.country_name, color: colors.purple[900] },
      { name: 'City', value: profileFilling?.filled_columns_percentages?.city_name, color: colors.purple[900] },
      { name: 'Postal Code', value: profileFilling?.filled_columns_percentages?.postal_code, color: colors.purple[900] },
      { name: 'Address', value: profileFilling?.filled_columns_percentages?.address, color: colors.purple[900] },
    ];

    return ( 
        <Box>
            <ToastContainer
                style={{ top: "5rem", width: "300px" }}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Typography variant="h5" fontWeight={500}>
                Les Dernières mises à jour
            </Typography>

            <Box className="filter" display="flex" flexDirection="row" justifyContent={{
              xs:'start',
              sm:'start',
              md:'center',
              lg:'center',
              xl:'center',
            }}>
        <Formik
          innerRef={formRef}
          //initial values
          initialValues={initialValuesCartes}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchUsers(values, access_token);
            setfilterDates(values)
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column" mt={4}>

                {/*Search fields  */}

                <Box display="flex" maxWidth="1400" sx={{ flexDirection: { md: "column", xs: "column", sm: "column", lg: "row" } }}>
                  <Box flex={2} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="start_date" as={MyDPFullDate} max={values.start_date} setFieldValue={setFieldValue} variant="outlined" label="Date d'ebut" size="small" />
                    <ErrorMessage name="start_date" component={MzErrorText} />
                  </Box>
                  <Box flex={2} alignItems="center" marginRight="1rem" mb>
                    <Field fullWidth name="end_date" as={MyDPFullDate} min={values.end_date} setFieldValue={setFieldValue} variant="outlined" label="Date de fin" size="small" />
                    <ErrorMessage name="end_date" component={MzErrorText} />
                </Box>

                  <Box flex={0.5} marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Filtrer
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
            </Box>

            <Box mt={2} mb={3} >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tabs"
              >
                <Tab
                  value="one"
                  label="BCard | BDisplay"
                />
                <Tab
                  value="three"
                  label="BCard"
                />
                <Tab value="two" label="BDisplay" />
              </Tabs>
            </Box>

            {/* B-Card */}
            {value === 'three' &&
            <Box display={'flex'} flexDirection={'column'} gap={6}>
              <section className={`w-full grid grid-cols-2 mt-4 md:grid-cols-2 lg:grid-cols-2 gap-1`}>
                <MultiStatsCard total={links?.totalLinks} links={links?.linkPerType ?? {'facebook':0}} title="Nombre de Liens Ouvertes " bgColor="bg-[#5e35b1]"/>
                <section className={`w-full grid grid-cols-2 md:grid-cols-2 mt-3 place-content-start lg:grid-cols-2 gap-3`}>
                <TotalStatsCard total={stats?.scans?.total ?? 0} type="scan" title="Nombre de Scans Total" bgColor=" bg-[#FFC344]"/>
                  <TotalStatsCard total={parseInt(stats?.scans?.avg) ?? 0} type="scan" isAvg={true} title="Nombre de Scans Moyenne " bgColor={`bg-[#2AC485]`}/>
                  <TotalStatsCard total={contacts?.contacts?.total ?? 0} type="contact" title="Nombre de Contacts Total " bgColor=" bg-[#02B2AF]"/>
                  <TotalStatsCard total={links.totalBrochure ?? 0} type="brochure" title="Nombre de Brochures Ouvertes " bgColor="bg-[#A96FEB]"/>
                  <TotalStatsCard total={profileFilling.total_profiles} type="profile" title="Nombre de Profiles Total " bgColor="bg-[#5e35c1]"/>
                  <TotalStatsCard total={profileFilling.totally_filled_nbr} type="profile" title="Nombre de Profiles Remplis " bgColor="bg-[#5e35a3]"/>
                </section>

                <Box display={'flex'} flexDirection={'column'} justifyContent={'start'} alignItems={'start'}>

                  {/* Filled Fields */}
                  <Box textAlign={'center'} width={'100%'}>
                    <Typography fontWeight={600} mt={8} mb={-4}>Pourcentage Du champs remplis ({profileFilling.totally_filled_percentage}%) </Typography>
                    <FilledFields data={data}/>
                  </Box>
                  
                  {/* Collected Contacts */}
                  <Box textAlign={'center'} ml={4} width={'100%'}>
                  <Typography fontWeight={600} mt={3} >Nombre De contacts récoltés</Typography>
                  <Typography variant="subtitle2">(par type, par utilisateur)</Typography>
                  <BarChart
                      xAxis={[{ scaleType: 'band', data: ['Total De Contacts'] }]}
                      series={[
                          { data: [contacts?.contacts?.perType.CLIENT], label:'CLIENT', color: colors.blue[600] },
                          { data: [contacts?.contacts?.perType.LEAD], label:'OPPORTUNITE', color: colors.purple[900] },
                          { data: [contacts?.contacts?.perType.HOT_LEAD], label:'HOT LEAD', color: colors.purple[300] },
                          { data: [contacts?.contacts?.perType.CALLED_LEAD], label:'COLD LEAD', color: colors.purple[200] },
                          { data: [contacts?.contacts?.perType.TO_BE_INITIATED], label:'TO BE INITIATED', color: colors.purple[100] },
                      ]}
                      slotProps={{
                        legend:{
                          hidden:true
                        }
                      }}
                      width={500}
                      height={300}
                      dataKey="name"
                  />
                  </Box>

                </Box>

              </section>
                <BarChartComponent values={filterDates}/>
                <Stack mt={4} direction={{
                    xs:'column',
                    sm:'column',
                    md:'column',
                    lg:'row'
                }} 
                width="100%" textAlign="center" spacing={2}>
                <Box flexGrow={1} width={'100%'}>
                    <PieChart
                    series={[
                        {
                            data: [
                                { id: 1, value: moneyCo2?.moneySaved?.brochures, label: 'Brochures Ouvertes' },
                                { id: 0, value: moneyCo2?.moneySaved?.scans, label: 'Scans', color:'#FFC344' },
                            ],
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            outerRadius: 103,
                        },
                    ]}
                    height={250}
                    />
                    <Typography fontWeight={600}>Argent économisé (DZD)</Typography>
                </Box>
                <Box flexGrow={1} width={'100%'}>
                    <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: moneyCo2?.carbonPrintSaved?.scans, label: 'Scans' },
                                { id: 1, value: moneyCo2?.carbonPrintSaved?.brochures, label: 'Brochures Ouvertes' },
                            ],
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            outerRadius: 103,
                        },
                    ]}
                    height={250}
                    />
                    <Typography fontWeight={600}>CO2 print économisé (Kg CO2eq/kg de papier)</Typography>
                </Box>
                
                </Stack>

                {/* Scans Percentage per company */}
                <Box p={3} mt={4}>
                        <FormControl sx={{width:'33%',marginBottom:1}}>
                           <Autocomplete
                             options={rowsAPI} // Use rowsAPI as options
                             getOptionLabel={(option) => option.commercial_name} // Display commercial_name
                             onChange={(event, newValue) => setSelectedCompany(newValue?.id || 0)} // Update selectedCompany
                             renderInput={(params) => (
                               <TextField {...params} label="Entreprise" variant="outlined" />
                             )}
                           />
                        </FormControl>
                    <Box display={'flex'} flexDirection={'column'} alignItems={'start'} gap={1}>
                        <LineChart data={PPM}/>
                        <Typography fontWeight={600} mb={0} alignSelf={'center'}>
                            Pourcentage du scan par mois (Total: {Total}%)
                        </Typography>
                        <Typography variant="subtitle1" fontSize={13} fontWeight={500} mb={4} alignSelf={'center'}>
                            (Entreprise)
                        </Typography>
                    </Box>
                </Box>
            </Box>
            }

            {/* Bdisplay */}
            {value === 'two' &&
            <Box display={'flex'} flexDirection={'column'} gap={6}>
            <section className={`w-full grid grid-cols-2 mt-4 md:grid-cols-2 lg:grid-cols-2 gap-1`}>
              <MultiStatsCard total={linksBdisplay?.total} links={linksBdisplay.per_type ?? {'facebook':0}} title="Nombre de Liens Ouvertes " bgColor="bg-[#893AE4]"/>
              <section className="w-full grid grid-cols-1 mt-4 md:grid-cols-1 lg:grid-cols-2 gap-3">
                    <TotalStatsCard total={statsBdisplay?.scans} type="scan" title="Nombre de Scans Total" bgColor=" bg-[#FFC344]"/>
                    <TotalStatsCard total={statsBdisplay?.contacts} type="contact" title="Nombre de Contacts Total " bgColor=" bg-[#2AC485]"/>
                    <TotalStatsCard total={23} type="money" title="Nombre d'argent économisé " bgColor={`bg-[#02B2AF]`}/>
                    <TotalStatsCard total={20} type="co2" title="Nombre de CO2 print économisé " bgColor="bg-[#A96FEB]"/>
              </section>

              <Box flexGrow={1} mt={6} textAlign="center" width={'100%'}>
                  <BarChart
                      xAxis={[{ scaleType: 'band',data:[''] }]}
                      series={[
                        { data: [servicesBdisplay?.per_type.CONSULT_URL],label:'Consultations', color: colors.blue[600] },
                        { data: [servicesBdisplay?.per_type.DOWNLOAD_BROCHURE],label:'Téléchargements', color: colors.purple[900] },
                      ]}
                      width={500}
                      height={300}
                      dataKey="name"
                  />
                  <Typography fontWeight={600}>Total Consultations, Téléchargements ({servicesBdisplay.total}) </Typography>
              </Box>
            
            </section>

            </Box>
            }

            {/* B-Card / Bdisplay */}
            {value === 'one' &&
            <Box display={'flex'} flexDirection={'column'} gap={6}>
                <section className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3">
                  <MultiStatsCard total={linksCommon?.totalLinks} links={linksCommon?.linkPerType ?? {'facebook':0}} title="Nombre de Liens Ouvertes " bgColor="bg-[#5e35b1]"/>
                  <section className="w-full grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-3">
                    <TotalStatsCard total={statsCommon.scans?.total ?? 0} type="scan" title="Nombre de Scans Total" bgColor=" bg-[#2AC485]"/>
                    <TotalStatsCard total={statsCommon.contacts?.total ?? 0} type="contact" title="Nombre de Contacts Total " bgColor=" bg-[#02B2AF]"/>
                    <TotalStatsCard total={linksCommon?.totalBrochure ?? 0} type="brochure" title="Nombre de Brochures Ouvertes " bgColor="bg-[#A96FEB]"/>
                  </section>
                  <Box display={'flex'} flexDirection={'column'} width={'100%'}>

                    {/* bar chart */}
                    <Box flexGrow={1} alignItems={'center'} textAlign="center" width={'100%'}>
                    <Typography fontWeight={600} mt={1}>Nombre Total De contacts par type </Typography>
                    <BarChart
                        xAxis={[{ scaleType: 'band', data: ['Total De contacts'] }]}
                        series={[
                            { data: [30], label:'CLIENT', color: colors.blue[600] },
                            { data: [40], label:'OPPORTUNITE', color: colors.purple[900] },
                            { data: [20], label:'COLD LEAD', color: colors.purple[300] },
                            { data: [13], label:'HOT LEAD', color: colors.purple[200] },
                            { data: [10], label:'TO BE INITIATED', color: colors.purple[100] },
                        ]}
                        width={700}
                        height={300}
                        dataKey="name"
                    />
                    </Box>
                    
                    {/* pies */}
                    <Box display={'flex'} width={'100%'} ml={4}>

                      <Box>
                    <PieChart
                      series={[
                          {
                              data: [
                                { id: 0, value: statsCommon?.moneySaved?.scans, label: 'Scans', color:'#FFC344' },
                                { id: 1, value: statsCommon?.moneySaved?.brochures, label: 'Brochures' },
                              ],
                              highlightScope: { faded: 'global', highlighted: 'item' },
                              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                              outerRadius: 103,
                          },
                      ]}
                      height={250}
                      width={300}
                      slotProps={{
                        legend:{
                          hidden:true
                        }
                      }}
                    />
                    <Typography fontWeight={600}>Argent économisé (DZD)</Typography>
                      </Box>

                      <Box>
                    <PieChart
                    series={[
                        {
                            data: [
                                { id: 1, value: statsCommon?.carbonPrintSaved?.brochures, label: 'Brochures' },
                                { id: 0, value: statsCommon?.carbonPrintSaved?.scans, label: 'Scans', color:'#FFC344' },
                            ],
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            outerRadius: 103,
                        },
                    ]}
                    height={250}
                    width={400}

                    />
                    <Typography fontWeight={600}>CO2 print économisé (Kg CO2eq/kg de papier)</Typography>
                      </Box>

                    </Box>
                  
                  </Box>
                </section>

            </Box>
            }

        </Box>
     );
}
 
export default Dashboard;