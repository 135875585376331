import React from "react";
import { Box, Button, Typography, Modal } from "@mui/material";
import { ModalStyle } from "../../theme/ModalStyles";

function ConfimationModal({ title, message, acceptLabel, refuseLabel, acceptMethod, refuseMethod, acceptMethodParams, children, ...props }) {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <Box sx={ModalStyle}>
        <Typography fontSize="1.5rem" fontWeight="600" mb>
          {title}
        </Typography>
        <Typography mb="1.5rem">{message}</Typography>
        <Box display="flex" flexDirection="row" gap="0.5rem">
          <Button
            variant="primary"
            onClick={() => {
              acceptMethod(acceptMethodParams);
              props.onClose();
            }}>
            {acceptLabel}
          </Button>
          <Button onClick={props.onClose} variant="third">
            {refuseLabel}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ConfimationModal;
