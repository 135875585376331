import ButtonDataGrid from "../components/ui/ButtonDataGrid";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import { colors } from "../theme/Colors";
import { Check, Close, LockClockOutlined, Pending, WatchLater } from "@mui/icons-material";

export const columns = [
  {
    field: "commercial_name",
    headerName: "Nom commercial",
    description: "Nom commercial de l'entreprise.",
    width: 250,
    align: "left",
    renderCell: (params) => {
      return (
        <Box sx={{ ":hover": { color: `${colors.main_purple_hover}`, fontWeight: "bold", textDecoration: "underline" },fontWeight:params.row.status === 'WAITING' ? 'bold' : 'regular' }} height="100%" width="100%">
          <Link style={{ textDecoration: "none", color: "inherit", height: "100%", width: "100%" }} to={`/admin/gestion-des-entreprises/${params.row.id}`}>
            <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{params.row.commercial_name}</Box>
          </Link>
        </Box>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    renderCell: (params) => {
      return (
          <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", fontWeight:500, alignItems: "center", color:params.row.status === 'WAITING' ? 'orange' : params.row.status === 'CONFIRMED' ? 'green' : 'red' }}>
            {params.row.status === 'WAITING' ? <WatchLater/> : params.row.status === 'CONFIRMED' ? <Check/> : <Close/>}
            {params.row.status === 'WAITING' ? 'Waiting' : params.row.status === 'CONFIRMED' ? 'Confirmed' : 'Rejected'}
          </Box>
      );
    },
  },
  {
    field: "type",
    headerName: "Type",
    width: 200,
    renderCell: (params) => {
      return (
          <Box sx={{ width: "100%", cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}>{params.row.type}</Box>
      );
    },
  },
];

export const rows = [
  {
    id: 1,
    social_reason: "TELECOMMUNICATION",
    commercial_name: "DJEZZY",
    commercial_register: "65412319848961532",
    nif: "123123564941532123",
    city_name: "Algiers",
    country_name: "Algérie",
    action: ["consult"],
  },
];
