import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Modal,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import LinearProgress from "@mui/material/LinearProgress";
import { columns } from "../../data/commandeData";
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import { frFR } from "@mui/x-data-grid";

import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import { useNavigate } from "react-router-dom";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { ModalStyle } from "../../theme/ModalStyles";
import { NavLink } from "react-router-dom";

function Users() {
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const formRef = useRef();

  //navigate to create enterprise
  const navigate = useNavigate();

  //data grid pages configuration
  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  //data grid pages configuration
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  //ondelete state
  const [del, setDel] = useState(false);
  //validation schema
  const validationSchema = Yup.object({
    id: Yup.string(),
    createdAtMin: Yup.date(),
    createdAtMax: Yup.date(),
    numberOfCardsMax: Yup.number(),
    numberOfCardsMin: Yup.number(),
    statusList: Yup.array(),
  });

  //page state

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  // eliminate empty data
  function nonEmptyObject(obj) {
    let tempObj = obj;
    for (const propName in tempObj) {
      if (
        (typeof tempObj[propName] === "object" &&
          Object.keys(tempObj[propName]).length === 0) ||
        tempObj[propName] === null ||
        tempObj[propName] === ""
      ) {
        delete tempObj[propName];
      }
    }
    return tempObj;
  }

  const status = {
    INIT: "Initiale",
    ESTIMATE_UPLOADED: "Devis téléchargé",
    PURCHASE_ORDER_UPLOADED: "Bon de commande téléchargé",
    PURCHASE_ORDER_CONFIRMED: "Bon de commande confirmé",
    PURCHASE_ORDER_REFUSED: "Bon de commande refusé",
    LIST_COLLABORATORS_UPLOADED: "Collaborateurs téléchargés",
    LIST_COLLABORATORS_ACCEPTED: "Collaborateurs acceptés",
    INVOICE_UPLOADED: "Facture téléchargée",
    PAYMENT_MADE: "Paiement effectué",
    PAYMENT_REFUSED: "Paiement refusé",
    PAYMENT_ACCEPTED: "Paiement accepté",
    IN_PRODUCTION: "En production",
    DELIVERY: "En cours de livraison",
    DELIVERED: "Livrée",
  };

  const getOrders = ()=>{
    setIsLoading(true);
    Api.get(Urls.GET_COMMANDE_LIST, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: { perPage: perPage },
    })
      .then((response) => {
        //console.log("RESPONSE TABLE", response.data.orders);
        setPage(0);
        setIsLoading(false);
        setRowsAPI(response.data.orders);
        setTotal(response.data.meta.total);
        return;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }
  //on page load
  useEffect(() => {
    getOrders()
  }, []);

  //on search
  const onSubmitSearchCommande = (values) => {
    let realValues = nonEmptyObject(values);

    setPage(0);
    setIsLoading(true);
    Api.get(Urls.GET_COMMANDE_LIST + `?perPage=${perPage}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params: realValues,
    })
      .then((response) => {
        //console.log("response", response);
        setIsLoading(false);
        setRowsAPI(response.data.orders);
        setTotal(response.data.meta.total);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
        setIsLoading(false);
      });
  };

  //initial values
  const initialValues = {
    id: "",
    createdAtMax: "",
    createdAtMin: "",
    numberOfCardsMax: "",
    numberOfCardsMin: "",
    statusList: [],
  };
  //delete une commande
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("0zFgtYmLv9");
  //delete commandes
  const deletecommandes = () => {
    Api.delete(Urls.DELETE_COMMANDES, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
      data: {
        order_ids: selectedIDs,
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("commande supprimée");
        setOnDeletNext(false);
        setDel(!del);
        getOrders()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };
  return (
    <Box>
      {/* Header Section */}

      {/* Filter  Section */}
      {/* Form Section */}

      <Box className="filter" display="flex" flexDirection="row">
        <ToastContainer
          style={{ top: "5rem" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitSearchCommande(values, access_token);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography noWrap mr marginTop="0.45rem" mb>
                    Filtre
                  </Typography>
                </Box>

                {/*Search fields */}

                <Box
                  display="flex"
                  maxWidth="1000px"
                  sx={{ flexDirection: { md: "row", xs: "column" } }}
                  marginBottom="1rem"
                >
                  <Box flex={0.5} alignItems="center" marginRight="1rem" mb>
                    <Field
                      name="id"
                      type="input"
                      as={TextField}
                      label="Id"
                      id="id"
                      size="small"
                    />
                    <ErrorMessage name="id" as={MzErrorText} />
                  </Box>
                  <Box flex={1.5} alignItems="center" marginRight="1rem" mb>
                    <Field
                      fullWidth
                      name="createdAtMin"
                      as={MyDPFullDate}
                      max={values.createdAtMax}
                      setFieldValue={setFieldValue}
                      value={values.createdAtMin}
                      variant="outlined"
                      label="Date min"
                      size="small"
                    />
                    <ErrorMessage name="createdAtMin" component={MzErrorText} />
                  </Box>
                  <Box flex={1.5} alignItems="center" marginRight="1rem" mb>
                    <Field
                      fullWidth
                      name="createdAtMax"
                      as={MyDPFullDate}
                      min={values.createdAtMin}
                      setFieldValue={setFieldValue}
                      value={values.createdAtMax}
                      variant="outlined"
                      label="Date max"
                      size="small"
                    />
                    <ErrorMessage name="createdAtMax" component={MzErrorText} />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  maxWidth="1000px"
                  sx={{ flexDirection: { md: "row", xs: "column" } }}
                  marginBottom="1rem"
                >
                  <Box alignItems="center" marginRight="1rem" mb>
                    <Field
                      label="nombre d’abonnements min"
                      name="numberOfCardsMin"
                      as={TextField}
                      type="input"
                      size="small"
                    />
                    <ErrorMessage
                      name="numberOfCardsMin"
                      component={MzErrorText}
                    />
                  </Box>
                  <Box alignItems="center" marginRight="1rem" mb>
                    <Field
                      label="nombre d’abonnements max"
                      name="numberOfCardsMax"
                      as={TextField}
                      type="input"
                      size="small"
                    />
                    <ErrorMessage
                      name="numberOfCardsMax"
                      component={MzErrorText}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="0.5rem"
                >
                  <Box
                    sx={{ flex: "1" }}
                    alignItems="center"
                    marginRight="1rem"
                    mb
                  >
                    <Field
                      name="statusList"
                      component={Autocomplete}
                      multiple={true}
                      options={[
                        "INIT",
                        "ESTIMATE_UPLOADED",
                        "PURCHASE_ORDER_UPLOADED",
                        "PURCHASE_ORDER_REFUSED",
                        "PURCHASE_ORDER_CONFIRMED",
                        "LIST_COLLABORATORS_UPLOADED",
                        "LIST_COLLABORATORS_ACCEPTED",
                        "INVOICE_UPLOADED",
                        "PAYMENT_MADE",
                        "PAYMENT_REFUSED",
                        "PAYMENT_ACCEPTED",
                        "IN_PRODUCTION",
                        "DELIVERY",
                        "DELIVERED",
                      ]}
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionLabel={(s) => status[`${s}`]}
                      onChange={(event, value) => {
                        setFieldValue("statusList", value ? value : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ maxWidth: "600px" }}
                          {...params}
                          name="autocomplete"
                          label="Statut"
                        />
                      )}
                      size="small"
                    />
                    <ErrorMessage name="statusList" component={MzErrorText} />
                  </Box>
                  <Box flex={0.5} marginRight="1rem" mb>
                    <Button type="submit" variant="primary">
                      Chercher
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <div className="flex gap-3 justify-between">
        <Button
          onClick={() => setOnDelete(true)}
          disabled={selectedIDs.length === 0 ? true : false}
          sx={{
            ":disabled": {
              backgroundColor: "gray",
              color: "white",
              border: "2px solid gray",
            },
          }}
          variant="third"
        >
          {" "}
          Supprimer une/des commande(s){" "}
        </Button>
        <div>
          <NavLink
            id="navlink"
            to={{ pathname: "ajouter-une-commande" }}
            className="flex bg-[#7B3FFF] p-2 rounded-lg justify-center text-white hover:opacity-70"
          >
            Ajouter une commande
          </NavLink>
        </div>
      </div>
      <ConfirmationModal
        title="Supprimer une/des commande(s)"
        message="Êtes-vous sûr de bien vouloir supprimer cette/ces commande(s) ?"
        acceptLabel="oui"
        refuseLabel="non"
        open={ondelite}
        onClose={() => setOnDelete(false)}
        acceptMethod={() => setOnDeletNext(true)}
      />
      <Modal open={ondeliteNext} onClose={() => setOnDeletNext(false)}>
        <Box sx={ModalStyle}>
          <Typography fontSize="1.5rem" fontWeight="600" mb>
            Supprimer une/des commande(s)
          </Typography>
          <Typography mb="1rem">Entrez votre mot de passe</Typography>
          <Box marginBottom="1rem">
            <TextField
              type="password"
              value={motdepasse}
              onChange={(e) => setMotDePasse(e.target.value)}
              name="motdepasse"
              placeholder="Mot de passe"
            />
          </Box>
          <Box display="flex" flexDirection="row" gap="0.5rem">
            <Button
              variant="primary"
              onClick={() => {
                deletecommandes();
              }}
            >
              Supprimer
            </Button>
            <Button onClick={() => setOnDeletNext(false)} variant="third">
              Annuler
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* End form section */}

      <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even"
          }
          // sx={{ maxWidth: "1180px" }}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          //allow for multiple rows selection when checkboxSelection is on
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rowsAPI.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
            //setSelectedRows(selectedRows);
            // console.log(selectedIDs);
            // console.log(selectedRows);
          }}
          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rowsAPI}
          //allow selection
          checkboxSelection
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // console.log(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          //onpage change
          onPageChange={(newPage) => {
            // console.log(newPage);
            setPage(newPage);
          }}
          pagination
          page={page}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
        {/* <pre>{JSON.stringify(selectedRows, null, 2)}</pre>*/}
      </Box>
    </Box>
  );
}

export default Users;
