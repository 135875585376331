import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
  Modal,
  IconButton,
  styled,
  Avatar,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import * as Yup from "yup";
import getCroppedImg from "../../utils/cropImage";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { CircularProgress } from "@mui/material";
import { useAuthContext } from "../../hooks/useAuthContext";
import MainTitle from "../../components/ui/MainTitle";
import { Link, NavLink, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import MzLabel from "../../components/ui/MzLabel";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import { ModalStyle } from "../../theme/ModalStyles";
import API from "../../api/Api_1_3";
import { colors } from "../../theme/Colors";
import MyDPFullDate from "../../components/ui/MyDPFullDate";
import Cropper from "react-easy-crop";
import placeholder from '../../imgs/logo.png'
import Excel from '../../imgs/excel.svg'
import { Check, Close, PictureAsPdf } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useMediaQuery } from '@mui/material';
import { DataGridHeaderStyle } from "../../components/dataGridStyles/DataGridHeaderStyle";
import { StripedDataGrid } from "../../components/ui/StripedGrid";
import { columns } from "../../data/cartesAbonnesData";
import { PagesColumns } from "../../data/PagesEnterpriseData";
import { CustomToolbar } from "../../components/dataGridStyles/CustomToolbar";
import EmptyDataGridOverlay from "../../components/ui/EmptyDataGridOverlay";
import LinearProgress from "@mui/material/LinearProgress";
import { frFR } from "@mui/x-data-grid";
import axios from "axios";
import AlertError from '../../components/ui/AlertError'

function EntrepriseDetails() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const [change, setChange] = useState(false);
  const minDate = new Date();
  const [ formData, setFormData ] = useState({
    activation_date:"",
    expiration_date:""
  })

  const { id } = useParams();

  //validation schema entreprise
  const validationSchemaEntreprise = Yup.object({
    social_reason: Yup.string().required("Ce champ est obligatoire"),
    commercial_name: Yup.string().required("Ce champ est obligatoire"),
    city_name: Yup.string().required("Ce champ est obligatoire"),
    postal_code: Yup.string().required("Ce champ est obligatoire"),
    address: Yup.string().required("Ce champ est obligatoire"),
    phone_number: Yup.number().required("Ce champ est obligatoire"),
    email: Yup.string().required("Ce champ est obligatoire").email('Email non valide'),
    fix: Yup.number(),
    website: Yup.string(),
    default_background_color: Yup.string(),
    default_text_color: Yup.string(),
    plan_id: Yup.string(),
    collaborators_file: Yup.mixed(),
    activation_date: Yup.string(),
    expiration_date: Yup.string().when("collaborators_file", {
      is: (value) => value !== undefined && value !== null && value !== "",
      then: Yup.string().required("Ce champ est obligatoire"),
    }),
  });

  //validation schema user
  const validationSchemaUser = Yup.object({
    email: Yup.string().required("Ce champ est obligatoire"),
    first_name: Yup.string().required("Ce champ est obligatoire"),
    last_name: Yup.string().required("Ce champ est obligatoire"),
    department: Yup.string().required("Ce champ est obligatoire"),
    function: Yup.string().required("Ce champ est obligatoire"),
  });

  const getColls = () => {
    setIsLoading(true);
    Api.get(Urls.USERS_ROLE, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        Api.get(Urls.GET_COLLABS, {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
          params: { perPage: perPage, collaboratedEnterpriseId: id },
        })
          .then((response) => {
            setPage(0);
            setIsLoading(false);
            if (res.data.data === "SALE") {
              const temp = response.data.users.map(
                (item) =>
                (item = {
                  ...item,
                  email: "##########",
                  department: "############",
                  function: "############",
                  phone_number: "############",
                })
              );
              setRowsAPI(temp);
            } else {
              setRowsAPI(response.data.users);
            }
            setTotal(response.data.meta.total);
            //console.log(response.data.users);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
            if (error?.response?.status === 403) {
              toast.error("Une erreur est survenue");
            } else {
              toast.error(error?.response?.data?.message);
            }
            // console.log(error);
          })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getPages = () => {
    setIsLoading(true);
    Api.get("/pages", {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
      params:{
        enterprise_id: id
      }
    })
      .then((response) => {
        setRowsAPIPages(response.data.pages ?? []);
      })
      .catch((error) => {
        toast.error(error.response.data.message )
        console.log(error);
      });
  }

  //API data state
  const [data, setData] = useState();
  const [selectedFileName, setSelectedFileName] = useState("");

  //navigate error page
  const navigate = useNavigate();

  //on page load (load entreprise details)
  useEffect(() => {
    API.get('/enterprises/' + `${id}`, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        setData(response.data.data);
        setImage(response.data?.data?.logo)
        setPhoto(response.data?.data?.admin.url_profile_picture)
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error.response?.status === 404) navigate("/not_found");
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, [change]);

  const onSubmitUpdateEntreprise = (values) => {
    const formData = new FormData();
    delete values.company_admin.civilite;

    const appendFormData = (key, value) => {
        if (value !== null && value !== "") {
            formData.append(key, value);
        }
    };

    Object.keys(values).forEach((key) => {
        const value = values[key];
        if (key === "company_admin" && typeof value === "object") {
            Object.keys(value).forEach((adminKey) => {
                const adminValue = value[adminKey];
                if (adminKey === "photo") {
                    if (typeof adminValue !== "string" && adminValue !== null) {
                        formData.append(`company_admin[${adminKey}]`, adminValue);
                    }
                } else {
                    appendFormData(`company_admin[${adminKey}]`, adminValue);
                }
            });
        } else if (key === "logo" || key === "photo") {
            if (typeof value !== "string" && value !== null) {
                formData.append(key, value);
            }
        } else {
            appendFormData(key, value);
        }
    });

    // Add attach-collaborators-method if file exists
    const data = {
      collaborators_file: values.collaborators_file,
      activation_date: values.activation_date,
      expiration_date: values.expiration_date
    }

      API.post(
      `/enterprises/${id}?_method=PATCH`,
      formData,
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {

        if (response.status === 200) {
          if(values.collaborators_file){
            API.post(`/admin/enterprises/add-new-collaborators/${id}`,
              data,
              {
                headers: {
                  "Content-type": "multipart/form-data",
                  authorization: `Bearer ${access_token}`,
                },
              }
            )
              .then((response) => {
        
                if (response.status === 200 && errors.length === 0) {
                  toast.success('Collaborateurs Attachées avec succès!');
                  toast.success('entreprise mis a jour avec succès');
                  setTimeout(()=>{
                    navigate('/admin/gestion-des-entreprises')
                  },300)
                }
              })
              .catch((error) => {
                if (error.response.status === 401) {
                  localStorage.removeItem("user");
                  navigate("/login", { replace: true });
                  navigate(0, { replace: true });
                }
                if (error?.response?.status === 403) {
                  toast.error("Une erreur est survenue");
                }
                if (error?.response?.status === 400) {
                  toast.error("Une erreur est survenue");
                  setErrors(!error.response.data.message ? error.response.data.errors : [])
                  return 1;
                }
                 else {
                  setErrors(!error.response.data.message ? error.response.data.errors : [])
                  return;
                }
              });
          }
          if(!values.collaborators_file){
            toast.success('entreprise mis a jour avec succès');
            setTimeout(()=>{
              navigate('/admin/gestion-des-entreprises')
            },300)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
    
  };

  const [progress, setProgress] = useState(0);


  //logo state
  const [image, setImage] = useState(placeholder);
  const [photo, setPhoto] = useState(placeholder);

  //crop image config
  const formikRef = useRef();

  const [openReject,setOpenReject] = useState(false)
  const handleOpenReject = () => setOpenReject(true)
  const handleCloseReject = () => setOpenReject(false)
  const [openValidate,setOpenValidate] = useState(false)
  const handleOpenValidate = () => setOpenValidate(true)
  const handleCloseValidate = () => setOpenValidate(false)

 
  
 
  //supp une entreprise
  const [ondelite, setOnDelete] = useState(false);
  const [ondeliteNext, setOnDeletNext] = useState(false);
  const [motdepasse, setMotDePasse] = useState("");
  const [plans,setPlans] = useState([])
  const [reason,setReason] = useState("")
  const handleReasonChange = (value)=> {
    setReason(value)
  }

  const [onImage, setOnImage] = useState(false);
  const [onImageCompany, setOnImageCompany] = useState(false);


  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [cropLogo, setCropLogo] = useState({ x: 0, y: 0 });
  const [zoomLogo, setZoomLogo] = useState(1);
  const [possibleLinks,setPossibleLinksList] = useState([])


  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [croppedAreaPixelsLogo, setCroppedAreaPixelsLogo] = useState();

  const [format, setFormat] = useState("");
  const [formatLogo, setFormatLogo] = useState("");
  const [onUploadImage, setOnUploadImage] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:960px)');
  const isDesktop = useMediaQuery('(min-width:960px)');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedIDs, setSelectedIDs] = useState([]);
  // const [openParams, setOpenParams] = React.useState(false);
  const pageNumbers = [10, 25, 50, 100];
  const [rowsAPI, setRowsAPI] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(pageNumbers[1]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [rowsAPIPages, setRowsAPIPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [perPageBdipslay, setPerPageBdipslay] = useState(pageNumbers[1]);
  const [pageBdisplay, setPageBdisplay] = useState(1);
  const [errors,setErrors] = useState([])

  const flexDirection = isMobile ? 'column' : isTablet ? 'column' : 'row';

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCropCompleteLogo = (croppedArea, croppedAreaPixelsLogo) => {
    setCroppedAreaPixelsLogo(croppedAreaPixelsLogo);
  };

  const onCrop = async (setFieldValue) => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixelsLogo, formatLogo);
    setImage(URL.createObjectURL(croppedImage));
  
    if (croppedImage) {
      const file = new File([croppedImage], `company_logo.${formatLogo.split("/")[1]}`, { type: formatLogo });
      setOnImageCompany(false);
      setFieldValue("logo", file);
  };
}
  

  const onCropAdmin = async (setFieldValue) => {
    const croppedImage = await getCroppedImg(photo, croppedAreaPixels, format);
    setPhoto(URL.createObjectURL(croppedImage));

    const file = new File([croppedImage], `company_admin_photo.${format.split("/")[1]}`, { type: format });
    if (croppedImage) {
      setOnImage(false);
      setFieldValue("company_admin.photo", file);
    }
  };

  const styleCropper = {
    containerStyle: {
      positon: "absolute",
      top: "50px",
      margin: "0 auto",
      height: "60%",
      width: "60%",
    },
  };

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const validateEntreprise = (values)=>{
    handleCloseValidate()
    const formData = new FormData()
    if(values.activation_date === '' || values.activation_date === null ){
      delete values.activation_date
      formData.delete('activation_date')
    }
    else {
      formData.append('activation_date',values.activation_date)
    }
    
    formData.append('expiration_date',values.expiration_date)

    API.post('/admin/enterprises/validate-auto-creation/' + id, formData, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        if(res.status === 200){
          toast.success("entreprise validé");
        setTimeout(() => {
          navigate("/admin/gestion-des-entreprises");
        }, 500);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }
  const onSubmitreject = () =>{
    handleCloseReject()
    API.post('/admin/enterprises/reject-auto-creation/' + id, 
    {
      rejection_reason: reason
    }
    , {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        if(res.status === 200){
          toast.success("entreprise rejeté");
        setTimeout(() => {
          navigate("/admin/gestion-des-entreprises");
        }, 500);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }
  //delete entreprise
  const deleteENT = () => {
    Api.delete(Urls.DELETE_ENTREPRISE, {
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${access_token}`,
      },
      data: {
        enterprise_ids: [id],
        passphrase: motdepasse,
      },
    })
      .then((res) => {
        toast.success("entreprise supprimée");
        setOnDeletNext(false);
        setTimeout(() => {
          navigate("/admin/gestion-des-entreprises");
        }, 500);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const getPlans = async () => {
    const res = await Api.get('/admin/plans',{
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
    const data = await res.data.data
    const values = data.map((item) => ({ id: item.id, name: item.name }))
    setPlans(values);
  }

  const initValuesEnt = {
    social_reason: data?.social_reason ?? "",
    commercial_name: data?.commercial_name ?? "",
    commercial_register: data?.commercial_register ?? "",
    nif: data?.nif ?? "",
    country_name: data?.country_name ?? "",
    city_name: data?.city_name ?? "",
    postal_code: data?.postal_code ?? "",
    address: data?.address ?? "",
    phone_number: data?.phone_number ?? "",
    email: data?.email ?? "",
    fix: data?.fix ?? "",
    logo: data?.url_logo ?? null,
    default_background_color: data?.default_background_color ?? colors.main_purple,
    default_text_color: data?.default_text_color ?? "#FFFFFF",
    plan: data?.plan ?? "",
    type: data?.type ?? "",
    status: data?.status ?? "",
    website: data?.website ?? ""
  }

  const initValuesAdmin = {
    [data?.status === "WAITING" ? "receiver_email" : "email"]: data?.status === "WAITING" ? data?.receiver_email ?? "" : data?.admin?.email ?? "",
    [data?.status === "WAITING" ? "receiver_first_name" : "first_name"]: data?.status === "WAITING" ? data?.receiver_first_name ?? "" : data?.admin?.first_name ?? "",
    [data?.status === "WAITING" ? "receiver_last_name" : "last_name"]: data?.status === "WAITING" ? data?.receiver_last_name ?? "" : data?.admin?.last_name ?? "",
    [data?.status === "WAITING" ? "receiver_phone_number" : "phone_number"]: data?.status === "WAITING" ? data?.receiver_phone_number ?? "" : data?.admin?.phone_number ?? "",
    department: data?.admin?.department ?? "",
    function: data?.admin?.function ?? "",
    civilite:
      data?.admin?.sex === "MALE"
        ? "M."
        : data?.admin?.sex === "FEMALE"
        ? "Mme."
        : "",
    photo: data?.admin?.url_profile_picture ?? null
  };
  useEffect(() => {
    getPlans()
    setTimeout(()=>{
      getColls()
      getPages()
    },1000)

    Api.get(Urls.GET_POSSIBLE_LINKS, {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((response) => {
        // console.log("POSSIBLE LINKS :", response.data.links);
        setPossibleLinksList(response.data.links);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  }, []);

  const getLogoUrl = (title) => {
    const link = possibleLinks.find((link) => link.title === title);
    return link ? link.url_logo : "";
  };

  const [value, setValue] = useState('one')
  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    const response = await axios.get('https://restcountries.com/v3.1/all');
    return response.data.map(country => ({
      code: country.cca2,
      name: country.name.common,
      geonameId: country.geonameId || null,
    }));
  };

  const formats = [
    {
      id:0,
      name: 'SARL',
    },
    {
      id:1,
      name: 'EURL',
    },
    {
      id:2,
      name: 'SPA',
    },
    {
      id:3,
      name: 'SNC',
    },
    {
      id:4,
      name: 'DC',
    },
    {
      id:5,
      name: 'SCS',
    },
    {
      id:6,
      name: 'Groupement',
    },
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const getCountries = async () => {
      try {
        const countries = await fetchCountries();
        setCountries(countries);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };
      getCountries();
  }, []);

  // Cover Section
  const [coverImage, setCoverImage] = useState();
  const [onCoverImage, setOnCoverImage] = useState(false);
  const [coverCrop, setCoverCrop] = useState({ x: 0, y: 0 });
  const [coverZoom, setCoverZoom] = useState(1);
  const [coverCroppedAreaPixels, setCoverCroppedAreaPixels] = useState();
  const [coverFormat, setCoverFormat] = useState("");
  const [coverProgress, setCoverProgress] = useState(0);
  const [onUploadCoverImage, setOnUploadCoverImage] = useState(false);
  const [openDeleteCoverPic, setOpenDeleteCoverPic] = useState(false);
  const coverImageProfile = useRef();
  const onCoverCropComplete = (croppedArea, croppedAreaPixels) => {
    setCoverCroppedAreaPixels(croppedAreaPixels);
  };
  const onCoverCrop = async () => {
    const croppedImage = await getCroppedImg(coverImage, coverCroppedAreaPixels, coverFormat);
    setCoverImage(URL.createObjectURL(croppedImage));
    var file = new File([croppedImage], `${Math.floor(Math.random() * 10000000000000000)}.${coverFormat.split("/")[1]}`, { lastModified: 1534584790000, type: [`${coverFormat}`] });
    if (file && croppedImage) {
      setOnCoverImage(false);
      formikRef.current.values.cover_picture = file;
      file = null;
    }
  };
  const deleteCoverPic = () => {
    API.delete('/enterprises/delete-cover-picture/' + id , {
      headers: { "Content-Type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res.data.message);
        setChange(!change);
        setCoverImage(null);
      })
      .catch((error) => {
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
      });
  };
  const onSubmitUpdateCoverPicture = (values) => {
    setOnUploadCoverImage(true);
    API.post(`/enterprises/update-cover-picture/${data?.id}?_method=PATCH`,
      {
        cover_picture: values.cover_picture,
      },
      {
        headers: {
          "Content-type": "multipart/form-data",
          authorization: `Bearer ${access_token}`,
        },
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setCoverProgress(percentCompleted);
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setOnUploadCoverImage(false);
          setCoverProgress(0);
        }
        setChange(!change);
      })
      .catch((error) => {
        setOnUploadCoverImage(false);
        if (error.response.status === 403) toast.error("Une erreur est survenue");
        else toast.error(error.response.data.message);
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
      });
  };
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme.breakpoints.only('sm'));
  const isMdScreen = useMediaQuery(theme.breakpoints.only('md'));
  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const getAvatarStyle = () => {
    let width;
    if (isXsScreen || isSmScreen) {
      width = '100%';
    } else if (isMdScreen) {
      width = '45.3125rem'; // 725px
    } else if (isLgScreen) {
      width = '45.3125rem'; // 725px
    }
    return {
      width
    };
  };
  // End: Cover Section
  
  return (
    <Box>
      {/* Header Section */}
      <Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography sx={{ marginBottom: "1.5rem", fontWeight: "500" }}>
            Détail de l'entreprise
          </Typography>
          <Box>
          <Button 
            variant="secondary" 
            startIcon={<ArrowBackIosIcon/>}
            onClick={() => navigate('/admin/gestion-des-entreprises/')}
            sx={{ marginBottom: "1rem" }} 
          >
            Retour
          </Button>
        </Box>
        </Box>
        {/* FORMULAIRE  */}
        <Box
          display="flex"
          flexDirection={'column'}
          gap={'2rem'}
        >
          <Formik
              innerRef={formikRef}
              initialValues={{ cover_picture: null}}
              onSubmit={(values) => {            
                onSubmitUpdateCoverPicture(values);
              }}
            >
            {({ values, setFieldValue, errors }) => (
                <Form 
                  style={{
                    display:'flex',
                    flexDirection: flexDirection,
                    justifyContent:'center',
                    gap:'1rem'
                  }}
                >
                  {/* Cover Picture Section */}
                  <Box display="flex" flexDirection="column">
                      <MzLabel>Coverture de l'entreprise </MzLabel>
                        <Avatar
                          sx={[getAvatarStyle(),{
                            position: "relative",
                            borderRadius: "20px",
                            height: "14rem", // 224px
                            border: "1px solid #C9C5D2",
                            marginBottom: "1rem",
                          }]}
                          variant="rounded"
                        >
                          {coverImage ? (
                            <img alt="cover-pic" ref={coverImageProfile} src={coverImage} style={{ width: "100%", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                          ) : data?.url_cover_picture ? (
                            <img alt="cover-pic" ref={coverImageProfile} src={data?.url_cover_picture} style={{ width: "100%", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                          ) : (
                            <img alt="cover-pic" src={'https://placehold.co/725x224'} style={{ width: "45.3125rem", height: "14rem", objectFit: "cover", objectPosition: "50% 50%" }} />
                          )}
                          <IconButton
                            disabled={formikRef.current?.values?.status === 'CONFIRMED' ? false : true}
                            sx={{
                              zIndex: "33",
                              position: "absolute",
                              right: "0.5rem",
                              bottom: "0.5rem",
                              bgcolor: `${colors.main_purple}`,
                              "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                            }}
                            component="label"
                          >
                            <input
                              hidden
                              id="cover_picture"
                              name="cover_picture"
                              type="file"
                              onChange={(event) => {
                                setFieldValue("cover_picture", event.target.files[0] ? event.target.files[0] : null);
                                if (event.target.files[0]) {
                                  setOnCoverImage(true);
                                  setCoverImage(URL.createObjectURL(event.target.files[0]));
                                  setCoverFormat(event.target.files[0].type);
                                }
                              }}
                            />
                            <AddRoundedIcon htmlColor="white" />
                          </IconButton>
                        </Avatar>
                        <Modal
                          sx={{ backgroundColor: "#3f3f3f" }}
                          open={onCoverImage}
                          onClose={() => {
                            setCoverImage(null);
                            formikRef.current.values.cover_picture = "";
                            setOnCoverImage(false);
                          }}
                        >
                          <Box height="100%" width="100%" margin="0 auto">
                            <Box sx={{ height: "80%", width: "80%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <Box height="100%" width="100%" display="flex" flexDirection="column">
                                <Cropper style={styleCropper} image={coverImage} crop={coverCrop} zoom={coverZoom} aspect={725 / 224} onCropChange={setCoverCrop} onCropComplete={onCoverCropComplete} onZoomChange={setCoverZoom} />
                              </Box>
                            </Box>
                            <Box position="absolute" bottom="5%" left="0" right="0" gap="1rem" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                              <MzErrorText>{errors.cover_picture ? errors.cover_picture : null}</MzErrorText>
                              <Box width="fit-content" display="flex" flexDirection="row" gap="1rem" alignItems="center">
                                <Button disabled={errors?.cover_picture ? true : false} onClick={onCoverCrop} variant="primary">
                                  Confirmer
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    setCoverImage(null);
                                    formikRef.current.values.cover_picture = "";
                                    setOnCoverImage(false);
                                  }}
                                >
                                  Annuler
                                </Button>
                              </Box>
                              <Box display="flex" gap="0.5rem" sx={{ flexDirection: { xs: "column", sm: "row", lg: "row", md: "row" }, alignItems: "center" }}>
                                <Typography color="white">Niveau de zoom : </Typography>
                                <input type="range" min={1} max={3} step={0.1} value={coverZoom} onInput={(e) => setCoverZoom(e.target.value)} />
                              </Box>
                            </Box>
                          </Box>
                        </Modal>
                        <Box>
                          {errors.cover_picture ? <MzErrorText>{errors.cover_picture}</MzErrorText> : null}
                        </Box>
                        <Box display="flex" flexDirection="column" alignItems="end" gap="0.5rem" sx={{ margin: "0.5rem 0rem 1rem 0rem" }}>
                          <Box display="flex" gap="0.5rem" alignItems="center">
                            {
                              formikRef.current?.values?.status === 'CONFIRMED'
                              ?
                              <Button  variant="primary" onClick={()=> onSubmitUpdateCoverPicture(values)}>
                                Enregistrer la photo
                              </Button>
                              :
                              null
                            }
                            {data?.url_cover_picture && (
                              <IconButton
                                onClick={() => deleteCoverPic()}
                                sx={{
                                  zIndex: "33",
                                  bgcolor: `${colors.bd_suspended}`,
                                  "&:hover": { bgcolor: `${colors.bd_suspended_hover}` },
                                }}
                                component="label"
                              >
                                <DeleteRoundedIcon htmlColor="white" />
                              </IconButton>
                            )}
                          </Box>
                          <Box>
                            {onUploadCoverImage && (
                              <Box display="flex" alignItems="center" gap="1rem">
                                <CircularProgress size={30} />
                                <Typography width="20px" color={colors.main_purple}>
                                  {coverProgress ? coverProgress + "%" : null}
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                  </Box>
                </Form>
            )}
          </Formik>

          {/* Formik 2 - ENT */}
          <Box>
            <Formik
              innerRef={formikRef}
              enableReinitialize={true}
              initialValues={{...initValuesEnt, company_admin: {...initValuesAdmin}}}
              validationSchema={validationSchemaEntreprise}
              onSubmit={(values, { setSubmitting }) => {            
                onSubmitUpdateEntreprise(values, access_token);
                setSubmitting(false);
              }}
            >
              {({ values, setFieldValue, errors }) => (
                <Form 
                  style={{
                    display:'flex',
                    flexDirection: flexDirection,
                    justifyContent:'center',
                    gap:'1rem'
                  }}
                >

                {/* Enterprise photo */}
                <Box display="flex" flexDirection="column" >

                  <MzLabel>Logo de l'entreprise </MzLabel>
                  <Avatar
                sx={{
                  position: "relative",
                  borderRadius: "20px",
                  width: "12rem",
                  height: "12rem",
                  border: "1px solid #C9C5D2",
                  marginBottom: "1rem",
                }}
                variant="rounded"
              >
                {image ? (
                  <img
                    src={image}
                    alt=""
                    style={{ width: "13rem" }}
                  />
                ) : (
                  <img
                    src={values?.logo}
                    alt=""
                    style={{ width: "13rem" }}
                  />
                )}
                <IconButton
                  disabled={values.status === 'WAITING' ? true : false}
                  sx={{
                    zIndex: "33",
                    position: "absolute",
                    right: "0.5rem",
                    bottom: "0.5rem",
                    bgcolor: `${colors.main_purple}`,
                    "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                  }}
                  component="label"
                >
                  <input
                    hidden
                    id="logo"
                    name="logo"
                    type="file"
                    onChange={(event) => {
                      setFieldValue(
                        "logo",
                        event.target.files[0]
                          ? event.target.files[0]
                          : null
                      );
                      if (event.target.files[0]) {
                        setOnImageCompany(true);
                        setImage(
                          URL.createObjectURL(event.target.files[0])
                        );
                        setFormatLogo(event.target.files[0].type);
                      }
                    }}
                  />
                  <AddRoundedIcon htmlColor="white" />
                </IconButton>
                  </Avatar>
                  <Modal
                sx={{ backgroundColor: "#3f3f3f" }}
                open={onImageCompany}
                onClose={() => {
                  setImage(null);
                  formikRef.current.values.logo = "";
                  setOnImageCompany(false);
                }}
              >
                <Box height="100%" width="100%" margin="0 auto">
                  <Box
                    sx={{
                      height: "80%",
                      width: "80%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      height="100%"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <Cropper
                        style={styleCropper}
                        image={image}
                        crop={cropLogo}
                        zoom={zoomLogo}
                        aspect={1 / 1}
                        onCropChange={setCropLogo}
                        onCropComplete={onCropCompleteLogo}
                        onZoomChange={setZoomLogo}
                      />
                    </Box>
                  </Box>
                  <Box
                    position="absolute"
                    bottom="5%"
                    left="0"
                    right="0"
                    gap="1rem"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MzErrorText>
                      {errors?.logo ? errors?.logo : null}
                    </MzErrorText>
                    <Box
                      width="fit-content"
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                      alignItems="center"
                    >
                      <Button
                        disabled={errors?.logo ? true : false}
                        onClick={()=> onCrop(setFieldValue)}
                        variant="primary"
                      >
                        Confirmer
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setImage(data?.url_logo);
                          formikRef.current.values.logo = "";
                          setOnImageCompany(false);
                        }}
                      >
                        Annuler
                      </Button>
                    </Box>
                    <Box
                      display="flex"
                      gap="0.5rem"
                      sx={{
                        flexDirection: {
                          xs: "column",
                          md: "row",
                          lg: "row",
                          sm: "row",
                        },
                        alignItems: "center",
                      }}
                    >
                      <Typography color="white">
                        Niveau de zoom :{" "}
                      </Typography>
                      <input
                        type="range"
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoomLogo}
                        onInput={(e) => setZoomLogo(e.target.value)}
                      />
                    </Box>
                  </Box>
                </Box>
                  </Modal>
                  <ErrorMessage name="logo" component={MzErrorText} />
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap="0.5rem"
                    sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
                  >
                  <Box>
                  {onUploadImage && (
                    <Box display="flex" alignItems="center" gap="1rem">
                      <CircularProgress size={30} />
                      <Typography width="20px" color={colors.main_purple}>
                        {progress ? progress + "%" : null}
                      </Typography>
                    </Box>
                  )}
                  </Box>
                  </Box>
                </Box>

                  {/* SECTION ENTREPRISE INFOS */}
                  <Box>
                  <Box>
                    <Box>
                      <MainTitle
                        title="Informations de l'entreprise"
                        icon="entreprise_purple"
                      />
                    </Box>

                    <Box marginBottom="1rem">
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Autocomplete
                          options={formats}
                          disabled={values.status === 'WAITING' ? true : false}
                          getOptionLabel={(option) => option.name || ''}
                          value={formats.find(c => c.name === values.social_reason) || null}
                          onChange={(event, newValue) => {
                            setFieldValue('social_reason', newValue ? newValue.name : '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Raison Social*"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                        <ErrorMessage
                          name="social_reason"
                          component={MzErrorText}
                        />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="commercial_name"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Nom commercial*"
                          size="small"
                        />
                        <ErrorMessage
                          name="commercial_name"
                          component={MzErrorText}
                        />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="commercial_register"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Registre de commerce"
                          size="small"
                        />
                        <ErrorMessage
                          name="commercial_register"
                          component={MzErrorText}
                        />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="nif"
                          disabled={values.status === 'WAITING' ? true : false}
                          as={TextField}
                          variant="outlined"
                          label="NIF"
                          size="small"
                        />
                        <ErrorMessage name="nif" component={MzErrorText} />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Autocomplete
                          options={countries}
                          disabled={values.status === 'WAITING' ? true : false}
                          getOptionLabel={(option) => option.name || ''}
                          value={countries.find(c => c.name === values.country_name) || null}
                          onChange={(event, newValue) => {
                            setFieldValue('country_name', newValue ? newValue.name : '');
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Pays*"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                        <ErrorMessage
                          name="country_name"
                          component={MzErrorText}
                        />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="city_name"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Ville*"
                          size="small"
                        />
                        <ErrorMessage
                          name="city_name"
                          component={MzErrorText}
                        />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="postal_code"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Code postal*"
                          size="small"
                        />
                        <ErrorMessage
                          name="postal_code"
                          component={MzErrorText}
                        />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="address"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Adresse*"
                          size="small"
                        />
                        <ErrorMessage name="address" component={MzErrorText} />{" "}
                      </Box>

                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                      <Field
                         sx={{ maxWidth: "350px" }}
                         name="plan"
                         component={Autocomplete}
                         disabled={values.status === 'WAITING'}
                         value={
                          plans.find((plan) => plan.name === values.plan) || null
                         }
                         options={plans}
                         getOptionLabel={(option) => option.name}
                         isOptionEqualToValue={(option, value) => option.id === value.id}
                         onChange={(event, value) => {
                             setFieldValue("plan_id", value ? value.id : "");
                         }}
                         renderInput={(params) => (
                             <TextField
                                 sx={{ maxWidth: "350px" }}
                                 {...params}
                                 name="autocomplete"
                                 label="Plan"
                             />
                         )}
                         size="small"
                      />

                    <ErrorMessage name="plans" component={MzErrorText} />
                      </Box>

                      <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        sx={{ maxWidth: "350px" }}
                        name="type"
                        component={Autocomplete}
                        value={values.type}
                        disabled={values.status === 'WAITING' ? true : false}
                        options={["GIFT", "CLIENT"]}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        getOptionLabel={(s) => s}
                        onChange={(event, value) => {
                          setFieldValue("type", value ? value : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            sx={{ maxWidth: "350px" }}
                            {...params}
                            name="autocomplete"
                            label="Type Entreprise*"
                          />
                        )}
                        size="small"
                      />
                      <ErrorMessage
                        name="company_status"
                        component={MzErrorText}
                      />
                      </Box>
                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <TextField
                          name="status"
                          label='Status'
                          disabled
                          variant="outlined"
                          value={values.status ?? null}
                          size="small"
                        />
                        <ErrorMessage name="status" component={MzErrorText} />
                      </Box>
					  
					  
                      {/* new: email, phone_number and fix */}

                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="email"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Email*"
                          size="small"
                        />
                        <ErrorMessage
                          name="email"
                          component={MzErrorText}
                        />
                      </Box>

                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="phone_number"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Téléphone*"
                          size="small"
                        />
                        <ErrorMessage
                          name="phone_number"
                          component={MzErrorText}
                        />
                      </Box>

                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="fix"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Téléphone Fix*"
                          size="small"
                        />
                        <ErrorMessage
                          name="fix"
                          component={MzErrorText}
                        />
                      </Box>

                      <Box
                        marginRight="1rem"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: "1.3rem",
                        }}
                      >
                        <Field
                          name="website"
                          as={TextField}
                          disabled={values.status === 'WAITING' ? true : false}
                          variant="outlined"
                          label="Site Web"
                          size="small"
                        />
                        <ErrorMessage
                          name="website"
                          component={MzErrorText}
                        />
                      </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                          <Box display={'flex'} mb={1.5} flexDirection={'column'} justifyContent={'space-between'} alignItems={'start'} gap={2} mt={3}>
                            <label htmlFor="collaborators_file" className='font-semibold text-md  '>Attacher Des Nouveaux Collaborateurs</label>
                            <Link style={{ textDecoration: "none", display:'flex',alignItems:'center', gap:'.5rem' }} to="/Manual_ex.xlsx" target="_blank" download>
                              <img src={Excel} alt="excel_logo" width={20} height={10}/>
                              <p className="hover:underline hidden md:block lg:block text-sm font-medium hover:underline-offset-2">
                                Télécharger l'exemplaire
                              </p>
                            </Link>
                          </Box>
                        <Button
                          component="label"
                          role={undefined}
                          sx={{width:'100%'}}
                          variant="upload"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setFieldValue('collaborators_file', file);
                          setSelectedFileName(file.name);
                        }
                      }}
                    >
                     {selectedFileName ? selectedFileName : 'Upload'}
                      <VisuallyHiddenInput type="file" />
                    </Button>
                      <ErrorMessage name="collaborators_file" component={MzErrorText} />

                     <Box display={'flex'} flexDirection={'column'} gap={.5} mt={3}>

                     {values.collaborators_file ? 
                      <Box marginRight="1rem" marginBottom="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent:'center',
                        gridColumn:'span 2 / span 2'
                      }}
                    >
                        <Field name="activation_date" sx={{'width':'100vw'}} min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} label="Date d'activation" />
                        <ErrorMessage name="activation_date" component={MzErrorText} />
                      </Box> :
                      null
                    }

                    {values.collaborators_file ? 
                      <Box marginRight="1rem" marginBottom="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent:'center',
                        gridColumn:'span 2 / span 2'
                      }}
                    >
                        <Field name="expiration_date" sx={{'width':'100vw'}} min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} label="Date d'expiration*" />
                        <ErrorMessage name="expiration_date" component={MzErrorText} />
                      </Box> :
                      null
                    }
                     </Box>
                  </Box>

                      {values.status === 'REJECTED' ?
                    <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                      color: 'red'
                    }}
                  >
                    <Field
                      name="rejection_reason"
                      value={data.rejection_reason}
                      as={TextField}
                      variant="outlined"
                      label="Raison"
                      size="small"
                    />
                    <ErrorMessage name="rejection_reason" component={MzErrorText} />
                  </Box>
                  : null  
                    }

                    </Box>
                  </Box>
                  

                  <ConfirmationModal
                    title="Supprimer une entreprise"
                    message="Êtes-vous sûr de bien vouloir supprimer cette entreprise ?"
                    acceptLabel="oui"
                    refuseLabel="non"
                    open={ondelite}
                    onClose={() => setOnDelete(false)}
                    acceptMethod={() => setOnDeletNext(true)}
                  />
                  <Modal
                    open={ondeliteNext}
                    onClose={() => setOnDeletNext(false)}
                  >
                    <Box sx={ModalStyle}>
                      <Typography fontSize="1.5rem" fontWeight="600" mb>
                        Supprimer une entreprise
                      </Typography>
                      <Typography mb="1rem">
                        Entrez votre mot de passe
                      </Typography>
                      <Box marginBottom="1rem">
                        <TextField
                          type="password"
                          value={motdepasse}
                          onChange={(e) => setMotDePasse(e.target.value)}
                          name="motdepasse"
                          placeholder="Mot de passe"
                        />
                      </Box>
                      <Box display="flex" flexDirection="row" gap="0.5rem">
                        <Button
                          variant="primary"
                          onClick={() => {
                            deleteENT();
                          }}
                        >
                          Supprimer
                        </Button>
                        <Button
                          onClick={() => setOnDeletNext(false)}
                          variant="third"
                        >
                          Annuler
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                  <ToastContainer
                    style={{ top: "5rem" }}
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                  />
                  </Box>

                  {/* Formik 3 - admin */}
                  <Box>
                  <Box>
                  <Box>
                    <MainTitle
                      title="Informations du client admin"
                      icon="person"
                    />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name="company_admin.civilite"
                      as={TextField}
                      disabled={true}
                      variant="outlined"
                      label="Civilite"
                      size="small"
                    />
                    <ErrorMessage name="company_admin.civilite" component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name={values.status === 'WAITING' ? 'company_admin.receiver_email' : 'company_admin.email'}
                      as={TextField}
                      disabled={values.status === 'WAITING' ? true : false}
                      variant="outlined"
                      label="Email*"
                      size="small"
                    />
                    <ErrorMessage name={values.status === 'WAITING' ? 'company_admin.receiver_email' : 'company_admin.email'} component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name={values.status === 'WAITING' ? 'company_admin.receiver_last_name' : 'company_admin.last_name'}
                      as={TextField}
                      disabled={values.status === 'WAITING' ? true : false}
                      variant="outlined"
                      label="Nom*"
                      size="small"
                    />
                    <ErrorMessage name={values.status === 'WAITING' ? 'company_admin.receiver_last_name' : 'company_admin.last_name'} component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name={values.status === 'WAITING' ? 'company_admin.receiver_first_name' : 'company_admin.first_name'}
                      as={TextField}
                      disabled={values.status === 'WAITING' ? true : false}
                      variant="outlined"
                      label="Prénom*"
                      size="small"
                    />
                    <ErrorMessage name={values.status === 'WAITING' ? 'company_admin.receiver_first_name' : 'company_admin.first_name'} component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name={values.status === 'WAITING' ? 'company_admin.receiver_phone_number' : 'company_admin.phone_number'}
                      as={TextField}
                      disabled={values.status === 'WAITING' ? true : false}
                      variant="outlined"
                      label="Phone Number"
                      size="small"
                    />
                    <ErrorMessage name={values.status === 'WAITING' ? 'company_admin.receiver_phone_number' : 'company_admin.phone_number'} component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name="company_admin.department"
                      as={TextField}
                      disabled={values.status === 'WAITING' ? true : false}
                      variant="outlined"
                      label="Département*"
                      size="small"
                    />
                    <ErrorMessage name="company_admin.department" component={MzErrorText} />
                  </Box>
                  <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name="company_admin.function"
                      as={TextField}
                      disabled={values.status === 'WAITING' ? true : false}
                      variant="outlined"
                      label="Fonction"
                      size="small"
                    />
                    <ErrorMessage name="company_admin.function" component={MzErrorText} />
                  </Box>

                  {/*  color sliders */}
                  {
                    formikRef.current?.values?.status === 'CONFIRMED' ? 
                      <Box display="flex" flexDirection="column" gap="1rem">
                    <Box display="flex" flexDirection={'column'} alignItems="start" gap="1rem">
                    <Typography>Default Background Color:</Typography>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                      <Field
                        name="default_background_color"
                        type="color"
                        disabled={formikRef.current?.values?.status === 'CONFIRMED' ? false : true}
                        as={TextField}
                        variant="outlined"
                        size="small"
                        style={{ width: "50px", height: "50px" }}
                      />
                      <Typography>
                        <Field name="default_background_color" />
                      </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection={'column'} alignItems="start" gap="1rem">
                    <Typography>Default Text Color:</Typography>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Field
                          name="default_text_color"
                          type="color"
                          as={TextField}
                          variant="outlined"
                          size="small"
                          style={{ width: "50px", height: "50px" }}
                        />
                        <Typography>
                          <Field name="default_text_color" />
                        </Typography>
                      </Box>
                    </Box>
                      </Box>
                    : 
                    null
                  }

                  <Box display={'flex'} flexDirection={'column'} gap={2} mb={3}>
                  {data?.url_collaborators_file !== null ?
                    <NavLink to={data?.url_collaborators_file} target="_blank" style={{'display':'flex','flexDirection':'row',gap:'.5rem'}} >
                      <img src={Excel} alt="excel_logo" width={20} height={10}/>
                      <p className="hover:underline hover:underline-offset-2">
                        Liste Des Collaborateurs
                      </p>
                    </NavLink>
                    : null  
                }
                {data?.url_brochure !== null ?
                    <NavLink to={data?.url_brochure} target="_blank" style={{'display':'flex','flexDirection':'row',gap:'.5rem'}} >
                      <PictureAsPdf/>
                      <p className="hover:underline hover:underline-offset-2">
                        Brochure
                      </p>
                  </NavLink>
                    : null  
                }
                  </Box>

                  <Box mb={2} display={'flex'} alignItems={'center'} gap={1}>
                  {data?.links && data?.links?.length !== 0 ?
                    data?.links.map((l) => (
                      <NavLink key={l.link_id} to={l.value} target='_blank'>
                        <img src={getLogoUrl(l.title)} width={25} height={25} alt="link_logo"/>
                      </NavLink>
                    ))
                    : null 
                  }
                  </Box>

                  {values.status === 'WAITING' && data.social_reason !== null ?
                      <Box display={'flex'} alignItems={'center'} gap={'.6rem'} marginBottom={'1rem'}>
                        <Button onClick={handleOpenValidate} variant="fifth" sx={{'fontWeight':600}} startIcon={<Check/>}> Valider</Button>
                        <Button onClick={handleOpenReject} variant="third"  sx={{'fontWeight':600}} startIcon={<Close/>}> Rejeter</Button>
                      </Box>
                      : null  
                      }

                      {/* validate */}

                     <Modal
                      open={openValidate}
                      onClose={handleCloseValidate}
                     >

                      <Formik
                      initialValues={formData}
                      onSubmit={(values)=>{
                        validateEntreprise(values)
                      }}
                      >
                        {({validateValues,setFieldValue})=>(
                          <Form>
                            <Box sx={ModalStyle}>
                       <Box>
                       <label sx={{fontWeight:600}}>Valider l'entreprise</label>
                     <Box 
                       marginBottom={"1rem"}
                       marginTop={'1rem'}
                       sx={{
                         display: "flex",
                         flexDirection: "column",
                         justifyContent:'center'
                       }}
                     >
                       <Field name="activation_date" sx={{'width':'100vw'}} min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} label="Date d'activation" />
                       <ErrorMessage name="activation_date" component={MzErrorText} />
                     </Box> 
                       </Box>

                       <Box>
                       <Box 
                       marginBottom={"1rem"}
                       sx={{
                         display: "flex",
                         flexDirection: "column",
                         justifyContent:'center'
                       }}
                     >
                       <Field name="expiration_date" sx={{'width':'100vw'}} min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} label="Date d'expiration*" />
                       <ErrorMessage name="expiration_date" component={MzErrorText} />
                       </Box> 
                       <Box display={'flex'} alignItems={'center'} gap={'.6rem'} >
                        <Button variant="primary" type="submit" onClick={()=>validateEntreprise}>Valider</Button>
                        <Button variant="secondary" onClick={handleCloseValidate}>Fermer</Button>
                       </Box>

                       </Box>
 
                      </Box>
                          </Form>
                        )}
                      </Formik>

                     </Modal>

                      {/* reject */}

                      <Modal open={openReject} onClose={handleCloseReject}>
                        <Box sx={ModalStyle}>

    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "1.3rem",
      }}
    >
      <TextField
        name="rejection_reason"
        value={reason}
        label="Raison de rejection"
        variant="outlined"
        size="medium"
        multiline 
        rows={4} 
        sx={{
          borderRadius: "8px",
          padding: "0.2rem",
          minHeight: "100px", 
          resize: "vertical", 
        }}
        onChange={(event) => {
          handleReasonChange(event.target.value);
        }}
      />
      <ErrorMessage name="rejection_reason" component={MzErrorText} />
    </Box>
                        <Box display={"flex"} alignItems={"center"} gap={".6rem"}>
                          <Button variant="primary" onClick={() => onSubmitreject()}>
                            Rejeter
                          </Button>
                          <Button variant="secondary" onClick={handleCloseReject}>
                            Fermer
                          </Button>
                        </Box>
                        </Box>
                      </Modal>
                 {values.status === 'CONFIRMED' ? 
                  <Box>
                  <Button
                  variant="primary"
                  type="submit"
                  sx={{ marginBottom: "1rem" }}
                >
                  Enregistrer
                  </Button>
                  <Box>
                  <Button onClick={() => setOnDelete(true)} variant="third">
                    Supprimer cette entreprise
                  </Button>
                  </Box>
                  </Box>
                  : null }

                  </Box>
                  </Box>

                  {/* admin photo */}
                  <Box display="flex" flexDirection="column">
                    <MzLabel>Photo de l'admin </MzLabel>
                    <Avatar
                sx={{
                  position: "relative",
                  borderRadius: "20px",
                  width: "12rem",
                  height: "12rem",
                  border: "1px solid #C9C5D2",
                  marginBottom: "1rem",
                }}
                variant="rounded"
              >
                {photo ? (
                  <img
                    src={photo}
                    alt="current file"
                    style={{ width: "13rem" }}
                  />
                ) : (
                  <img
                    src={values?.company_admin.photo}
                    alt=""
                    style={{ width: "13rem" }}
                  />
                )}
                <IconButton
                  disabled={values.status === 'WAITING' ? true : false}
                  sx={{
                    zIndex: "33",
                    position: "absolute",
                    right: "0.5rem",
                    bottom: "0.5rem",
                    bgcolor: `${colors.main_purple}`,
                    "&:hover": { bgcolor: `${colors.main_purple_hover}` },
                  }}
                  component="label"
                >
                  <input
                    hidden
                    id="company_admin.photo"
                    name="company_admin.photo"
                    type="file"
                    onChange={(event) => {
                      setFieldValue(
                        "company_admin.photo",
                        event.target.files[0]
                          ? event.target.files[0]
                          : null
                      );
                      if (event.target.files[0]) {
                        setOnImage(true);
                        setPhoto(
                          URL.createObjectURL(event.target.files[0])
                        );
                        setFormat(event.target.files[0].type);
                      }
                    }}
                  />
                  <AddRoundedIcon htmlColor="white" />
                </IconButton>
                    </Avatar>
                    <Modal
                sx={{ backgroundColor: "#3f3f3f" }}
                open={onImage}
                onClose={() => {
                  setPhoto(null);
                  formikRef.current.values.company_admin.photo = "";
                  setOnImage(false);
                }}
              >
                <Box height="100%" width="100%" margin="0 auto">
                  <Box
                    sx={{
                      height: "80%",
                      width: "80%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      height="100%"
                      width="100%"
                      display="flex"
                      flexDirection="column"
                    >
                      <Cropper
                        style={styleCropper}
                        image={photo}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                      />
                    </Box>
                  </Box>
                  <Box
                    position="absolute"
                    bottom="5%"
                    left="0"
                    right="0"
                    gap="1rem"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <MzErrorText>
                      {errors?.company_admin?.photo ? errors?.company_admin?.photo : null}
                    </MzErrorText>
                    <Box
                      width="fit-content"
                      display="flex"
                      flexDirection="row"
                      gap="1rem"
                      alignItems="center"
                    >
                      <Button
                        disabled={errors?.company_admin?.photo ? true : false}
                        onClick={() => onCropAdmin(setFieldValue)}
                        variant="primary"
                      >
                        Confirmer
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          setPhoto(data?.admin?.url_profile_picture)
                          formikRef.current.values.company_admin.photo = "" ;
                          setOnImage(false);
                        }}
                      >
                        Annuler
                      </Button>
                    </Box>
                    <Box
                      display="flex"
                      gap="0.5rem"
                      sx={{
                        flexDirection: {
                          xs: "column",
                          md: "row",
                          lg: "row",
                          sm: "row",
                        },
                        alignItems: "center",
                      }}
                    >
                      <Typography color="white">
                        Niveau de zoom :{" "}
                      </Typography>
                      <input
                        type="range"
                        min={1}
                        max={3}
                        step={0.1}
                        value={zoom}
                        onInput={(e) => setZoom(e.target.value)}
                      />
                    </Box>
                  </Box>
                </Box>
                    </Modal>
                    <ErrorMessage name="company_admin.photo" component={MzErrorText} />
                    <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                gap="0.5rem"
                sx={{ margin: "0.5rem 0rem 1rem 0rem" }}
              >
                <Box>
                  {onUploadImage && (
                    <Box display="flex" alignItems="center" gap="1rem">
                      <CircularProgress size={30} />
                      <Typography width="20px" color={colors.main_purple}>
                        {progress ? progress + "%" : null}
                      </Typography>
                    </Box>
                  )}
                </Box>
                    </Box>

                  </Box>

                </Form>
              )}
              
            </Formik>

          </Box>

          
        </Box>
        {errors.length !== 0 && <AlertError>
          <ul className="list-disc">
            {errors.map((error,index)=>(
              <ul>
                <li key={index} className="flex items-center gap-2">
                  <p><strong>Row:</strong> {error.row}</p>
                  <p>{error.msg}</p>
                </li>
              </ul>
            ))}
          </ul>
        </AlertError>}
      </Box>
      
      {/* Colls and Pages */}
      <Box>
        {/* Tabulation */}
        <Box mt={2} mb={3} >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
          >
            <Tab
              value="one"
              label="Collaborateurs"
            />
            <Tab value="two" label="Bdisplays" />
          </Tabs>
        </Box>

        {/* Collaborators */}
        {value === 'one' &&
        
        <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          onSelectionModelChange={(ids) => {
            const selection = new Set(ids);
            setSelectedIDs(ids);
            const selectedRows = rowsAPI.filter((row) => selection.has(row.id));
            setSelectedRows(selectedRows);
          }}
          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={columns}
          //data to feed datagrid
          rows={rowsAPI}
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // console.log(newPageSize);
          }}
          pageSize={perPage}
          rowsPerPageOptions={pageNumbers}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          //onpage change
          onPageChange={(newPage) => {
            // console.log(newPage);
            setPage(newPage);
          }}
          pagination
          page={page}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
        </Box>

        }

        {/* Bdisplays */}
        {value === 'two' && 
        
        <Box sx={DataGridHeaderStyle}>
        <StripedDataGrid
          //stripes
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
          sx={{ maxWidth: "100%" }}
          rowHeight={40}
          loading={isLoading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: LinearProgress,
            NoRowsOverlay: EmptyDataGridOverlay,
          }}
          columns={PagesColumns}
          //data to feed datagrid
          rows={rowsAPIPages}
          //how many rows in a page ?
          onPageSizeChange={(newPageSize) => {
            setPerPage(newPageSize);
            // console.log(newPageSize);
          }}
          pageSize={perPageBdipslay}
          rowsPerPageOptions={pageNumbers}
          //mode
          paginationMode="server"
          //total number of rows per search
          rowCount={total}
          //onpage change
          onPageChange={(newPage) => {
            // console.log(newPage);
            setPageBdisplay(newPage);
          }}
          pagination
          page={pageBdisplay}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        />
        </Box>
        
        }
      </Box>

    </Box>
  );
}

export default EntrepriseDetails;
