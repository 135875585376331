import React from "react";
import EditIcon from "../../imgs/edit.svg";
import DeleteIcon from "../../imgs/clear.svg";
import { IconButton } from "@mui/material";

const icons = {};
icons["edit"] = EditIcon;
icons["delete"] = DeleteIcon;

function MyIconButton({ name, children, ...props }) {
  return (
    <IconButton {...props}>
      <img width="18px" height="18px" src={`${icons[name]}`} />
    </IconButton>
  );
}

export default MyIconButton;
