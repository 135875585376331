import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Box, Button, Typography, IconButton, TextField } from '@mui/material';
import api from '../../api/Api_1_3';
import { useAuthContext } from "../../hooks/useAuthContext";
import { DataGridHeaderStyle } from '../../components/dataGridStyles/DataGridHeaderStyle';
import { StripedDataGrid } from '../../components/ui/StripedGrid';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import MyDPFullDate from '../../components/ui/MyDPFullDate';
import MzErrorText from "../../components/ui/MzErrorText";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import * as Yup from 'yup'
import { Add, OpenInNew, Send, Upload } from '@mui/icons-material';
import API from '../../api/Api';
import { Urls } from '../../api/Urls';

const ProsumersManagement = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [prosumers, setProsumers] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [didUpdateExpiration, setDidUpdateExpiration] = useState(false);
    const [openUpdateExpiration, setOpenUpdateExpiration] = useState(false);
    const [filter, setFilter] = useState({ first_name: '', last_name: '', email: '', phone: '' }); // Add filter state
    const minDate = new Date();


    const access_token = user.access_token;

    useEffect(() => {
        getProsumers();
    }, []);

    const getProsumers = async () => {
      const params = {
        // Exclude empty_profiles from params
        ...(filter.first_name && { first_name: filter.first_name }),
        ...(filter.last_name && { last_name: filter.last_name }),
        ...(filter.email && { email: filter.email }),
        ...(filter.phone && { phone: filter.phone }),
    };
        try {
            const response = await api.get('/admin/prosumers', {
                headers: {
                    Authorization: `Bearer ${access_token}`
                },
                params: params
            });
            setProsumers(response.data.data);
        } catch (error) {
            console.error('Error fetching customers:', error);
        }
    };

    const exportContacts = async () =>{
      try {
        const response = await API.get('/export-contacts', {
          headers: {
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            authorization: `Bearer ${access_token}`,
          },
          responseType: 'blob', // Ensure Axios treats the response as a blob
        });
    
        const blob = response.data; // Use response.data to access the blob
    
        const url = window.URL.createObjectURL(new Blob([blob]));
    
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.setAttribute('download', `Contacts.xlsx`);
    
        document.body.appendChild(link);
    
        setTimeout(() => {
          link.click();
          // Clean up resources
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }, 100); // Delay the click to ensure the link is appended
      } catch (error) {
        toast.error('Failed to export contacts');
        console.error('Error fetching the file:', error);
      }
    }

    const handleCreateCustomer = () => {
        navigate('/admin/create-prosumer');
    };

    const handleSelectionModelChange = (newSelection) => {
        setSelectedIds(newSelection);
    };

    const handleOpenUpdateExpiration = () => {
        if (selectedIds.length === 0) {
          toast.error("Aucune ligne séléctionnée");
          return;
        }
        setOpenUpdateExpiration(true);
    };

    const handleCloseUpdateExpiration = () => setOpenUpdateExpiration(false);

    //initialValues update expiration date
    const initialValuesUpdateExpirationDate = {
      expiration_date: "",
    };

    //validation schema update expiration date
    const validationSchemaUpdateExpirationDate = Yup.object({
      expiration_date: Yup.date(),
    });

    const updateExpirationDate = (values) => {
        API.patch(
          Urls.UPDATE_EXPIRATION_DATE,
          {
            user_ids: selectedIds,
            expiration_date: values.expiration_date,
          },
          {
            headers: {
              "Content-type": "application/json",
              authorization: `Bearer ${access_token}`,
            },
          }
        )
          .then((response) => {
            // console.log("RESPONSE UPDATE EXPIRATION DATE", response);
    
            if (response.status === 200) {
              // console.log(response);
              toast.success("Date d'expiration des cartes mise à jour");
              setDidUpdateExpiration(!didUpdateExpiration);
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              localStorage.removeItem("user");
              navigate("/login", { replace: true });
              navigate(0, { replace: true });
            }
            // console.log("RESPONSE", error.response.data.message);
            if (error?.response?.status === 403) {
              toast.error("Une erreur est survenue");
            } else {
              toast.error(error?.response?.data?.message);
            }
          });
    };

    // Add a function to handle filter changes
    const handleFilterChange = (e) => {
          const { name, value } = e.target;
          setFilter({ ...filter, [name]: value });
    };
  
    // Add a function to handle filter submission
    const handleFilterSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        getProsumers(); // Fetch customers with the current filters
    };

    const filledProfileColumns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'first_name',
            headerName: 'Prénom',
            width: 150,
            renderCell: (params) => (
                <Button onClick={() => navigate(`/admin/gestion-des-prosumers/${params.row.id}`)}>
                    {params.value}
                </Button>
            )
        },
        {
            field: 'last_name',
            headerName: 'Nom',
            width: 150,
            renderCell: (params) => (
                <Button onClick={() => navigate(`/admin/gestion-des-prosumers/${params.row.id}`)}>
                    {params.value}
                </Button>
            )
        },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'phone_number', headerName: 'Téléphone', width: 150 },
        { field: 'department', headerName: 'Dépatment', width: 150 },
        { field: 'function', headerName: 'Fonction', width: 150 },
        { field: 'expiration_date', headerName: "Date d'expiration", width: 150 },
        { field: 'status_card', headerName: 'Status', width: 150 },
        {
          field: 'url_profile',
          headerName: 'Voir Profile',
          width: 150,
          renderCell: (params) => (
              <a href={params.value} target="_blank" rel="noopener noreferrer">
                 <OpenInNew />
              </a>
          )
        },
        { field: 'profiles_number', headerName: 'Nombre des profiles', width: 150,
          renderCell: (params) => (
            <Typography variant="body1" >
               {params.value}
            </Typography>
        )
         },
        // Add more columns as needed
    ];

    const [ondeliteNext, setOnDeletNext] = useState(false);
    const [ondelite, setOnDelete] = useState(false);


    const deleteUser = () => {
      API.delete(Urls.DELETE_USER, {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        data: {
          user_ids: selectedIds,
          passphrase: "0zFgtYmLv9"
        },
      })
        .then((res) => {
          toast.success("utilisateur supprimé");
          setOnDeletNext(false);
          getProsumers()
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("user");
            navigate("/login", { replace: true });
            navigate(0, { replace: true });
          }
          // console.log("RESPONSE", error.response.data.message);
          if (error?.response?.status === 403) {
            toast.error("Une erreur est survenue");
          } else {
            toast.error(error?.response?.data?.message);
          }
        });
    };

    return (
        <div>
            <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <Typography mb={3} variant='h5'>Gestion des Prosumers</Typography>
            <Box display="flex" flexDirection={'column'} justifyContent="flex-end" mt={2} mb={2}>
              <form onSubmit={handleFilterSubmit} style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                                <TextField
                                    name="first_name"
                                    label="Prénom"
                                    variant="outlined"
                                    value={filter.first_name}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                                <TextField
                                    name="last_name"
                                    label="Nom"
                                    variant="outlined"
                                    value={filter.last_name}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                                <TextField
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={filter.email}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                                <TextField
                                    name="phone"
                                    label="Phone"
                                    variant="outlined"
                                    value={filter.phone}
                                    onChange={handleFilterChange}
                                    size="small"
                                />
                        <Button type="submit" variant="primary">Filter</Button> {/* Submit button */}
              </form>
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
                        {/* Expiration Date */}
                        <Box>
                        <Formik initialValues={initialValuesUpdateExpirationDate} validationSchema={validationSchemaUpdateExpirationDate}>
                                {({ values, setFieldValue }) => (
                        <Form>
                          <Box display="flex" flexDirection="row">
                            <Box sx={{ minWidth: "300px" }}>
                              <Field name="expiration_date" min={minDate} setFieldValue={setFieldValue} as={MyDPFullDate} size="medium" label="Changer date d'expiration" />
                              <ErrorMessage name="expiration_date" component={MzErrorText} />
                            </Box>
                            <Box>
                              <IconButton onClick={() => handleOpenUpdateExpiration()}>
                                <DoneRoundedIcon />
                              </IconButton>
                            </Box>
                          </Box>
                          {/* modal yupdate expiration */}
                          <ConfirmationModal
                            title="Mettre à jour la date d'expiration"
                            message="Êtes-vous sûr de bien vouloir mettre à jours la date d'expiration pour les cartes séléctionnées ?"
                            acceptLabel="oui"
                            refuseLabel="non"
                            open={openUpdateExpiration}
                            onClose={handleCloseUpdateExpiration}
                            acceptMethod={updateExpirationDate}
                            acceptMethodParams={values}
                          />
                        </Form>
                                )}
                            </Formik>
                        </Box>
              </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} gap={2}>
              <Button variant="primary" startIcon={<Add/>} onClick={handleCreateCustomer}>
                Create Prosumer
              </Button>
              <Button variant="primary" startIcon={<Send/>} onClick={()=> navigate('/admin/gestion-des-prosumers/send-passcode')}>
                Envoyer un passcode
              </Button>
              <Button disabled={selectedIds.length === 0 ? true : false} sx={{ ":disabled": { backgroundColor: "gray", color: "white", border: "2px solid gray" } }} onClick={() => setOnDelete(true)} variant="third">
                Delete Prosumer(s)
              </Button>
              <ConfirmationModal title="Supprimer une/des cartes" message="Êtes-vous sûr de bien vouloir supprimer cette/ces cartes ?" acceptLabel="oui" refuseLabel="non" open={ondelite} onClose={() => setOnDelete(false)} acceptMethod={() => deleteUser()} />
            </Box>
            <Box sx={DataGridHeaderStyle}>
                <StripedDataGrid
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "odd" : "even")}
                    sx={{ maxWidth: "100%" }}
                    rowHeight={40}
                    rows={prosumers}
                    columns={filledProfileColumns}
                    pageSize={15}
                    checkboxSelection
                    onSelectionModelChange={handleSelectionModelChange}
                />
            </Box>
        </div>
    );
};

export default ProsumersManagement;





