import React, { useState } from 'react';
import { TextField, Button, Box, Typography, CircularProgress, Select, MenuItem } from '@mui/material';
import api from '../../api/Api_1_3';
import { useAuthContext } from "../../hooks/useAuthContext";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const CreatePasscode = () => {
    const { user } = useAuthContext();
    const access_token = user.access_token;
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const [userType, setUserType] = useState('PROSUMER'); // Add state for user type
    const [nbrOfCards, setNbrOfCards] = useState(1); // Add state for number of cards
    const navigate = useNavigate();

    const handleSendPasscode = async () => {
        setLoading(true); // Set loading to true
        try {
            const res = await api.post('/admin/individuals/send-passcode', {
                user_email: email,
                user_type: userType, // Use selected user type
                nbr_of_cards: userType === 'PRO_PLUS_ADMIN' ? nbrOfCards : undefined // Include nbr_of_cards if user type is PRO_PLUS_ADMIN
            }, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            if(res.status === 201){
                toast.success('Passcode Envoyé avec succès');
                setTimeout(()=> { 
                    navigate('/admin/gestion-des-prosumers')
                }, 1000)
            }
        } catch (error) {
            toast.error(error.response.data.message);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 4, gap: 2 }}>
        <ToastContainer style={{ top: "5rem" }} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
            <Typography variant="h5" gutterBottom>Envoyer Un Passcode</Typography>
            <Select
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
                sx={{ mb: 2, width: '220px' }}
                size='small'
            >
                <MenuItem value="PROSUMER">PROSUMER</MenuItem>
                <MenuItem value="PRO_PLUS_ADMIN">PRO PLUS ADMIN</MenuItem>
            </Select>
            <TextField
                label="Email"
                type="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2, width:'220px' }}
                size='small'
            />
            {userType === 'PRO_PLUS_ADMIN' && ( // Conditionally render nbr_of_cards input
                <TextField
                    label="Nombre de cartes"
                    type="number"
                    inputProps={{ max: 9 }}
                    onInput={(e) => {
                        if (e.target.value > 9) {
                            e.target.value = 9; // Set value to 200 if it exceeds
                        }
                    }}
                    value={nbrOfCards}
                    onChange={(e) => setNbrOfCards(e.target.value)}
                    sx={{ mb: 2, width: '220px' }}
                    size='small'
                />
            )}
            <Box display={'flex'} alignItems={'center'} gap={2}>
                {
                 loading 
                ? 
                <CircularProgress size={24} /> 
                : 
                <Button variant="primary" onClick={handleSendPasscode} disabled={loading}> {/* Disable button when loading */}
                    Envoyer le passcode
                </Button>
                }
                <Button
                    variant="secondary"
                    onClick={() => navigate('/admin/gestion-des-prosumers')} // Navigate to the desired route
                >
                    Annuler
                </Button>
            </Box>
        </Box>
    );
};

export default CreatePasscode;
