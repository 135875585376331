import React from "react";
import { useState, useRef,useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Autocomplete,
} 
from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import AlertError from '../../components/ui/AlertError'
import Api from "../../api/Api";
import * as Yup from "yup";
import MzErrorText from "../../components/ui/MzErrorText";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../hooks/useAuthContext";
import MainTitle from "../../components/ui/MainTitle";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import API from "../../api/Api_1_3";

function ImportEnterprise() {
  const { user } = useAuthContext();
  const access_token = user.access_token;
  const [plans,setPlans] = useState([])
  const [enterprises,setEnterprises] = useState([])
  const [errors,setErrors] = useState([])
  const [loading,setLoading] = useState(false);
   //logo state
  const formikRef = useRef();
  
  //validation schema
  const initialValues = {
    commercial_name: "",
    plan_id: "",
    type: "",
    receiver_email: "",
    receiver_first_name: "",
    receiver_last_name: "",
  };

  //validation schema
  const validationSchema = Yup.object({
    commercial_name: Yup.string().required('Ce champe est obligatoire'),
    plan_id: Yup.string().required('Ce champe est obligatoire'),
    type: Yup.string().required('Ce champe est obligatoire'),
    receiver_email: Yup.string().required('Ce champe est obligatoire'),
    receiver_first_name: Yup.string().required('Ce champe est obligatoire'),
    receiver_last_name: Yup.string().required('Ce champe est obligatoire'),
  });

  const navigate = useNavigate();

  var configs = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  };

  const getPlans = async () => {
    const res = await Api.get('/admin/plans',{
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
    const data = await res.data.data
    const values = data.map((item) => ({ id: item.id, name: item.name }))
    setPlans(values);
  }

  const getEnterprises = async () => {
    const res = await Api.get('/admin/enterprises',{
        headers: {
            authorization: `Bearer ${access_token}`,
        },
    })
    const data = await res.data.enterprises
    const values = data.map((item) => ({ id: item.id, name: item.commercial_name }))
    setEnterprises(values);
  }

  useEffect(()=>{
    getEnterprises()
    getPlans()
  },[])

  //submit entreprise
  const onSubmitCreateEntreprise = (values) => {
    console.log("values", values);
    
    setLoading(true)
    API.post('/admin/enterprises/store-auto',
      {
        commercial_name: values.commercial_name,
        plan_id: values.plan_id,
        type: values.type,
        receiver_email: values.receiver_email,
        receiver_first_name: values.receiver_first_name,
        receiver_last_name: values.receiver_last_name,
      },
      configs
    )
      .then((response) => {
        // console.log("RESPONSE", response);

        if (response.status === 200 || response.status === 201) {
          // console.log(response);
          toast.success("Entreprisé Ajoutée avec succès");
          setTimeout(() => {
            navigate("/admin/gestion-des-entreprises");
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login", { replace: true });
          navigate(0, { replace: true });
        }
        // console.log("RESPONSE", error.response.data.message);
        if (error?.response?.status === 403) {
          toast.error("Une erreur est survenue");
        } else {
          toast.error(error.response.data.message)
          setErrors(!error.response.data.message ? error.response.data.errors : [])
        }
      });
      setLoading(false)
  };

  return (
    <Box>

      <Box>
        <Typography variant="h5" sx={{ marginBottom: "1.5rem", fontWeight: "500" }}>
          Création d'une entreprise
        </Typography>
        <ToastContainer
          style={{ top: "5rem" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />

        {/* FORMULAIRE  */}

        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            onSubmitCreateEntreprise(values, access_token);
          }}
        >
          {({ values, setFieldValue, errors }) => (
            <Form>
              <Box
                display="flex"
                sx={{
                  gap: "1rem",
                }}
              >
                {/* SECTION ENTREPRISE INFOS */}
                <Box>
                  <Box>
                    <MainTitle
                      title="Informations de l'entreprise"
                      icon="entreprise_purple"
                    />
                  </Box>

                    <Box
                    display="flex"
                    maxWidth="500px"
                    sx={{ flexDirection: "column"}}
                    marginBottom="1rem"
                  >

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="commercial_name"
                        as={TextField}
                        variant="outlined"
                        label="Nom commercial*"
                        size="small"
                      />
                      <ErrorMessage
                        name="commercial_name"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                    marginRight="1rem"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "1.3rem",
                    }}
                  >
                    <Field
                      name="plan_id"
                      component={Autocomplete}
                      value={plans.find((plan) => plan.id === values.plan_id) || null}
                      options={plans}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        setFieldValue("plan_id", value ? value.id : "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="autocomplete"
                          label="Plan*"
                        />
                      )}
                      size="small"
                    />
                    <ErrorMessage name="plan_id" component={MzErrorText} />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="type"
                        component={Autocomplete}
                        value={values.type}
                        options={["GIFT", "CLIENT"]}
                        isOptionEqualToValue={(option, value) =>
                          option === value
                        }
                        getOptionLabel={(s) => s}
                        onChange={(event, value) => {
                          setFieldValue("type", value ? value : "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="autocomplete"
                            label="Type Entreprise*"
                          />
                        )}
                        size="small"
                      />
                      <ErrorMessage
                        name="type"
                        component={MzErrorText}
                      />
                    </Box>
                    
                    </Box>
                </Box>

                {/* Section Admin */}
                <Box>
                  <Box>
                    <MainTitle
                      title="Informations de l'admin"
                      icon="person"
                    />
                  </Box>

                    <Box
                    display="flex"
                    maxWidth="500px"
                    sx={{ flexDirection: "column"}}
                    marginBottom="1rem"
                  >

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="receiver_first_name"
                        as={TextField}
                        variant="outlined"
                        label="First Name*"
                        size="small"
                      />
                      <ErrorMessage
                        name="receiver_first_name"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="receiver_last_name"
                        as={TextField}
                        variant="outlined"
                        label="Last Name*"
                        size="small"
                      />
                      <ErrorMessage
                        name="receiver_last_name"
                        component={MzErrorText}
                      />
                    </Box>

                    <Box
                      marginRight="1rem"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "1.3rem",
                      }}
                    >
                      <Field
                        name="receiver_email"
                        as={TextField}
                        variant="outlined"
                        label="Email*"
                        size="small"
                      />
                      <ErrorMessage
                        name="receiver_email"
                        component={MzErrorText}
                      />
                    </Box>
                    
                    </Box>
                </Box>

                </Box>

                <Box>
                { loading === true ? <CircularProgress/> :
                  <Box display={'flex'} flexDirection={'row'} justifyContent={'start'} gap={'.5rem'}>
                    <Button variant="primary" type="submit">
                    Envoyer
                  </Button>
                  
                  <Button variant="secondary" onClick={()=> navigate('/admin/gestion-des-entreprises')} >
                    Annuler
                  </Button>
                  </Box>
                  }
                </Box> 
                
            </Form>
          )}
        </Formik>
      </Box> 

        <ToastContainer
          style={{ top: "5rem" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />    
         {errors.length !== 0 && <AlertError>
          <ul className="list-disc">
            {errors.map((error,index)=>(
              <ul>
                <li key={index} className="flex items-center gap-2">
                  <p><strong>Row:</strong> {error.row}</p>
                  <p>{error.msg}</p>
                </li>
              </ul>
            ))}
          </ul>
        </AlertError>}
    </Box>
  );
}

export default ImportEnterprise;