import { Category, FacebookOutlined, Instagram, Link, LinkedIn, YouTube } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import X from '../../imgs/logo.svg'

const MultiStatsCard = ({ total, links, title, jobTitle, bgColor, style }) => {
const navigate = useNavigate()
const location = useLocation()
const path = location.pathname.split('/').length
  const getIcon = (linkName) =>{
    switch(linkName){
      case 'facebook':
        return <FacebookOutlined/>;
      case 'instagram':
        return <Instagram/>;
      case 'twitter':
        return <img src={X} width={18} alt="Xcorp logo"/>;
      case 'linkedin':
        return <LinkedIn/>;
      case 'youtube':
        return <YouTube/>;
      case 'url_services':
        return <Category/>
      default:
        return <Link/>;
    }
  }
    return (
      <section
        className={`flex w-full col-start-[span_4] flex-col items-center h-[15rem] rounded-3xl px-6 py-6 justify-between gap-2 text-primary-white ${bgColor}`}
        style={style}
      >
          <h4 className="text-white font-bold text-xl text-center">{title} <Link/> </h4>
          <h4 className="text-white font-medium text-lg text-center">{jobTitle}</h4>
          <h2 className='text-white font-bold text-[4rem] text-center'>{total}</h2>
          <section className='flex justify-around items-center w-full'>
          {Object.entries(links).map(([linkName, linkValue], index) => {
            return (
             <Tooltip title={linkName === 'url_services' ? 'service bdisplay' : linkName === 'twitter' ? 'X (ex twitter)' : linkName} arrow>
              <span
                key={index}
                className={`flex items-center gap-1 mx-2 text-white font-bold text-2xl`}
              >
                <h3 className="text-[1.5rem]">{linkValue}</h3>
                {getIcon(linkName)}
              </span>
             </Tooltip>
            );
          })}
          </section>
      </section>
    );
  };
  
  export default MultiStatsCard;
  