import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate } from "react-router-dom";

export const useLogin = (email, password) => {
  const [errorLogin, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();

  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);

    //remove const response = await
    const response = await Api.post(
      Urls.LOGIN_URL,
      { email, password, role: "ADMIN" },
      {
        headers: { "Content-type": "multipart/form-data",
                    "Access-Control-Allow-Origin": "*"
                  },
      }
    )
      .then((response) => {
        // console.log(response);

        if (response.status === 200 && response.data) {
          // save the user to local storage
          localStorage.setItem("user", JSON.stringify(response.data));

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });

          // update loading state
          setTimeout(() => {
            setIsLoading(false);
            window.location.reload();
          }, 1500);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.response.data.message);
        // console.log(error.response.data.message);
        // console.log(error);
      });
  };

  return { login, isLoading, errorLogin };
};
