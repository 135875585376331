import React, { useEffect, useRef, useState } from "react";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./styles/StyledTable.css";
import { CircularProgress, Select, MenuItem, Button, Box, TextField, FormControl, Checkbox, Menu, IconButton, Modal, Autocomplete, Typography, TextareaAutosize, FormControlLabel } from "@mui/material";
import { Add, ArrowBackIos, ArrowForwardIos, CalendarMonth, Call, Check, Close, CoPresent, Comment, ContactsOutlined, Download, Edit, Email, LinkedIn, List, MoreHoriz, Upload } from "@mui/icons-material";
import API from "../../api/Api_1_3";
import { toast, ToastContainer } from "react-toastify";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ModalStyle } from "../../theme/ModalStyles";
import MzErrorText from "../../components/ui/MzErrorText";
import { colors } from "../../theme/Colors";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { Navigate, useNavigate } from "react-router-dom";

function PagesContacts() {
  const navigate = useNavigate()
  const { user } = useAuthContext();
  const access_token = user.access_token;

  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReject, setOpenReject] = useState(false);
  const [contactId, setContactId] = useState("");
  const [selectedType, setSelectedType] = useState('')
  const [companyID,setCompanyID] = useState('')

  const handleOpenReject = (id) => {
    handleCloseMenu();
    setOpenReject(true);
  };

  const handleCloseReject = () => {
    setOpenReject(false);
  };

  const exportContacts = async () =>{
    try {
      const response = await API.get('/export-contacts', {
        headers: {
          accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          authorization: `Bearer ${access_token}`,
        },
        responseType: 'blob', // Ensure Axios treats the response as a blob
      });
  
      const blob = response.data; // Use response.data to access the blob
  
      const url = window.URL.createObjectURL(new Blob([blob]));
  
      const link = document.createElement('a');
      link.href = url;
      link.target = '_blank';
      link.setAttribute('download', `Contacts.xlsx`);
  
      document.body.appendChild(link);
  
      setTimeout(() => {
        link.click();
        // Clean up resources
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }, 100); // Delay the click to ensure the link is appended
    } catch (error) {
      toast.error('Failed to export contacts');
      console.error('Error fetching the file:', error);
    }
  }

  const handleCheckboxChange = (event, contactId) => {
    if (event.target.checked) {
      setSelectedContacts([...selectedContacts, contactId]);
    } else {
      setSelectedContacts(selectedContacts.filter(id => id !== contactId));
    }
  };

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: '',
    phone: '',
    company: ''
  });

  const validationSchema = Yup.object({
    first_name: Yup.string(),
    last_name: Yup.string(),
    phone: Yup.string(),
    company: Yup.string(),
  });

  const validationSchemaUser = Yup.object({
    type: Yup.string(),
    send_email: Yup.boolean(),
  });

  const onSubmitSearchBdisplay = async (values, token) => {
    Api.get("/get-contacts", {
      headers: {
        "Content-type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${token}`,
      },
      params: {
        first_name: values.first_name !== '' ? values.first_name : null,
        last_name: values.last_name !== '' ? values.last_name : null,
        phone: values.phone !== '' ? values.phone : null,
        company_name: values.company !== '' ? values.company : null
      }
    })
      .then((response) => {
        setContacts(response.data?.contacts)
      })
  };

  const handleConvertToUser = async (values) => {
    const { type, send_email } = values;
    let payload = {
      source: "bdisplay_contacts",
      contact_id: contactId.toString(),
      type: type.toUpperCase(),
    };
    if(values.type === 'Gift'){
      payload.send_email = send_email
    }

    try {
      await API.post(
        "/contacts/convert-to-user",
        payload,
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      )
      .then((res)=>{
        toast.success('Operation success');
        setTimeout(()=>{
          navigate('/admin/gestion-des-entreprises/' + res.data?.enterprise?.id)
        },1000)
      })
      setSelectedType("")
    } catch (error) {
      console.error("Error adding contact:", error.response.data.message);
      toast.error(error.response.data.message);
    }
    handleCloseReject();
  };

  const [vcfUrlFile, setVcfUrlFile] = useState(null);

  const handleShowMenu = (event, url_vcf_file) => {
    setAnchorEl(event.currentTarget);
    setVcfUrlFile(url_vcf_file);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setVcfUrlFile(null);
  };

  const handleNavLinkClick = () => {
    if (vcfUrlFile) {
      window.open(vcfUrlFile, "_blank");
    }
    handleCloseMenu();
  };

  const showMenu = Boolean(anchorEl);

  const getContacts = (page) => {
    Api.get(`/bdisplay-contacts?page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        authorization: `Bearer ${access_token}`,
      },
    })
    .then((response) => {
      const contacts = response.data.data ?? [];
      // Reverse both contacts and their comments
      const reversedContacts = contacts.reverse().map(contact => ({
        ...contact,
        comments: contact.comments.reverse(),
      }));
      setContacts(reversedContacts);
      setTotalPages(response.data.meta.last_page);
      setCurrentPage(response.data.meta.current_page);
    })
    .catch((error) => {
      console.log('Error fetching contacts:', error);
    });
  };
  
  useEffect(() => {
    getContacts(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };


  const [possibleActionsList,setList] = useState([
    {
      id: 0,
      type: 'Client',
    },
    {
      id: 1,
      type: 'Gift',
    },
  ])

  const [editedComments, setEditedComments] = useState({});

  const handleEditComment = (id, newComment) => {
    setEditedComments({ ...editedComments, [id]: newComment });
  };

  const handleEditCommentOnSubmit = async (id, newComment) => {
    const trimmed = newComment.trim()
    handleShowEdit(null)
    try {
      await API.patch(
        "/pages/comments/" + id,
        {
          comment: trimmed,
        },
        {
          headers: {
            "Content-type": "application/json",
            accept: "application/json",
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      toast.success('Operation success');
      getContacts();
    } catch (error) {
      console.error("Error editing comment:", error);
      toast.error("Failed to edit comment");
    }
  };

  const [openComments,setOpenComments] = useState(false)
  const handleClose = () => setOpenComments(false)
  const handleOpen = () => setOpenComments(true)
  
  const [selectedContactId, setSelectedContactId] = useState(null);

  const handleOpenComments = () => {
    setSelectedContactId(contactId);
  };

  const getIconByType = (type, color) => {
    switch (type) {
      case 'Call':
        return <Call sx={{color: color}} />;
      case 'Email':
        return <Email sx={{color: color}} />;
      case 'LinkedIn':
        return <LinkedIn sx={{color: color}} />;
      case 'RDV Présentation':
        return <CalendarMonth sx={{color: color}}/>;
      case 'Démo':
        return <CoPresent sx={{color: color}} />;
      default:
        return <DesignServicesIcon sx={{color: color}} />;
    }
  };

  const [editCommentId, setEditCommentId] = useState(null);

  const handleShowEdit = (commentId) =>{
    setEditCommentId(commentId);
  }
  
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [editCommentId]);

  return (
    <div>
      <ToastContainer
        style={{ top: "5rem", width: "300px" }}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="flex items-start justify-between mb-4">
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <h1 className="text-2xl font-bold">Liste Des Contacts Bdisplay</h1>
          <Box className="filter" display="flex" flexDirection="row">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                onSubmitSearchBdisplay(initialValues, access_token);
              }}
            >
              {({ values, setFieldValue }) => (
                <FormControl>
                  <Box display="flex">
                    {/*Search fields */}
                    <Box display="flex" maxWidth="1200px" sx={{ flexDirection: { md: "row", xs: "column" } }} marginBottom="1rem">
                      <Box flex={1} alignItems="center" marginRight="1rem" mb>
                        <TextField
                          placeholder="Full Name"
                          name="first_name"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              first_name: event.target.value,
                            }));
                            onSubmitSearchBdisplay(
                              {
                                ...initialValues,
                                first_name: event.target.value,
                              },
                              access_token
                            );
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 } }}
                        />
                        <ErrorMessage name="first_name" component={MzErrorText} />
                      </Box>

                      <Box flex={1} alignItems="center" marginRight="1rem" mb>
                        <TextField
                          placeholder="Téléphone"
                          name="phone"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              phone: event.target.value,
                            }));
                            onSubmitSearchBdisplay(
                              {
                                ...initialValues,
                                phone: event.target.value,
                              },
                              access_token
                            );
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 } }}
                        />
                        <ErrorMessage name="phone" component={MzErrorText} />
                      </Box>

                      <Box flex={1} alignItems="center" marginRight="1rem" mb>
                        <TextField
                          placeholder="Entreprise"
                          name="company"
                          onChange={(event) => {
                            setInitialValues((prevValues) => ({
                              ...prevValues,
                              company: event.target.value,
                            }));
                            onSubmitSearchBdisplay(
                              {
                                ...initialValues,
                                company: event.target.value,
                              },
                              access_token
                            );
                          }}
                          sx={{ '::placeholder': { 'fontWeight': 600 } }}
                        />
                        <ErrorMessage name="company" component={MzErrorText} />
                      </Box>

                      <Box marginRight="1rem" display={'flex'} alignItems={'center'} gap={1} mb>
                        {user.user.role === 'CLIENT_ADMIN' && <Button startIcon={<Upload/>} variant="primary" sx={{ fontWeight: 500 }} onClick={exportContacts}>Exporter</Button>}
                      </Box>
                    </Box>
                  </Box>
                </FormControl>
              )}
            </Formik>
          </Box>
        </Box>
      </div>
      <div className="table-container">
        <table className="styled-table overflow-x-auto">
          <thead>
            <tr>
              <th scope="col" className="px-8 py-3">Nom</th>
              <th scope="col" className="px-6 py-3">Email</th>
              <th scope="col" className="px-6 py-3">Mobile</th>
              <th scope="col" className="px-6 py-3">Entreprise</th>
              <th scope="col" className="px-6 py-3">Bdisplay</th>
              <th scope="col" className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {contacts.length === 0 && <div className="text-center my-4"><CircularProgress /></div>}
            {contacts.length > 0 && contacts.map((contact) => (
              <tr key={contact.id}>
                <td>{contact.first_name + " " + contact.last_name ?? '---'}</td>
                <td>{contact.email ?? '---'}</td>
                <td>{contact.phone ?? '---'} </td>
                <td>{(contact.company) ?? '---'} </td>
                <td>{contact.bdisplay_name ?? '---'} </td>
                <td>
                  <IconButton onClick={(event) => {
                    handleShowMenu(event, contact.url_vcf_file)
                    setContactId(contact.id);
                    setCompanyID(contact.company_id)
                  }}>
                    <MoreHoriz />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={showMenu}
                    onClose={handleCloseMenu}
                    PaperProps={{ elevation: 2 }}
                  >
                    <MenuItem onClick={() => handleNavLinkClick(contact?.url_vcf_file)} sx={{display:'flex', gap:1}}>
                        <ContactsOutlined sx={{width:25}}/>
                          Ajouter Contact
                    </MenuItem>

                        <MenuItem onClick={() => { 
                          handleOpenReject(contact.id)
                          } }
                          sx={{display:'flex', gap:1}}>
                          <Add/>
                          Créer un compte
                        </MenuItem>

                    <MenuItem onClick={() => {
                      handleOpenComments(contact.id)
                      handleOpen()
                      }}
                     sx={{display:'flex', gap:1}}>
                      <List/>
                      List des Commentaires</MenuItem>
                  </Menu> 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination-controls self-end">
        <Button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <ArrowBackIos/>
        </Button>
        <span>Page {currentPage} of {totalPages}</span>
        <Button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <ArrowForwardIos/>
        </Button>
        </div>
      </div>
      {openReject && (
        <Formik
          initialValues={{ type: 'Client', send_email: false }}
          onSubmit={(values) => handleConvertToUser(values)}
          validationSchema={validationSchemaUser}
          enableReinitialize
        >
          {({ values, handleChange, setFieldValue, errors, touched }) => (
            <Modal
              open={openReject}
              onClose={handleCloseReject}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={ModalStyle}>
                <h2 className="text-2xl mb-4">Créer un compte</h2>
                <Form>
                  <Box className="field" sx={{ marginBottom: "1rem" }}>
                    <Field
                      name="type"
                      component={Autocomplete}
                      options={possibleActionsList}
                      isOptionEqualToValue={(option, value) => option.id === value?.id}
                      getOptionLabel={(option) => option.type}
                      onChange={(event, value) => {
                        setFieldValue("type", value.type);
                        if(value){
                          setSelectedType(value.type)
                          console.log(value);
                        }
                      }}
                      renderInput={(params) => <TextField {...params} name="autocomplete" label="Type" />}
                      size="small"
                      error={touched.type}
                    />
                    <ErrorMessage name="type" component={MzErrorText} />
                  </Box>
                  {
                    selectedType === 'Gift' ?
                    <div className="pb-2">
                      <FormControlLabel
                        control={
                          <Field
                            as={Checkbox}
                            name="send_email"
                            color="primary"
                            checked={values.send_email} // if using Formik, ensure `values` is being passed correctly
                          />
                        }
                        label="Envoyer email"
                      />
                      {touched.send_email && errors.send_email && (
                        <ErrorMessage name="send_email" component={MzErrorText} />
                      )}
                    </div> : null
                  }
                  <div className="flex justify-end">
                    <Button variant="secondary" onClick={handleCloseReject} sx={{ mr: 2 }}>Annuler</Button>
                    <Button type="submit" variant="primary">Ajouter</Button>
                  </div>
                </Form>
              </Box>
            </Modal>
          )}
        </Formik>
      )}

      {
        openComments && (
          <Modal
          open={openComments}
          onClose={handleClose}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openComments}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant="h6" color="initial">Commentaires</Typography>
                  <Box onClick={handleClose}>
                    <Close/>
                  </Box>
                </Box>
              </MenuItem>
              {contacts.length !== 0 ? contacts.map((contact, index) => (
              <div key={index}>
                {selectedContactId === contact.id && contact.comments && contact.comments.map((comment, commentIndex) => (
                  <MenuItem key={commentIndex} sx={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', alignItems: 'center', borderBottom: '.5px solid #232323', gap: '.5rem' }}>
                    <Box>
                      {getIconByType(comment.comment_type,colors.bd_Purple)}
                    </Box>
                    <Box width={500} display={'flex'} flexDirection={'column'} alignItems={'start'}>
                      {editCommentId === comment.id ? 
                      <TextareaAutosize
                      rowsMin={3}
                      maxRows={5}
                      value={editedComments[comment.id] || comment.comment}
                      onChange={(e) => handleEditComment(comment.id, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault(); 
                          handleEditCommentOnSubmit(comment.id, editedComments[comment.id]);
                        }
                      }}
                      autoFocus
                      ref={textAreaRef}
                      style={{ width: '100%', fontSize: '1rem', padding:3, fontWeight: 450, backgroundColor:'transparent' }}
                    />
                      : <Typography variant="body1">{editedComments[comment.id] || comment.comment}</Typography>
                    }
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{width:'100%'}}>
                      <Typography variant="body1" color="initial" sx={{ whiteSpace: 'break-spaces', fontSize: '.9rem', opacity: 0.7 }}>{comment.user} - {comment.is_updated ? comment.updated_at : comment.created_at} {comment.is_updated && '(Modifié)'}</Typography>
                      <Box display={'flex'} gap={1}>
                        {
                        editCommentId === comment.id &&
                        <>
                          <Check sx={{color: colors.mjGreenText}} onClick={() => handleEditCommentOnSubmit(comment.id, editedComments[comment.id])}/>
                          <Close sx={{color: colors.mj_red}} onClick={()=> handleShowEdit(null)}/>
                        </>
                        }
                      </Box>
                    </Box>
                    </Box>
                    <Box>
                      <Button variant={ editCommentId === comment.id ? "disabled" : "secondary"} onClick={() => handleShowEdit(comment.id)}><Edit/></Button>
                    </Box>
                  </MenuItem>
          ))}
              </div>
              )) : <div>Aucun contact ajoutée.</div>}


            </Menu>

          </Modal>
        )
      }
    </div>
  );
}

export default PagesContacts;
